@import "../../../../public/style/variables";

.invite-people-box{
	background: $color_white;
	padding: 12px 8px;
	margin-bottom: 8px;
	@include border-radius(5px);
	> h3{
		font-size: 20px;
		font-weight: 700;
		color: $heading_color;
		padding: 0 8px 10px;
	}
	.invite-people-content{
		padding: 0 8px 6px;
		line-height: 18px;
	}
}

.invite-people-popup{
	.modal-dialog{
		max-width: 600px;
		@include breakpoint( max , 400){
			width: 90%;
			margin: 2em auto;
		}
	}
}