@import "../../../public/style/variables";

.cms-fooer{
	background: $color_firefly;
	.cms-quick-links{
		padding: 28px 0 24px;
		ul{
			display: flex;
			flex-wrap: wrap;
			li{
				width: 25%;
				@include breakpoint( max , 767){
					width: 50%;
				}
				a{
					font-size: 14px;
					line-height: 26px;
					color: $color_white;
				}
			}
		}
	}
	.copyright{
		border-top: 1px solid rgba($gray_color, .2);
		padding: 15px 0;
		text-align: center;
		font-size: 12px;
		color: $color_white;
	}
}