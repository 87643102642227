@import "../../../public/style/variables";

.myprofile-header-wrap {
  position: relative;
  .profile-coverphoto {
    height: 368px;
    position: relative;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .myprofile-intro-nav {
    background: $color_white;
    margin-top: -151px;
    position: relative;
    @include border-radius(5px);
  }
  .myprofile-intro {
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    .profile-photo {
      width: 140px;
      img {
        @include border-radius(100%);
        // min-height: 140px;
        // object-fit: cover;
      }
      @include breakpoint(max, 767) {
        width: 100%;
        img {
          max-width: 110px;
          @include border-radius(10px);
        }
      }
    }
    .profile-info {
      width: calc(100% - 140px);
      padding-left: 16px;
      @include breakpoint(max, 767) {
        width: 100%;
        padding-left: 0;
        padding-top: 10px;
      }
      .profile-meta {
        display: flex;
        justify-content: space-between;
        padding-bottom: 12px;
        .left {
          h3 {
            font-size: 20px;
            line-height: 24px;
            font-weight: 600;
            color: $color_gray_dark;
            padding-bottom: 6px;
            text-transform: capitalize;
          }
          .followers {
            font-size: 14px;
            line-height: 17px;
            padding-right: 16px;
            @include breakpoint(max, 767) {
              padding-bottom: 5px;
            }
            span {
              font-size: 16px;
              color: $color_gray_dark;
            }
          }
          .create-date {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 17px;
            @include breakpoint(max, 767) {
              padding-bottom: 8px;
            }
            &.org-user-name {
              padding-right: 16px;
            }
            span {
              margin-right: 8px;
              svg {
                display: block;
              }
            }
          }
        }
        .right {
          flex-shrink: 0;
          display: flex;
          align-items: flex-start;
          .community-badge {
            margin-right: 8px;
          }
        }
      }
      .txt {
        line-height: 16px;
      }
      .org-personal-info {
        padding-top: 10px;
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            margin-right: 16px;
            display: flex;
            align-items: center;
            color: $color_dorado;
            font-size: 14px;
            margin-bottom: 5px;
            a {
              color: $color_dorado;
              &:hover {
                color: $secondary_color;
              }
            }
            .icon {
              flex-shrink: 0;
              margin-right: 7px;
              svg {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  &.org-header-wrap {
    padding-top: 40px;
    .myprofile-intro-nav {
      margin-top: 0;
    }
    .myprofile-intro {
      .profile-photo {
        img {
          min-height: 140px;
          object-fit: cover;
        }
        @include breakpoint(max, 767) {
          img {
            min-height: 110px;
            @include border-radius(10px);
          }
        }
      }
    }
  }
}

.myprofile-nav {
  border-top: 1px solid $color_concrete;
  .collapse:not(.show) {
    @include breakpoint(min, 768) {
      display: block;
    }
  }
  .btn-nav {
    border: 0;
    padding: 8px 15px;
    font-size: 14px;
    font-weight: 600;
    color: $gray_color;
    display: flex;
    align-items: center;
    svg {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    @include breakpoint(min, 768) {
      display: none;
    }
  }
  .nav-list {
    > ul {
      display: flex;
      @include breakpoint(max, 767) {
        flex-wrap: wrap;
      }
      > li {
        @include breakpoint(max, 767) {
          width: 100%;
        }
      }
      .nav-link {
        padding: 8px 16px;
        font-size: 16px;
        color: $gray_color;
        border-bottom: 2px solid transparent;
        @include transition(all 0.3s ease-in-out);
        @include breakpoint(max, 991) {
          padding: 8px 10px;
          font-size: 14px;
        }
        @include breakpoint(max, 767) {
          padding: 8px 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &.dropdown-toggle {
          &:after {
            border: 0;
            width: 9px;
            height: 5px;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.092' height='5' viewBox='0 0 8.092 5'%3E%3Cpath id='down-arrow-blue' d='M49.861,0l-.954.954L52,4.046,48.907,7.138l.954.954,4.046-4.046Z' transform='translate(8.092 -48.907) rotate(90)' fill='%23909090'/%3E%3C/svg%3E%0A")
              no-repeat;
            vertical-align: middle;
          }
        }
        &:hover {
          color: $primary_color;
        }
        &.active {
          font-weight: 600;
          color: $primary_color;
          border-color: $primary_color;
        }
      }
      .dropdown-menu {
        border: 0;
        padding: 0;
        @include border-radius(3px);
        overflow: hidden;
        min-width: 120px;
        margin: 0;
        @include box-shadow(0px 2px 3px rgba(0, 0, 0, 0.16));
        .dropdown-item {
          padding: 9px 8px;
          font-size: 12px;
          color: $color_gray_dark;
          @include transition(all 0.3s ease-in-out);
          @include breakpoint(max, 991) {
            padding: 8px 15px;
          }
          &.active {
            background: $color_concrete;
          }
        }
        @include breakpoint(max, 767) {
          width: 100%;
          position: relative !important;
          @include transform(translate3d(0px, 0px, 0px) !important);
        }
      }
    }
  }
}
