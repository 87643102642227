@import "./variables";

.search-result-page-wrap{
	padding: 40px 0;
	.container{
		max-width: 606px;
	}
	.search-text{
		font-size: 20px;
		color: $color_gray_dark;
		font-weight: 400;
		strong{
			font-weight: 600;
		}
	}
	.search-tab-nav{
		background: $color_white;
		@include border-radius(4px);
		overflow: hidden;
		margin-bottom: 8px;
		.myprofile-nav{
			.nav-list{
				> ul{
					.nav-link{
						font-size: 15px;
						padding: 8px 15px;
					}
				}
			}
		}
	}
	.search-not-found{
		font-size: 24px;
		font-weight: 600;
		color: $gray_color;
		text-align: center;
		padding-top: 20px;
	}
}