import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import moment from "moment";
import className from "classnames";
import { useMutation, useQuery } from "@apollo/client";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../utils/useAuth";
import {
  FOLLOW_USER,
  SELECT_USER_COMMUNITIES,
} from "../../../../mutations/newsCosmosMutations";
import {
  GET_COMMUNITY_MEMBER_IDS,
  GET_FOLLOWING_COMMUNITY_IDS,
  GET_FOLLOWING_USER_IDS,
} from "../../../../queries/newsCosmosQueries";
import { numFormatter } from "../../../../utils/helper";
import EditIcon from "../../../../public/images/edit.svg";
import "./aboutus.scss";

const AboutUs = ({ isUserLoggedIn, userData, type }) => {
  const auth = useAuth();
  const communityIds = useQuery(GET_FOLLOWING_COMMUNITY_IDS, {
    skip: !auth.user,
  });
  const {
    data, refetch,
  } = useQuery(GET_FOLLOWING_USER_IDS, {
    skip: !auth.user,
  });
  const {
    // loading: memberIdsLoading,
    // error: memberIdsError,
    data: memberIdsData,
  } = useQuery(GET_COMMUNITY_MEMBER_IDS, {
    variables: {
      community_slug: userData.slug,
    },
    skip: type !== "community",
  });
  const [userFollow, mutationData] = useMutation(FOLLOW_USER);
  const [selectCommunity, selectCommunityMutationData] = useMutation(
    SELECT_USER_COMMUNITIES,
  );

  // const loggedInUser = typeof auth.user === "object" ? auth.user : JSON.parse(auth.user);
  let badgeClass = "";
  if (type === "community") {
    badgeClass = userData
      ? className("short-type community-badge", {
        "community-badge-public": userData.type === "public",
        "community-badge-private": userData.type === "private",
        "community-badge-restricted": userData.type === "restricted",
      })
      : "";
  }
  useEffect(() => {
    if (mutationData.data && mutationData.data.userFollow.status === "Fail") {
      toast.error(mutationData.data.userFollow.message);
    }
    if (
      mutationData.data
      && mutationData.data.userFollow.status === "Success"
    ) {
      toast.success(mutationData.data.userFollow.message);
    }
  }, [mutationData.data]);

  // if (loading || !data) {
  //   return (
  //     <div className="about-article-info-box">
  //       <p>
  //         Loading...
  //         <span className="ml-2">
  //           <ClipLoader color="#e76c35" loading={true} size={15} />
  //         </span>
  //       </p>
  //     </div>
  //   );
  // }
  return (
    <div className="about-article-info-box">
      <div className="cover-photo">
        <img
          src={
            type === "user"
              ? userData.userProfile.cover_photo
              : userData.cover_photo
          }
          alt=""
        />
      </div>
      <div className="article-user-info">
        <div className="photo-name">
          <div className="photo">
            <img src={userData.photo} alt="" />
          </div>
          <div className="name">
            {type === "user" ? userData.username : userData.name}
          </div>
        </div>
        <div className="small-txt">
          {type === "user" ? userData.userProfile.location : userData.location}
        </div>
        <div className="member-type">
          <div className="member">
            <span>
              {type === "user"
                ? numFormatter(userData.followers_count) ?? 0
                : numFormatter(userData.community_members_count) ?? 0}
            </span>
            {" "}
            {type === "user" ? "Followers" : "Members"}
          </div>
          {type !== "user" && (
            <div className="community-type">
              <div className={badgeClass}>{userData.type.slice(0, 2)}</div>
              <div className="type">
                <span>{userData.type}</span>
                Community
              </div>
            </div>
          )}
        </div>
        <div className="create-date">
          <span className="icon">
            <EditIcon />
          </span>
          {" "}
          {`Created ${moment(userData.created_at).format("D MMM YYYY")} `}
        </div>
        <div className="join-follow-btn">
          {type === "user" ? (
            <div className="cta">
              {isUserLoggedIn ? (
                JSON.parse(isUserLoggedIn).slug !== userData.slug
                && (data
                && data.getFollowingUserIds
                && data.getFollowingUserIds.following_user_ids
                && data.getFollowingUserIds.following_user_ids.includes(
                  userData.id,
                ) ? (
                  <Button variant="primary" block className="btn-bold">
                    Following
                  </Button>
                  ) : (
                    <Button
                      variant="primary"
                      block
                      className="btn-bold"
                      onClick={async () => {
                        await userFollow({
                          variables: { user_slug: userData.slug },
                        });
                        refetch();
                      }}
                      title="Follow"
                      disabled={mutationData.loading}
                    >
                      Follow
                      {mutationData.loading && (
                      <span className="ml-2">
                        <ClipLoader color="#ffffff" loading={true} size={15} />
                      </span>
                      )}
                    </Button>
                  ))
              ) : (
                <Button
                  variant="primary"
                  block
                  className="btn-bold"
                  onClick={async () => {
                    await userFollow({
                      variables: { user_slug: userData.slug },
                    });
                    refetch();
                  }}
                  title="Follow"
                >
                  Follow
                </Button>
              )}
            </div>
          ) : (
            <div className="join-follow-btn">
              <div className="cta">
                {isUserLoggedIn ? (
                  JSON.parse(isUserLoggedIn).id !== userData.owner.id
                  && (communityIds
                  && communityIds.data
                  && communityIds.data.getFollowingCommunityIds
                  && communityIds.data.getFollowingCommunityIds.following_community_ids.includes(
                    userData.id,
                  ) ? (
                      userData.type === "private" ? (
                        memberIdsData
                      && memberIdsData.getCommunityMemberIds
                      && memberIdsData.getCommunityMemberIds
                        .community_member_ids
                      && memberIdsData.getCommunityMemberIds.community_member_ids.includes(
                        auth.user.id,
                      ) ? (
                        <Button
                          variant="outline-primary"
                          block
                          className="btn-bold"
                          title="Joined"
                        >
                          Joined
                        </Button>
                          ) : (
                            userData.type === "private" && (
                            <Button
                              variant="outline-primary"
                              block
                              className="btn-bold"
                              title="Requested"
                            >
                              Requested
                            </Button>
                            )
                          )
                      ) : (
                        <Button
                          variant="outline-primary"
                          block
                          className="btn-bold"
                          title="Joined"
                        >
                          Joined
                        </Button>
                      )
                    ) : (
                      <Button
                        variant="primary"
                        block
                        className="btn-bold"
                        title="Join"
                        disabled={selectCommunityMutationData.loading}
                        onClick={async () => {
                          await selectCommunity({
                            variables: {
                              community_slug: userData.slug,
                              from: "portal",
                            },
                            refetchQueries: [
                              {
                                query: GET_FOLLOWING_COMMUNITY_IDS,
                              },
                              {
                                query: GET_COMMUNITY_MEMBER_IDS,
                                variables: {
                                  community_slug: userData.slug,
                                },
                              },
                            ],
                          });
                        }}
                      >
                        Join
                        {selectCommunityMutationData.loading && (
                        <span className="ml-2">
                          <ClipLoader
                            color="#ffffff"
                            loading={true}
                            size={15}
                          />
                        </span>
                        )}
                      </Button>
                    ))
                ) : (
                  <Link to="/login">
                    <Button
                      variant="primary"
                      block
                      className="btn-bold"
                      title="Join"
                    >
                      Join
                    </Button>
                  </Link>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default AboutUs;