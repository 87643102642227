import {
  ApolloClient, InMemoryCache, HttpLink, ApolloLink, concat,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import history from "./history";
/* eslint-disable import/no-cycle */

// const httpLink = createHttpLink({
//   uri: process.env.NEWS_COSMOS_APP_API_ENDPOINT,
//   headers: {
//     authorization: `Bearer ${localStorage.getItem("accessToken")}`,
//   },
// });

const httpLink = new HttpLink({ uri: process.env.NEWS_COSMOS_APP_API_ENDPOINT });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      authorization: `Bearer ${localStorage.getItem("accessToken")}` || null,
    },
  });

  return forward(operation);
});

const errorLink = onError(({
  graphQLErrors, networkError,
}) => {
  if (graphQLErrors) {
    if (graphQLErrors[0].message === "Unauthenticated.") {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("showCommunity");
      localStorage.removeItem("user");
      localStorage.removeItem("lCount");
      window.location.href = "/login";
    }
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
};

// const cache = new InMemoryCache();
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([errorLink, concat(authMiddleware, httpLink)]),
  defaultOptions,
});
// const client = new ApolloClient({
//   link: httpLink,
//   cache,
//   defaultOptions,
// });

export default client;