import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../utils/useAuth";
import SearchBox from "../../Forms/SearchBox";
import TopicSelect from "./TopicSelect";
import MobileMenu from "./MobileMenu";
import Logout from "../../Logout";
import Notifications from "./Notifications";
import Logo from "../../../public/images/logo.svg";
import "./header.scss";

const Header = () => {
  const auth = useAuth();
  return (
    <div className="header-wrap">
      <header>
        <div className="header-left">
          <div className="logo">
            <Link to="/">
              <span className="logo-icon">
                <Logo />
              </span>
              <span className="logo-text">News Cosmos</span>
            </Link>
          </div>
          <div className="menu">
            <ul>
              <li className="home-link">
                <Link to="/">Home</Link>
              </li>
              {auth.user ? (
                <li>
                  <Link
                    to={`/user/${
                      typeof auth.user === "object"
                        ? auth.user.slug
                        : JSON.parse(auth.user).slug
                    }/joined-communities`}
                  >
                    Communities
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/signup">Communities</Link>
                </li>
              )}
              <li>
                <TopicSelect />
              </li>
            </ul>
          </div>
        </div>
        <SearchBox />
        <div className="header-right">
          <div className="help-link">
            <Link to="/help">
              {/* <HelpIcon />
              {" "} */}
              Help
            </Link>
          </div>
          {auth.user ? (
            <>
              <Notifications />
              <Logout auth={auth} />
            </>
          ) : (
            <div className="cta">
              <Link to="/login" className="btn btn-primary rounded outline">
                Login
              </Link>
              <Link to="/signup" className="btn btn-primary rounded">
                Sign up
              </Link>
            </div>
          )}
          <MobileMenu />
        </div>
      </header>
    </div>
  );
};

export default Header;