import React from "react";
import Select from "react-select";
import { useQuery } from "@apollo/client";
import { GET_OWN_FOLLOWING_COMMUNITIES } from "../queries/newsCosmosQueries";

const loadCommunityOptions = (data) =>
  data.getOwnAndFollowingCommunities.communities.map((edge) => {
    const { id, name } = edge;
    return {
      value: name,
      label: name,
      id,
    };
  });

export default ({ field, onChange, selectedVal, disabled }) => {
  const { loading, error, data } = useQuery(GET_OWN_FOLLOWING_COMMUNITIES);
  if (loading || error) {
    return <Select placeholder="Select Community" cacheOptions isLoading />;
  }
  if (data.getOwnAndFollowingCommunities) {
    const communityOptions = loadCommunityOptions(data);
    return (
      <Select
        placeholder="Select Community"
        isClearable={false}
        isSearchable={true}
        cacheOptions
        id="community_id"
        options={communityOptions}
        className="selectpicker"
        classNamePrefix="select"
        onChange={(value) => onChange(field, value.id)}
        value={
          selectedVal
            ? communityOptions.find((option) => option.id === selectedVal)
            : []
        }
        isDisabled={disabled}
      />
    );
  }
  return null;
};
