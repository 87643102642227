import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { ClipLoader } from "react-spinners";
import { GET_USER_FOLLOWING } from "../../../../queries/newsCosmosQueries";
import "../CommunitiesNearby/communitiesnearby.scss";
import { useAuth } from "../../../../utils/useAuth";

const FollowingBox = () => {
  const auth = useAuth();
  const { loading, error, data } = useQuery(GET_USER_FOLLOWING, {
    variables: {
      limit: 5,
      current_page: 1,
    },
  });
  if (loading || error) {
    return (
      <div className="community-nearby-box">
        <h3>My Following</h3>
        <div className="community-nearby-list">
          <p>
            Loading...
            <span className="ml-2">
              <ClipLoader color="#e76c35" loading={true} size={15} />
            </span>
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className="community-nearby-box">
      <h3>My Following</h3>
      <div className="community-nearby-list">
        {data
          && data.getUserFollowing
          && data.getUserFollowing.followings.length > 0 && (
            <ul>
              {data.getUserFollowing.followings.map((user) => (
                <li key={Math.random()}>
                  <div className="community-info">
                    <div className="photo">
                      <img src={user.following.photo} alt="" />
                    </div>
                    <div className="name-info">
                      <div className="community-name">
                        <Link to={`/user/${user.following.slug}/posts`}>
                          {user.following.username}
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
        )}
        {data
          && data.getUserFollowing
          && data.getUserFollowing.followings.length === 0 && (
            <p>Nothing to see here — yet</p>
        )}
        {data
          && data.getUserFollowing
          && data.getUserFollowing.next_page !== 0 && (
            <div className="create-article-btn d-block">
              <Link
                to={`/user/${auth.user.slug}/following`}
                className="btn-bold btn-primary btn btn-block"
              >
                VIEW ALL FOLLOWING
              </Link>
            </div>
        )}
      </div>
    </div>
  );
};

export default FollowingBox;