@import "./variables";

.issues-list{
	.issues-item{
		margin-bottom: 8px;
	}
}
.issues-item{
	background: $color_white;
	@include border-radius(3px);
	padding: 12px 16px;
	display: flex;
	align-items: center;
	.no{
		flex-shrink: 0;
		width: 28px;
		font-size: 16px;
		font-weight: 600;
		color: $color_gray_dark;
	}
	&-info{
		width: calc(100% - 28px);
		display: flex;
		align-items: center;
		.photo{
			flex-shrink: 0;
			width: 56px;
			img{
				@include border-radius(3px);
			}
		}
		.title{
			width: calc(100% - 56px);
			padding-left: 8px;
			font-size: 14px;
			font-weight: 600;
			color: $primary_color;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			display: flex;
			a{
				display: block;
				color: $primary_color;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}
}