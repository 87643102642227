import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../public/images/logo.svg";
import "./headersmall.scss";

const HeaderSmall = () => (
  <div className="header-wrap-small">
    <header>
      <div className="logo">
        <Link to="/">
          <span className="logo-icon">
            <Logo />
          </span>
          <span className="logo-text">News Cosmos</span>
        </Link>
      </div>
    </header>
  </div>
);

export default HeaderSmall;