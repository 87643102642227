import React from "react";
import "./ads-card.scss";

const AdsCard = () => (
  <div className="ads-card">
    <div className="ads-card-box">
      <div className="ads-title">advertisement</div>
      <div className="ads-body-area">
        <img src="https://via.placeholder.com/468x60/F2F2F2/E2E2E2?text=468x60" alt="advrt" width="468" height="60" />
      </div>
    </div>
  </div>
);

export default AdsCard;