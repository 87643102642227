// color
$color_inherit: inherit;
$color_white: #ffffff;
$color_black: #000000;
$color_log_cabin: #222222;
$color_mine_shaft: #333333;
$color_tundora: #444444;
$color_storm_dust: #666666;
$color_tapa: #777777;
$color_suva_gray: #888888;
$color_mountain_mist: #999999;
$color_silver_chalice: #aaaaaa;
$color_celeste: #cccccc;
$color_gray_nurse: #eeeeee;
$color_error: #ff4349;
$color_mercury: #eaeaea;

$body_bg_color: #F2F2F2;

$primary_color: #228CB7;
$secondary_color: #ED6C27;
$success: #3CBA54;

$header_bg_color: #0D2934;
$heading_color: #1A1A1A;
$gray_color: #909090;
$color_mercury2: #e2e2e2;
$color_astral: #3f809a;
$mercury: #e5e5e5;
$color_gigas: #5e3f9a;
$color_green: #008900;
$color_yellow: #E0BD00;
$color_red: #DD0000;
$label_color: #1A1A1A;
$color_firefly: #0d2934;
$color_concrete: #f2f2f2;
$color_gray_dark: #1a1a1a;
$color_cerulean_blue: #2250b7;
$color_athens_gray: #f6f7f8;
$color_dove_gray: #707070;
$color_fantasy: #f7eeea;
$color_zumthor: #eaf4ff;
$color_link_water: #dee8f2;
$color_dorado: #515151;
$color_alabaster: #f9f9f9;
$color_mercury3: #e9e9e9;
$color_elephant: #133948;
$color_regent_gray: #8a9da4;


$primary_font: "proxima-nova",sans-serif;


@mixin border-radius($radius) {
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
}
// @include border-radius(4px);

@mixin transition($args...) {
	transition: $args;
	-webkit-transition: $args;
	-moz-transition: $args;
	-ms-transition: $args;
	-o-transition: $args;
}
// @include transition(all 0.3s ease-in-out);

@mixin box-shadow($args...) {
	box-shadow: $args;
	-webkit-box-shadow: $args;
	-moz-box-shadow: $args;
}
// @include box-shadow();

@mixin transform-rotate($args...) {
	-webkit-transform: rotate($args);
	-moz-transform: rotate($args);
	-o-transform: rotate($args);
	-ms-transform: rotate($args);
	transform: rotate($args);
}
// @include transform-rotate();

@mixin transform($args...) {
	-webkit-transform: ($args);
	-moz-transform: ($args);
	-ms-transform: ($args);
	-o-transform: ($args);
	transform: ($args);
}
// @include transform();

@mixin transition-delay($args...) {
	-webkit-transition-delay: ($args);
	-moz-transition-delay: ($args);
	-o-transition-delay: ($args);
	transition-delay: ($args);
}
// @include transition-delay();

@mixin optional-at-root($sel) {
	@at-root #{if(not &, $sel, selector-append(&, $sel))} {
		@content;
	}
}

@mixin placeholder {
	@include optional-at-root("::-webkit-input-placeholder") {
		@content;
	}

	@include optional-at-root(":-moz-placeholder") {
		@content;
	}

	@include optional-at-root("::-moz-placeholder") {
		@content;
	}

	@include optional-at-root(":-ms-input-placeholder") {
		@content;
	}
}
// @include placeholder{value};

// media min & max
@mixin breakpoint($max_min, $point) {
	@if $max_min==min {
		@media (min-width: ($point + px)) {
			@content;
		}
	}
	@if $max_min==max {
		@media (max-width: ($point + px)) {
			@content;
		}
	}
}
// @include breakpoint( max , 767){
// 	padding: 10px 25px;
// }
// @include breakpoint( min , 1366){
// 	padding: 15px 35px;
// }

@mixin breakpoint-between($lower, $upper) {
	@media (min-width: ($lower + px)) and (max-width: ($upper + px)) {
		@content;
	}
}

// @include breakpoint-between( 1025 , 1140){
// 	width: 524px
// }
