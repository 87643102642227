import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import className from "classnames";
import swal from "sweetalert";
import moment from "moment";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import { useMutation, useQuery } from "@apollo/client";
import { BarLoader, ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import {
  GET_COMMUNITY_MEMBER_IDS,
  GET_FOLLOWING_COMMUNITY_IDS,
  GET_USER_BLOCK_USER_IDS,
} from "../../../queries/newsCosmosQueries";
import {
  LEAVE_COMMUNITY,
  SELECT_USER_COMMUNITIES,
} from "../../../mutations/newsCosmosMutations";
import EditIcon from "../../../public/images/edit.svg";
import MenuIcon from "../../../public/images/discussion-icon.svg";
import GlobeIcon from "../../../public/images/globe.svg";
import MailIcon from "../../../public/images/mail.svg";
import LocationIcon from "../../../public/images/location.svg";
import "../ProfileHeader/_profileheader.scss";
import { numFormatter } from "../../../utils/helper";
import { useAuth } from "../../../utils/useAuth";

const ProfileHeader = ({ community, history }) => {
  const auth = useAuth();
  const [open, setOpen] = useState(false);
  const badgeClass = className("short-type community-badge", {
    "community-badge-public": community.type === "public",
    "community-badge-private": community.type === "private",
    "community-badge-restricted": community.type === "restricted",
  });
  const [leaveCommunity, leaveCommunityMutationData] =
    useMutation(LEAVE_COMMUNITY);
  const { data } = useQuery(GET_FOLLOWING_COMMUNITY_IDS, {
    skip: !auth.user,
  });
  const { data: blockUserIdsData } = useQuery(GET_USER_BLOCK_USER_IDS, {
    variables: {
      slug: community.owner.slug,
    },
    skip: !auth.user,
  });

  const {
    loading: memberIdsLoading,
    error: memberIdsError,
    data: memberIdsData,
  } = useQuery(GET_COMMUNITY_MEMBER_IDS, {
    variables: {
      community_slug: community.slug,
    },
  });

  const [selectCommunity, mutationData] = useMutation(SELECT_USER_COMMUNITIES);

  useEffect(() => {
    if (
      mutationData.data &&
      mutationData.data.selectCommunity.status === "Fail"
    ) {
      toast.error(mutationData.data.selectCommunity.message);
    }
    if (
      mutationData.data
      && mutationData.data.selectCommunity.status === "Success"
    ) {
      toast.success(mutationData.data.selectCommunity.message);
    }
  }, [mutationData.data]);

  useEffect(() => {
    if (
      leaveCommunityMutationData.data
      && leaveCommunityMutationData.data.leaveCommunity.status === "Fail"
    ) {
      toast.error(leaveCommunityMutationData.data.leaveCommunity.message);
    }
    if (
      leaveCommunityMutationData.data
      && leaveCommunityMutationData.data.leaveCommunity.status === "Success"
    ) {
      toast.success(leaveCommunityMutationData.data.leaveCommunity.message);
    }
  }, [leaveCommunityMutationData.data]);


  if (memberIdsLoading || memberIdsError) {
    return (
      <div className="page-loader">
        <div>
          Loading...
          <BarLoader color="#ED6C27" width="150px" height="5px" />
        </div>
      </div>
    );
  }
  const loggedInUser =
    typeof auth.user === "object" ? auth.user : JSON.parse(auth.user);
  if (!community.status) {
    toast.error("Community not found");
    history.push(`/`);
    return null;
  }
  if (community.owner.id === loggedInUser.id) {
    history.push(`/user/community/${community.slug}/posts`);
    return null;
  }
  if (data && memberIdsData && community.type === "restricted") {
    if (community.owner.id !== loggedInUser.id) {
      if (
        memberIdsData.getCommunityMemberIds.community_member_ids.length === 0 ||
        !memberIdsData.getCommunityMemberIds.community_member_ids.includes(
          loggedInUser.id
        )
      ) {
        history.push("/");
        return null;
      }
    }
  }
  if (
    data &&
    blockUserIdsData &&
    blockUserIdsData.getUserBlockedUserIds &&
    blockUserIdsData.getUserBlockedUserIds.blocked_user_ids
  ) {
    if (
      blockUserIdsData.getUserBlockedUserIds.blocked_user_ids.includes(
        auth.user.id
      )
    ) {
      history.push("/");
      return null;
    }
  }
  return (
    <div className="myprofile-header-wrap">
      <div className="profile-coverphoto">
        <img src={community.cover_photo} alt="cover" />
      </div>
      <div className="container container-sm">
        <div className="myprofile-intro-nav">
          <div className="myprofile-intro">
            <div className="profile-photo">
              <img src={community.photo} alt="avatar" />
            </div>
            <div className="profile-info">
              <div className="profile-meta">
                <div className="left">
                  <h3>{community.name}</h3>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="followers">
                      <span>
                        {numFormatter(community.community_members_count) ?? 0}
                      </span>{" "}
                      Members
                    </div>
                    <div className="create-date">
                      <span className="icon">
                        <EditIcon />
                      </span>{" "}
                      Created{" "}
                      {moment(community.created_at).format("DD MMM YYYY")} by{" "}
                      {community.owner.username}
                    </div>
                  </div>
                </div>
                <div className="right">
                  <span className={badgeClass}>
                    {community.type.slice(0, 2)}
                  </span>
                  {community.type === "restricted" && (
                    <Button
                      variant="outline-primary"
                      className="btn-bold"
                      onClick={async () => {
                        swal({
                          title: "Are you sure want to leave the community?",
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then(async (willDelete) => {
                          if (willDelete) {
                            await leaveCommunity({
                              variables: { community_slug: community.slug },
                              refetchQueries: [
                                {
                                  query: GET_FOLLOWING_COMMUNITY_IDS,
                                },
                                {
                                  query: GET_COMMUNITY_MEMBER_IDS,
                                  variables: {
                                    community_slug: community.slug,
                                  },
                                },
                              ],
                            });
                          }
                        });
                      }}
                      title="Leave"
                      disabled={leaveCommunityMutationData.loading}
                    >
                      Leave
                      {leaveCommunityMutationData.loading && (
                        <span className="ml-2">
                          <ClipLoader
                            color="#3ca7d4"
                            loading={true}
                            size={15}
                          />
                        </span>
                      )}
                    </Button>
                  )}
                  {community.type !== "restricted" &&
                    (data &&
                    data.getFollowingCommunityIds &&
                    data.getFollowingCommunityIds.following_community_ids.includes(
                      community.id
                    ) ? (
                      community.type === "private" ? (
                        memberIdsData &&
                        memberIdsData.getCommunityMemberIds &&
                        memberIdsData.getCommunityMemberIds
                          .community_member_ids &&
                        memberIdsData.getCommunityMemberIds.community_member_ids.includes(
                          auth.user.id
                        ) ? (
                          <Button
                            variant="outline-primary"
                            className="btn-bold"
                            onClick={async () => {
                              swal({
                                title:
                                  "Are you sure want to leave the community?",
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                              }).then(async (willDelete) => {
                                if (willDelete) {
                                  await leaveCommunity({
                                    variables: {
                                      community_slug: community.slug,
                                    },
                                    refetchQueries: [
                                      {
                                        query: GET_FOLLOWING_COMMUNITY_IDS,
                                      },
                                      {
                                        query: GET_COMMUNITY_MEMBER_IDS,
                                        variables: {
                                          community_slug: community.slug,
                                        },
                                      },
                                    ],
                                  });
                                }
                              });
                            }}
                            title="Leave"
                            disabled={leaveCommunityMutationData.loading}
                          >
                            Leave
                            {leaveCommunityMutationData.loading && (
                              <span className="ml-2">
                                <ClipLoader
                                  color="#3ca7d4"
                                  loading={true}
                                  size={15}
                                />
                              </span>
                            )}
                          </Button>
                        ) : (
                          community.type === "private" && (
                            <Button
                              variant="primary"
                              className="btn-bold"
                              title="Requested"
                            >
                              Requested
                            </Button>
                          )
                        )
                      ) : (
                        <Button
                          variant="outline-primary"
                          className="btn-bold"
                          onClick={async () => {
                            swal({
                              title:
                                "Are you sure want to leave the community?",
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then(async (willDelete) => {
                              if (willDelete) {
                                await leaveCommunity({
                                  variables: { community_slug: community.slug },
                                  refetchQueries: [
                                    {
                                      query: GET_FOLLOWING_COMMUNITY_IDS,
                                    },
                                    {
                                      query: GET_COMMUNITY_MEMBER_IDS,
                                      variables: {
                                        community_slug: community.slug,
                                      },
                                    },
                                  ],
                                });
                              }
                            });
                          }}
                          title="Leave"
                          disabled={leaveCommunityMutationData.loading}
                        >
                          Leave
                          {leaveCommunityMutationData.loading && (
                            <span className="ml-2">
                              <ClipLoader
                                color="#3ca7d4"
                                loading={true}
                                size={15}
                              />
                            </span>
                          )}
                        </Button>
                      )
                    ) : (
                      <Button
                        variant="outline-primary"
                        className="btn-bold"
                        onClick={async () => {
                          await selectCommunity({
                            variables: {
                              community_slug: community.slug,
                              from: "portal",
                            },
                            refetchQueries: [
                              {
                                query: GET_FOLLOWING_COMMUNITY_IDS,
                              },
                              {
                                query: GET_COMMUNITY_MEMBER_IDS,
                                variables: {
                                  community_slug: community.slug,
                                },
                              },
                            ],
                          });
                        }}
                        title="Join"
                        disabled={mutationData.loading}
                      >
                        Join
                        {mutationData.loading && (
                          <span className="ml-2">
                            <ClipLoader
                              color="#3ca7d4"
                              loading={true}
                              size={15}
                            />
                          </span>
                        )}
                      </Button>
                    ))}
                </div>
              </div>
              <div className="txt">{community.description}</div>
              <div className="org-personal-info">
                <ul>
                  {community.website && (
                    <li>
                      <div className="icon">
                        <GlobeIcon />
                      </div>
                      <a
                        href={community.website}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {community.website}
                      </a>
                    </li>
                  )}

                  {community.owner.email && (
                    <li>
                      <div className="icon">
                        <MailIcon />
                      </div>
                      <a href={`mailto:${community.owner.email}`}>
                        {community.owner.email}
                      </a>
                    </li>
                  )}
                  <li>
                    <div className="icon">
                      <LocationIcon />
                    </div>
                    {community.location}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {community.type === "private" ? (
            memberIdsData &&
            memberIdsData.getCommunityMemberIds &&
            memberIdsData.getCommunityMemberIds.community_member_ids &&
            memberIdsData.getCommunityMemberIds.community_member_ids.includes(
              auth.user.id
            ) ? (
              <div className="myprofile-nav">
                <Button
                  onClick={() => setOpen(!open)}
                  aria-expanded={open}
                  variant="nav"
                >
                  <MenuIcon /> Menu
                </Button>
                <Collapse in={open}>
                  <div className="nav-list">
                    <ul>
                      <li>
                        <NavLink
                          className="nav-link"
                          exact
                          to={`/community/${community.slug}/posts`}
                        >
                          Posts
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="nav-link"
                          exact
                          to={`/community/${community.slug}/members`}
                        >
                          Members
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </Collapse>
              </div>
            ) : (
              ""
            )
          ) : (
            <div className="myprofile-nav">
              <Button
                onClick={() => setOpen(!open)}
                aria-expanded={open}
                variant="nav"
              >
                <MenuIcon /> Menu
              </Button>
              <Collapse in={open}>
                <div className="nav-list">
                  <ul>
                    <li>
                      <NavLink
                        className="nav-link"
                        exact
                        to={`/community/${community.slug}/posts`}
                      >
                        Posts
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="nav-link"
                        exact
                        to={`/community/${community.slug}/members`}
                      >
                        Members
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
