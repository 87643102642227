@import "./variables";

.calendar-inline{
	.flatpickr-input{
		display: none;
	}
	.flatpickr-calendar{
		box-shadow: none;
		border-radius: 0;
		top: 0;
		margin: 0 auto;
	}
}

.flatpickr-calendar{
	.flatpickr-months{
		.flatpickr-prev-month,
		.flatpickr-next-month{
			height: 26px;
			padding: 5px;
			border: 1px solid $color_mercury2;
			@include border-radius(5px);
			svg{
				display: block;
				fill: $primary_color;
			}
		}
		.flatpickr-month{
			.flatpickr-current-month{
				font-size: 16px;
				font-weight: 600;
				color: $primary_color;
				height: 26px;
				padding-top: 4px;
				.flatpickr-monthDropdown-months{
					font-weight: 600;
				}
				input.cur-year{
					font-weight: 600;
					background: transparent;
				}
			}
		}
	}
	.flatpickr-weekdays{
		margin-bottom: 16px;
		padding-bottom: 9px;
		padding-top: 8px;
		border-bottom: 1px dashed $color_mercury2;
	}
	span.flatpickr-weekday{
		font-size: 12px;
		font-weight: 400;
		text-transform: uppercase;
	}
	.flatpickr-days{
		padding-bottom: 10px;
	}
	.flatpickr-day{
		font-size: 12px;
		color: $gray_color;
		&.flatpickr-disabled,
		&.flatpickr-disabled:hover,
		&.prevMonthDay,
		&.nextMonthDay,
		&.notAllowed,
		&.notAllowed.prevMonthDay,
		&.notAllowed.nextMonthDay{
			color: $color_mercury2;
		}
		&.today{
			background: $primary_color;
			border-color: $primary_color;
			color: $color_white;
		}
		&.selected,
		&.startRange,
		&.endRange,
		&.selected.inRange,
		&.startRange.inRange,
		&.endRange.inRange,
		&.selected:focus,
		&.startRange:focus,
		&.endRange:focus,
		&.selected:hover,
		&.startRange:hover,
		&.endRange:hover,
		&.selected.prevMonthDay,
		&.startRange.prevMonthDay,
		&.endRange.prevMonthDay,
		&.selected.nextMonthDay,
		&.startRange.nextMonthDay,
		&.endRange.nextMonthDay{
			background: $secondary_color;
			border-color: $secondary_color;
			color: $color_white;
		}
	}
	&.hasTime{
		.flatpickr-time{
			height: auto;
			max-height: 100px;
			border-color: $color_mercury2;
		}
	}
}
.flatpickr-time{
	padding: 15px 0;
	height: auto;
	max-height: 100px;
	input.flatpickr-hour{
		font-weight: 400;
	}
	input, .flatpickr-am-pm{
		border: 1px solid $color_mercury2;
		color: $secondary_color;
		font-size: 12px;
		height: 40px;
		@include border-radius(5px);
	}
	.flatpickr-am-pm{
		margin: 0 4px;
	}
	.numInputWrapper{
		margin: 0 4px;
		&:hover{
			background: transparent;
		}
		span{
			opacity: 1;
		}
	}
	.flatpickr-time-separator{
		display: none;
	}
}