@import "./variables";

.comment-wrap {
  padding: 16px 8px;
  .comment-box {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 15px;
    .avatar {
      width: 38px;
      img {
        width: 30px;
        height: 30px;
        @include border-radius(100%);
      }
    }
    .comment-info {
      width: calc(100% - 38px);
      padding-top: 5px;
      .comment-meta {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: 8px;
        .meta-name {
          font-size: 12px;
          font-weight: 600;
          color: $color_gray_dark;
          padding-right: 8px;
          a {
            color: $color_gray_dark;
          }
        }
        .meta-date {
          font-size: 12px;
          line-height: 14px;
          padding-left: 8px;
          border-left: 1px solid rgba($gray_color, 0.5);
        }
      }
    }
    .comment-txt {
      font-size: 14px;
      line-height: 18px;
      color: $color_gray_dark;
      padding-bottom: 6px;
    }
    &.comment-box-reply {
      padding-left: 30px;
      .avatar {
        @include breakpoint(max, 767) {
          display: none;
        }
      }
      .comment-info {
        @include breakpoint(max, 767) {
          width: 100% !important;
        }
        .comment-meta {
          @include breakpoint(max, 767) {
            display: none;
          }
        }
      }
      .comment-form-box {
        .comment-form-footer {
          .cta {
            .icon {
              display: none;
              @include breakpoint(max, 767) {
                display: block;
                svg {
                  display: block;
                }
              }
            }
            .btn {
              @include breakpoint(max, 767) {
                font-size: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                width: 36px;
                height: 36px;
                .ml-2 {
                  margin: 0 !important;
                  display: block;
                  height: 20px;
                  padding-top: 3px;
                }
              }
            }
          }
        }
      }
    }
  }
  .commentlist {
    .children {
      padding-left: 38px;
      .children {
        padding-left: 30px;
      }
      .comment-box {
        .avatar {
          width: 30px;
          img {
            width: 22px;
            height: 22px;
          }
        }
        .comment-info {
          width: calc(100% - 30px);
          padding-top: 3px;
        }
      }
    }
  }
}
