import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { GoogleLogin } from "react-google-login";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import ClipLoader from "react-spinners/ClipLoader";
import { SOCIAL_LOGIN } from "../mutations/newsCosmosMutations";
import { useAuth } from "../utils/useAuth";
import GoogleIcon from "../public/images/google.svg";

const GoogleSocialLogin = ({ history }) => {
  const auth = useAuth();
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [location, setLocation] = useState("");
  const [socialLogin, mutationData] = useMutation(SOCIAL_LOGIN);

  useEffect(() => {
    if (mutationData.error && mutationData.error.graphQLErrors.length > 0) {
      toast.error(mutationData.error.graphQLErrors[0].extensions.reason);
    }
  }, [mutationData.error]);

  useEffect(() => {
    fetch("https://pro.ip-api.com/json?key=cdozVIPpXEsXHwT")
      .then((response) => response.json())
      .then((res) => {
        setLatitude(res.lat);
        setLongitude(res.lon);
        setLocation(res.city);
      });
  }, []);

  useEffect(() => {
    if (
      mutationData.data &&
      mutationData.data.socialRegisterOrLogin.status === "Fail"
    ) {
      toast.error(mutationData.data.socialRegisterOrLogin.message);
    }
    if (
      mutationData.data &&
      mutationData.data.socialRegisterOrLogin.status === "Success"
    ) {
      localStorage.setItem(
        "lCount",
        mutationData.data.socialRegisterOrLogin.user_login_count
      );
      localStorage.setItem(
        "accessToken",
        mutationData.data.socialRegisterOrLogin.tokens.access_token
      );
      auth.signIn(
        mutationData.data.socialRegisterOrLogin.tokens.access_token,
        mutationData.data.socialRegisterOrLogin.user
      );
      toast.success(mutationData.data.socialRegisterOrLogin.message);
      history.push("/");
    }
  }, [mutationData.data]);

  const googleResponse = async (response) => {
    await socialLogin({
      variables: {
        email: response.profileObj.email,
        googleId: response.profileObj.googleId,
        accessToken: response.accessToken,
        username: response.profileObj.name ? response.profileObj.name : null,
        photo: response.profileObj.imageUrl
          ? response.profileObj.imageUrl
          : null,
        lat: latitude,
        long: longitude,
        location,
      },
    });
  };
  const onFailure = (error) => {
    console.log(error);
  };

  return (
    <div className="login-with-google">
      <div className="google-btn">
        <GoogleLogin
          clientId="120245399382-iafb6jrksb6or4qovj5qv3u172m0u41m.apps.googleusercontent.com"
          render={(renderProps) => (
            <Button
              variant="success"
              block
              className="btn-bold"
              onClick={renderProps.onClick}
              disabled={mutationData.loading}
            >
              <span className="icon">
                <GoogleIcon />
              </span>
              <span className="txt">Continue with google</span>
              {mutationData.loading && (
                <span className="ml-2">
                  <ClipLoader color="#ffffff" loading={true} size={15} />
                </span>
              )}
            </Button>
          )}
          buttonText="Login"
          onSuccess={googleResponse}
          onFailure={onFailure}
          cookiePolicy="single_host_origin"
        />
      </div>
      <div className="or">
        <span>OR</span>
      </div>
    </div>
  );
};

export default withRouter(GoogleSocialLogin);
