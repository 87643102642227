import { gql } from "@apollo/client";

export const SIGNUP = gql`
  mutation register(
    $username: String!
    $email: String!
    $password: String!
    $location: String
    $lat: Float
    $long: Float
  ) {
    register(
      input: {
        username: $username
        email: $email
        password: $password
        location: $location
        lat: $lat
        long: $long
      }
    ) {
      id
      status
      message
      user {
        email
      }
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation verifyEmail($token: String!) {
    verifyEmail(input: { token: $token }) {
      code
      status
      message
      user {
        id
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(input: { email: $email }) {
      code
      status
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $token: String!
    $password: String!
    $password_confirmation: String!
  ) {
    resetPassword(
      input: {
        token: $token
        password: $password
        password_confirmation: $password_confirmation
      }
    ) {
      code
      status
      message
    }
  }
`;

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      code
      status
      message
      tokens {
        access_token
        refresh_token
        expires_in
        token_type
      }
      id
      user_login_count
      user {
        id
        slug
        email
        username
        role
        photo
      }
    }
  }
`;

export const RESEND_VERIFY_EMAIL = gql`
  mutation resendVerifyEmail($email: String!) {
    resendVerifyEmail(input: { email: $email }) {
      code
      status
      message
      user {
        id
      }
    }
  }
`;

export const SOCIAL_LOGIN = gql`
  mutation socialRegisterOrLogin(
    $email: String!
    $googleId: String!
    $accessToken: String!
    $username: String
    $photo: String
    $lat: Float!
    $long: Float!
    $location: String!
  ) {
    socialRegisterOrLogin(
      input: {
        email: $email
        googleId: $googleId
        accessToken: $accessToken
        username: $username
        photo: $photo
        lat: $lat
        long: $long
        location: $location
      }
    ) {
      code
      status
      message
      tokens {
        access_token
        refresh_token
        expires_in
        token_type
      }
      id
      user_login_count
      user {
        id
        slug
        email
        username
        role
        photo
      }
    }
  }
`;

export const CREATE_POST = gql`
  mutation createPost(
    $topic_id: ID!
    $url: String!
    $title: String!
    $description: String!
    $image_url: String!
    $organization: String!
    $tags: [String]
    $quality: Int
    $facts: Int
    $objectivity: Int
    $accuracy: Int
    $edited: Boolean!
    $schedule: String
    $timezone: String!
  ) {
    createPost(
      input: {
        topic_id: $topic_id
        url: $url
        title: $title
        description: $description
        image_url: $image_url
        organization: $organization
        tags: $tags
        post_ratings: {
          quality: $quality
          facts: $facts
          objectivity: $objectivity
          accuracy: $accuracy
          edited: $edited
        }
        schedule: $schedule
        timezone: $timezone
      }
    ) {
      code
      status
      message
      post {
        id
      }
    }
  }
`;

export const UPDATE_POST = gql`
  mutation updatePost(
    $post_slug: String!
    $topic_id: ID!
    $description: String!
    $tags: [String]
    $quality: Int
    $facts: Int
    $objectivity: Int
    $accuracy: Int
    $edited: Boolean!
    $schedule: String
    $timezone: String!
  ) {
    updatePost(
      post_slug: $post_slug
      input: {
        topic_id: $topic_id
        description: $description
        tags: $tags
        post_ratings: {
          quality: $quality
          facts: $facts
          objectivity: $objectivity
          accuracy: $accuracy
          edited: $edited
        }
        schedule: $schedule
        timezone: $timezone
      }
    ) {
      code
      status
      message
      post {
        id
      }
    }
  }
`;

export const CREATE_COMMUNITY = gql`
  mutation createCommunity(
    $type: CommunityType!
    $name: String!
    $photo: String
    $coverPhoto: String
    $description: String!
    $location: String!
    $lat: Float
    $long: Float
  ) {
    createCommunity(
      input: {
        type: $type
        name: $name
        photo: $photo
        cover_photo: $coverPhoto
        description: $description
        location: $location
        lat: $lat
        long: $long
      }
    ) {
      code
      status
      message
      community {
        id
      }
    }
  }
`;

export const CREATE_COMMUNITY_POST = gql`
  mutation createCommunityPost(
    $community_id: ID!
    $title: String!
    $image_url: String
    $document_url: String
    $description: String
    $schedule: String
    $tags: [String]
    $timezone: String!
  ) {
    createCommunityPost(
      input: {
        community_id: $community_id
        title: $title
        image_url: $image_url
        document_url: $document_url
        description: $description
        schedule: $schedule
        tags: $tags
        timezone: $timezone
      }
    ) {
      code
      status
      message
      post {
        id
      }
    }
  }
`;

export const UPDATE_COMMUNITY_POST = gql`
  mutation updateCommunityPost(
    $post_slug: String!
    $community_id: ID!
    $title: String!
    $image_url: String
    $document_url: String
    $description: String
    $schedule: String
    $tags: [String]
    $timezone: String!
  ) {
    updateCommunityPost(
      post_slug: $post_slug
      input: {
        community_id: $community_id
        title: $title
        image_url: $image_url
        document_url: $document_url
        description: $description
        schedule: $schedule
        tags: $tags
        timezone: $timezone
      }
    ) {
      code
      status
      message
      post {
        id
      }
    }
  }
`;

export const UPDATE_POST_RATING = gql`
  mutation postRating(
    $post_slug: String!
    $quality: Int
    $facts: Int
    $objectivity: Int
    $accuracy: Int
    $edited: Boolean!
  ) {
    postRating(
      post_slug: $post_slug
      post_ratings: {
        quality: $quality
        facts: $facts
        objectivity: $objectivity
        accuracy: $accuracy
        edited: $edited
      }
    ) {
      code
      status
      message
      post {
        id
        slug
      }
    }
  }
`;
export const CREATE_COMMUNITY_RULE = gql`
  mutation createCommunityRule(
    $community_slug: String!
    $title: String!
    $description: String!
  ) {
    createCommunityRule(
      input: {
        community_slug: $community_slug
        title: $title
        description: $description
      }
    ) {
      code
      status
      message
      rule {
        id
        title
      }
    }
  }
`;

export const UPDATE_COMMUNITY_RULE = gql`
  mutation updateCommunityRule(
    $community_rule_id: ID!
    $community_slug: String!
    $title: String!
    $description: String!
  ) {
    updateCommunityRule(
      input: {
        community_rule_id: $community_rule_id
        community_slug: $community_slug
        title: $title
        description: $description
      }
    ) {
      code
      status
      message
      rule {
        id
        community_id
        title
        description
      }
    }
  }
`;
export const RE_ORDER_RULES = gql`
  mutation reOrderRules($input: [ReOrderRulesInput!]) {
    reOrderRules(input: $input) {
      code
      status
      message
    }
  }
`;
export const DELETE_COMMUNITY_RULE = gql`
  mutation deleteCommunityRule($community_rule_id: ID!) {
    deleteCommunityRule(input: { community_rule_id: $community_rule_id }) {
      code
      status
      message
    }
  }
`;

export const SELECT_USER_TOPICS = gql`
  mutation selectTopic($topic_ids: [ID!]!) {
    selectTopic(input: { topic_ids: $topic_ids }) {
      code
      status
      message
    }
  }
`;

export const SELECT_USER_COMMUNITIES = gql`
  mutation selectCommunity($community_slug: String!, $from: String) {
    selectCommunity(community_slug: $community_slug, from: $from) {
      code
      status
      message
      community {
        id
      }
    }
  }
`;

export const DELETE_COMMUNITY = gql`
  mutation deleteCommunity($slug: String!) {
    deleteCommunity(community_slug: $slug) {
      code
      status
      message
    }
  }
`;

export const INVITE_PEOPLE_TO_JOIN_COMMUNITY = gql`
  mutation invitePeopleToJoin(
    $slug: String!
    $input: InvitePeopleToJoinInput!
  ) {
    invitePeopleToJoin(community_slug: $slug, input: $input) {
      code
      status
      message
    }
  }
`;

export const DEACTIVATE_COMMUNITY = gql`
  mutation deactivateCommunity($slug: String!) {
    deactivateCommunity(community_slug: $slug) {
      code
      status
      message
    }
  }
`;

export const UPDATE_COMMUNITY = gql`
  mutation updateCommunity($slug: String!, $input: UpdateCommunityInput!) {
    updateCommunity(community_slug: $slug, input: $input) {
      code
      status
      message
      community {
        id
      }
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile($input: UserSettingsInput!) {
    userSettings(input: $input) {
      code
      status
      message
      user {
        id
      }
    }
  }
`;

export const DEACTIVATE_USER_ACCOUNT = gql`
  mutation {
    userAccountDeactivate {
      code
      status
      message
      user {
        id
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      code
      status
      message
    }
  }
`;

export const UPDATE_USER_NOTIFICATION_PERMISSIONS = gql`
  mutation setUserNotificationPermissions($input: [String!]) {
    setUserNotificationPermissions(input: $input) {
      code
      status
      message
    }
  }
`;

export const USER_ACCOUNT_DELETE = gql`
  mutation {
    userAccountDelete {
      code
      status
      message
      user {
        id
      }
    }
  }
`;
export const FOLLOW_USER = gql`
  mutation userFollow($user_slug: String!) {
    userFollow(user_slug: $user_slug) {
      code
      status
      message
    }
  }
`;

export const UNFOLLOW_USER = gql`
  mutation userUnfollow($user_id: ID!) {
    userUnfollow(user_id: $user_id) {
      code
      status
      message
    }
  }
`;

export const BLOCK_USER = gql`
  mutation blockUser($user_slug: String!) {
    blockUser(user_slug: $user_slug) {
      code
      status
      message
    }
  }
`;

export const UNBLOCK_USER = gql`
  mutation unblockUser($user_slug: String!) {
    unblockUser(user_slug: $user_slug) {
      code
      status
      message
    }
  }
`;
export const READ_NOTIFICATIONS = gql`
  mutation readNotification {
    readNotification {
      code
      status
      message
    }
  }
`;

export const COMMUNITY_MEMBER_ACCEPT_REJECT_REMOVE = gql`
  mutation communityMemberAcceptRejectRemove(
    $user_slug: String!
    $community_slug: String!
    $status: CommunityMemberAcceptRejectRemoveStatus!
  ) {
    communityMemberAcceptRejectRemove(
      user_slug: $user_slug
      community_slug: $community_slug
      status: $status
    ) {
      code
      status
      message
    }
  }
`;

export const BLOCK_COMMUNITY_MEMBER = gql`
  mutation requestToBlockUser($user_slug: String!) {
    requestToBlockUser(user_slug: $user_slug) {
      code
      status
      message
    }
  }
`;
export const LEAVE_COMMUNITY = gql`
  mutation leaveCommunity($community_slug: String!) {
    leaveCommunity(community_slug: $community_slug) {
      code
      status
      message
    }
  }
`;

export const POST_NOW = gql`
  mutation postNow($post_slug: String!) {
    postNow(post_slug: $post_slug) {
      code
      status
      message
    }
  }
`;
export const SAVE_POST = gql`
  mutation savePost($post_slug: String!) {
    savePost(post_slug: $post_slug) {
      code
      status
      message
    }
  }
`;
export const DELETE_POST = gql`
  mutation deletePost($post_slug: String!) {
    deletePost(post_slug: $post_slug) {
      code
      status
      message
    }
  }
`;
export const REMOVE_SAVED_POST = gql`
  mutation removeSavedPost($post_slug: String!) {
    removeSavedPost(post_slug: $post_slug) {
      code
      status
      message
    }
  }
`;
export const CREATE_POST_COMMENT = gql`
  mutation createComment(
    $post_slug: String!
    $parent_comment_id: Int
    $text: String!
  ) {
    createComment(
      input: {
        post_slug: $post_slug
        parent_comment_id: $parent_comment_id
        text: $text
      }
    ) {
      code
      status
      message
      comment {
        id
        user_id
        parent_id
        text
        deleted_at
        created_at
        replies_count
        upvotes_count
        downvotes_count
        replies {
          id
          user_id
          parent_id
          text
          created_at
          replies_count
        }
        user {
          id
          username
          slug
          photo
        }
      }
    }
  }
`;

export const UPDATE_COMMENT = gql`
  mutation updateComment($comment_id: Int, $text: String!) {
    updateComment(input: { comment_id: $comment_id, text: $text }) {
      code
      status
      message
      comment {
        id
        user_id
        parent_id
        text
        deleted_at
        created_at
        replies_count
        upvotes_count
        downvotes_count
        replies {
          id
          user_id
          parent_id
          text
          created_at
          replies_count
        }
        user {
          id
          username
          slug
          photo
        }
      }
    }
  }
`;

export const UPVOTE_DOWNVOTE_COMMENT = gql`
  mutation voteToComment($comment_id: Int, $vote: String!) {
    voteToComment(comment_id: $comment_id, vote: $vote) {
      code
      status
      message
    }
  }
`;

export const DELETE_POST_COMMENT = gql`
  mutation deleteComment($comment_id: Int!) {
    deleteComment(comment_id: $comment_id) {
      code
      status
      message
    }
  }
`;

export const CREATE_CURRENT_ISSUE_COMMENT = gql`
  mutation createCurrentIssueComment(
    $current_issue_slug: String!
    $parent_comment_id: Int
    $text: String!
  ) {
    createCurrentIssueComment(
      input: {
        current_issue_slug: $current_issue_slug
        parent_comment_id: $parent_comment_id
        text: $text
      }
    ) {
      code
      status
      message
      current_issue_comment {
        id
        user_id
        parent_id
        text
        deleted_at
        created_at
        replies_count
        replies {
          id
          user_id
          parent_id
          text
          created_at
          replies_count
        }
        user {
          id
          username
          slug
          photo
        }
      }
    }
  }
`;

export const UPVOTE_DOWNVOTE_CURRENT_ISSUE_COMMENT = gql`
  mutation voteToCurrentIssueComment(
    $current_issue_comment_id: Int
    $vote: String!
  ) {
    voteToCurrentIssueComment(
      current_issue_comment_id: $current_issue_comment_id
      vote: $vote
    ) {
      code
      status
      message
    }
  }
`;

export const REPORT_COMMENT = gql`
  mutation reportComment($comment_id: ID!, $description: String!) {
    reportComment(comment_id: $comment_id, description: $description) {
      code
      status
      message
    }
  }
`;

export const REPORT_POST = gql`
  mutation reportPost($post_slug: String!, $description: String!) {
    reportPost(post_slug: $post_slug, description: $description) {
      code
      status
      message
    }
  }
`;

export const REPORT_CURRENT_ISSUE_COMMENT = gql`
  mutation reportCurrentIssueComment(
    $current_issue_comment_id: ID!
    $description: String!
  ) {
    reportCurrentIssueComment(
      current_issue_comment_id: $current_issue_comment_id
      description: $description
    ) {
      code
      status
      message
    }
  }
`;

// export const DELETE_POST_CURRENT_ISSUE_COMMENT = gql`
//   mutation deleteComment($comment_id: Int!) {
//     deleteComment(comment_id: $comment_id) {
//       code
//       status
//       message
//     }
//   }
// `;

export const CREATE_NEWS_ORGANIZATION_COMMENT = gql`
  mutation createNewsOrganizationComment(
    $news_organization_slug: String!
    $parent_comment_id: Int
    $text: String!
  ) {
    createNewsOrganizationComment(
      input: {
        news_organization_slug: $news_organization_slug
        parent_comment_id: $parent_comment_id
        text: $text
      }
    ) {
      code
      status
      message
      news_organization_comment {
        id
        user_id
        parent_id
        text
        deleted_at
        created_at
        replies_count
        replies {
          id
          user_id
          parent_id
          text
          created_at
          replies_count
        }
        user {
          id
          username
          slug
          photo
        }
      }
    }
  }
`;

export const UPVOTE_DOWNVOTE_NEWS_ORGANIZATION_COMMENT = gql`
  mutation voteToNewsOrganizationComment(
    $news_organization_comment_id: Int
    $vote: String!
  ) {
    voteToNewsOrganizationComment(
      news_organization_comment_id: $news_organization_comment_id
      vote: $vote
    ) {
      code
      status
      message
    }
  }
`;
export const REPORT_NEWS_ORGANIZATION_COMMENT = gql`
  mutation reportNewsOrganizationComment(
    $news_organization_comment_id: ID!
    $description: String!
  ) {
    reportNewsOrganizationComment(
      news_organization_comment_id: $news_organization_comment_id
      description: $description
    ) {
      code
      status
      message
    }
  }
`;

export const APPLY_FOR_JOB = gql`
  mutation applyForJob(
    $applied_for: String!
    $name: String!
    $address: String!
    $street: String!
    $city: String!
    $phone: String!
    $email: String!
    $interest: String!
    $resume: String!
  ) {
    applyForJob(
      input: {
        applied_for: $applied_for
        name: $name
        address: $address
        street: $street
        city: $city
        phone: $phone
        email: $email
        interest: $interest
        resume: $resume
      }
    ) {
      code
      status
      message
    }
  }
`;

export const CONTACT_US = gql`
  mutation contactUs($name: String!, $email: String!, $message: String!) {
    contactUs(input: { name: $name, email: $email, message: $message }) {
      code
      status
      message
    }
  }
`;

export const REACTIVATE_ACCOUNT_REQUEST_TO_ADMIN = gql`
  mutation reactivateAccountRequestToAdmin($user_slug: String!) {
    reactivateAccountRequestToAdmin(user_slug: $user_slug) {
      code
      status
      message
    }
  }
`;