import { useQuery } from "@apollo/client";
import React from "react";
import { GET_SITE_SETTING_DETAILS } from "../../../queries/newsCosmosQueries";

const FooterSmall = () => {
  const { data } = useQuery(GET_SITE_SETTING_DETAILS);
  if (data && data.siteSetting) {
    return (
      <div className="login-footer">
        {data.siteSetting.site_setting.copy_right ?? ""}
      </div>
    );
  }
  return null;
};
export default FooterSmall;