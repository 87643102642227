import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import BarLoader from "react-spinners/BarLoader";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import Rating from "react-rating";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import { Redirect } from "react-router-dom";
import { UPDATE_POST_RATING } from "../../../mutations/newsCosmosMutations";
import StarOutline from "../../../public/images/star-outline.svg";
import StarFill from "../../../public/images/star-fill.svg";
import Plus from "../../../public/images/plus.svg";
import Close from "../../../public/images/close.svg";
import "./ratingbox.scss";
import { GET_POST_RATING_BY_USER } from "../../../queries/newsCosmosQueries";

const RatingBoxPostDetails = ({ values, postSlug }) => {
  const [show, setShow] = useState(false);
  const [redirectTo, setRedirectTo] = useState(false);
  const { loading, error, data } = useQuery(GET_POST_RATING_BY_USER, {
    variables: {
      post_slug: postSlug,
    },
  });
  const [updatePostRating, mutationData] = useMutation(UPDATE_POST_RATING);
  const formInitialValues = {
    quality: 0,
    facts: 0,
    objectivity: 0,
    accuracy: 0,
  };
  const formik = useFormik({
    initialValues: formInitialValues,
  });
  useEffect(() => {
    if (
      mutationData.data
      && mutationData.data.postRating.message === "Post not found!"
    ) {
      setShow(false);
      toast.error(mutationData.data.postRating.message);
      setRedirectTo(true);
    } else {
      if (mutationData.data && mutationData.data.postRating.status === "Fail") {
        toast.error(mutationData.data.postRating.message);
      }
      if (
        mutationData.data
        && mutationData.data.postRating.status === "Success"
      ) {
        toast.success(mutationData.data.postRating.message);
        setShow(false);
      }
    }
  }, [mutationData.data]);
  useEffect(() => {
    if (
      data
      && data.getPostRatingByUser
      && data.getPostRatingByUser.user_post_rate
    ) {
      formik.setFieldValue(
        "quality",
        data.getPostRatingByUser.user_post_rate.quality
          ? data.getPostRatingByUser.user_post_rate.quality
          : 0,
      );
      formik.setFieldValue(
        "facts",
        data.getPostRatingByUser.user_post_rate.facts
          ? data.getPostRatingByUser.user_post_rate.facts
          : 0,
      );
      formik.setFieldValue(
        "objectivity",
        data.getPostRatingByUser.user_post_rate.objectivity
          ? data.getPostRatingByUser.user_post_rate.objectivity
          : 0,
      );
      formik.setFieldValue(
        "accuracy",
        data.getPostRatingByUser.user_post_rate.accuracy
          ? data.getPostRatingByUser.user_post_rate.accuracy
          : 0,
      );
    }
  }, [data]);
  const handleClose = () => {
    formik.setFieldValue(
      "quality",
      data.getPostRatingByUser.user_post_rate.quality,
    );
    formik.setFieldValue(
      "facts",
      data.getPostRatingByUser.user_post_rate.facts,
    );
    formik.setFieldValue(
      "objectivity",
      data.getPostRatingByUser.user_post_rate.objectivity,
    );
    formik.setFieldValue(
      "accuracy",
      data.getPostRatingByUser.user_post_rate.accuracy,
    );
    setShow(false);
  };
  const handleShow = () => setShow(true);
  if (loading || error) {
    return (
      <div className="page-loader">
        <div>
          Loading...
          <BarLoader color="#ED6C27" width="150px" height="5px" />
        </div>
      </div>
    );
  }

  if (redirectTo) {
    return <Redirect to="/" />;
  }

  return (
    <div className="rating-box">
      <div className="start-preview">
        <ul>
          <li>
            Quality
            <div className="star-info">
              {formik.values.quality > 0 && (
                <span className="no">{formik.values.quality}</span>
              )}
              <span className="icon">
                {formik.values.quality > 0 ? <StarFill /> : <StarOutline />}
              </span>
            </div>
          </li>
          <li>
            Facts
            <div className="star-info">
              {formik.values.facts > 0 && (
                <span className="no">{formik.values.facts}</span>
              )}
              <span className="icon">
                {formik.values.facts > 0 ? <StarFill /> : <StarOutline />}
              </span>
            </div>
          </li>
          <li>
            Objectivity
            <div className="star-info">
              {formik.values.objectivity > 0 && (
                <span className="no">{formik.values.objectivity}</span>
              )}
              <span className="icon">
                {formik.values.objectivity > 0 ? <StarFill /> : <StarOutline />}
              </span>
            </div>
          </li>
          <li>
            Accuracy
            <div className="star-info">
              {formik.values.accuracy > 0 && (
                <span className="no">{formik.values.accuracy}</span>
              )}
              <span className="icon">
                {formik.values.accuracy > 0 ? <StarFill /> : <StarOutline />}
              </span>
            </div>
          </li>
        </ul>
      </div>
      {data
      && data.getPostRatingByUser
      && data.getPostRatingByUser.user_post_rate ? (
          !data.getPostRatingByUser.user_post_rate.edited && (
          <div className="add-rating">
            <button type="button" className="rating-btn" onClick={handleShow}>
              <Plus />
              {" "}
              Add rating
            </button>
          </div>
          )
        ) : (
          <div className="add-rating">
            <button type="button" className="rating-btn" onClick={handleShow}>
              <Plus />
              {" "}
              Add rating
            </button>
          </div>
        )}

      <Modal show={show} onHide={handleClose} centered className="rating-popup">
        <Button variant="modal-close" onClick={handleClose}>
          <Close />
        </Button>
        <Modal.Header>
          <Modal.Title>ADD RATING</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="rating-popup-body">
            <div className="rating-type-list">
              <ul>
                <li>
                  <div className="rating-label">Quality</div>
                  <div className="rating-star">
                    <Rating
                      className="rating"
                      initialRating={formik.values.quality}
                      stop={10}
                      emptySymbol={<StarOutline className="star-outline" />}
                      fullSymbol={<StarFill className="star-fill" />}
                      onClick={(value) => formik.setFieldValue("quality", value)}
                    />
                  </div>
                </li>
                <li>
                  <div className="rating-label">Facts</div>
                  <div className="rating-star">
                    <Rating
                      className="rating"
                      initialRating={formik.values.facts}
                      stop={10}
                      emptySymbol={<StarOutline className="star-outline" />}
                      fullSymbol={<StarFill className="star-fill" />}
                      onClick={(value) => formik.setFieldValue("facts", value)}
                    />
                  </div>
                </li>
                <li>
                  <div className="rating-label">Objectivity</div>
                  <div className="rating-star">
                    <Rating
                      className="rating"
                      initialRating={formik.values.objectivity}
                      stop={10}
                      emptySymbol={<StarOutline className="star-outline" />}
                      fullSymbol={<StarFill className="star-fill" />}
                      onClick={(value) => formik.setFieldValue("objectivity", value)}
                    />
                  </div>
                </li>
                <li>
                  <div className="rating-label">Accuracy</div>
                  <div className="rating-star">
                    <Rating
                      className="rating"
                      initialRating={formik.values.accuracy}
                      stop={10}
                      emptySymbol={<StarOutline className="star-outline" />}
                      fullSymbol={<StarFill className="star-fill" />}
                      onClick={(value) => formik.setFieldValue("accuracy", value)}
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div className="cta-group text-center">
              <Button
                variant="outline-primary"
                className="btn-bold"
                onClick={handleClose}
              >
                CANCEL
              </Button>
              {formik.values.quality > 0
              || formik.values.facts > 0
              || formik.values.objectivity > 0
              || formik.values.accuracy > 0 ? (
                <Button
                  variant="primary"
                  className="btn-bold"
                  disabled={mutationData.loading}
                  onClick={async () => {
                    await updatePostRating({
                      variables: {
                        post_slug: values.slug,
                        quality: formik.values.quality,
                        facts: formik.values.facts,
                        objectivity: formik.values.objectivity,
                        accuracy: formik.values.accuracy,
                        edited: data
                          ? !(
                            data.getPostRatingByUser
                              && data.getPostRatingByUser.user_post_rate.edited
                                === null
                          )
                          : false,
                      },
                      refetchQueries: [
                        {
                          query: GET_POST_RATING_BY_USER,
                          variables: {
                            post_slug: postSlug,
                          },
                        },
                      ],
                    });
                  }}
                >
                  SAVE
                  {mutationData.loading && (
                    <span className="ml-2">
                      <ClipLoader color="#ffffff" loading={true} size={15} />
                    </span>
                  )}
                </Button>
                ) : (
                  <Button variant="primary" className="btn-bold" disabled>
                    SAVE
                  </Button>
                )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RatingBoxPostDetails;