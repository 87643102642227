@import "../../../../public/style/variables";

.community-nearby-box{
    background: $color_white;
    padding: 12px 8px;
		margin-bottom: 8px;
    @include border-radius(5px);
    > h3{
        font-size: 20px;
        font-weight: 700;
        color: $heading_color;
        padding: 0 8px 10px;
    }
    .community-nearby-list{
        padding: 0 8px;
        > ul{
            > li{
                margin-bottom: 12px;
                display: flex;
                align-items: center;
                &:last-child{
                    margin-bottom: 0;
                }
                .community-info{
                    width: 100%;
                    padding-right: 10px;
                    display: flex;
                    align-items: center;
                    .photo{
                        flex-shrink: 0;
                        margin-right: 4px;
                        img{
                            max-width: 30px;
                            @include border-radius(100%);
                        }
                    }
                    .name-info{
                        .community-name{
                            font-size: 16px;
                            line-height: 18px;
                            font-weight: 600;
                            color: $heading_color;
                            a{
                                font-size: 16px;
                                line-height: 18px;
                                font-weight: 600;
                                color: $heading_color;
                            }
                        }
                        .community-members{
                            font-size: 14px;
                            line-height: 18px;
                            color: $gray_color;
                        }
                    }
                }
                .join-btn{
                    flex-shrink: 0;
                }
            }
        }
        .create-article-btn{
            padding-top: 14px;
            display: none;
        }
    }
}