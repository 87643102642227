@import "./variables";

.notifications-list{
	> ul{
		> li{
			padding: 16px;
			background: $color_white;
			border-bottom: 1px solid $color_fantasy;
			display: flex;
			flex-wrap: wrap;
			&:first-child{
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
			}
			&:last-child{
				border-bottom: 0;
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;
			}
			.icon{
				width: 26px;
				padding-top: 2px;
				svg{
					width: 16px;
					height: 16px;
					display: block;
				}
			}
			.notifications-info{
				width: calc(100% - 26px);
				.notification-info-txt{
					display: flex;
					white-space: nowrap;
					@include breakpoint( max , 991){
						flex-wrap: wrap;
						white-space: normal;
					}
					.name{
						padding-right: 5px;
						a{
							color: $primary_color;
							font-weight: 600;
							&.dropdown-item{
								background: transparent;
								padding: 0;
							}
						}
					}
					p{
						margin-bottom: 0;
						margin-right: 5px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
					.article-name{
						font-weight: 600;
						color: $color_gray_dark;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						@include breakpoint( max , 991){
							width: 100%;
						}
					}
				}
				.time{
					font-size: 12px;
				}
			}
			&.selected{
				background: $color_zumthor;
				border-color: $color_link_water;
			}
		}
	}
}