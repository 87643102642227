@import "../../../public/style/variables";

.ads-card{
    background: $color_white;
	@include border-radius(5px);
    margin-bottom: 8px;
    padding: 8px;
    text-align: center;
    .ads-card-box{
        display: inline-block;
        text-align: left;
    }
    .ads-title{
        font-size: 14px;
        text-transform: uppercase;
        line-height: 17px;
        color: $heading_color;
        padding-bottom: 8px;
    }
}