import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import ClipLoader from "react-spinners/ClipLoader";
import { GET_SEARCH_SUGGESTIONS } from "../../queries/newsCosmosQueries";

const SearchSuggestions = ({ searchTerm }) => {
  const { loading, error, data } = useQuery(GET_SEARCH_SUGGESTIONS, {
    variables: {
      query: searchTerm,
    },
    skip: searchTerm.length === 0,
  });
  if (loading || error) {
    return (
      <div className="search-dd">
        <div className="search-dd-list">
          <p className="text-center pt-2">
            Loading...
            <span className="ml-2">
              <ClipLoader color="#e76c35" loading={true} size={15} />
            </span>
          </p>
        </div>
      </div>
    );
  }

  if (searchTerm.length > 0) {
    return (
      <div className="search-dd">
        <div className="search-dd-list">
          {data && data.searchSuggestion && data.searchSuggestion.search_suggestions.length > 0 && (
            <ul>
              {data.searchSuggestion.search_suggestions.map((res) => {
                if (res.is_user) {
                  return (
                    <li key={Math.random()}>
                      <div className="avatar">
                        <img src={res.photo} alt="" />
                      </div>
                      <div className="info">
                        <div className="search__title"><Link to={`/user/${res.slug}/posts`}>{res.username}</Link></div>
                        <div className="search__sub-title">User</div>
                      </div>
                    </li>
                  );
                }
                if (res.is_post) {
                  return (
                    <li key={Math.random()}>
                      <div className="avatar">
                        <img src={res.image_url} alt="" />
                      </div>
                      <div className="info">
                        <div className="search__title"><Link to={`/post/${res.slug}/details`}>{res.title}</Link></div>
                        <div className="search__sub-title">Article</div>
                      </div>
                    </li>
                  );
                }
                if (res.is_community) {
                  return (
                    <li key={Math.random()}>
                      <div className="avatar">
                        <img src={res.photo} alt="" />
                      </div>
                      <div className="info">
                        <div className="search__title"><Link to={`/community/${res.slug}/posts`}>{res.name}</Link></div>
                        <div className="search__sub-title">Community</div>
                      </div>
                    </li>
                  );
                }
                if (res.is_news_organization) {
                  return (
                    <li key={Math.random()}>
                      <div className="avatar">
                        <img src={res.photo} alt="" />
                      </div>
                      <div className="info">
                        <div className="search__title"><Link to={`/news-organization/${res.slug}/posts`}>{res.title}</Link></div>
                        <div className="search__sub-title">News Organization</div>
                      </div>
                    </li>
                  );
                }
                if (res.is_current_issue) {
                  return (
                    <li key={Math.random()}>
                      <div className="avatar">
                        <img src={res.photo} alt="" />
                      </div>
                      <div className="info">
                        <div className="search__title"><Link to={`/current-issues/${res.slug}/details`}>{res.name}</Link></div>
                        <div className="search__sub-title">Current Issue</div>
                      </div>
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          )}
          {data
            && data.searchSuggestion
            && data.searchSuggestion.search_suggestions.length === 0 && (
            <p className="text-center pt-2">Sorry, the search you requested could not be found.</p>
          )}
        </div>
      </div>
    );
  }
  return null;
};

export default SearchSuggestions;