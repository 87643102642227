@import "../../../../public/style/variables";

.search-blog-box{
	background: $color_white;
	margin-bottom: 16px;
	@include border-radius(5px);
	h3{
		font-size: 20px;
		font-weight: 700;
		color: $heading_color;
		padding-bottom: 16px;
	}
	.search-area{
		padding: 18px 20px;
		border-bottom: 1px solid $color_mercury2;
		.search{
			position: relative;
			.form-group{
				position: relative;
				.form-control{
					padding-right: 45px;
				}
				.search-action{
					position: absolute;
					width: 34px;
					height: 40px;
					top: 0;
					right: 0;
					border: 0;
					background-color: transparent;
					svg{
						display: block;
					}
				}
			}
			.search-dd{
				position: absolute;
				top: 100%;
				left: 0;
				right: 0;
				background: $color_white;
				max-width: 576px;
				margin: 7px auto 0;
				border: 1px solid $body_bg_color;
				display: inline-block;
				@include box-shadow(0px 3px 6px rgba(13, 41, 52, .10));
				@include breakpoint-between( 768 , 991){
					margin-top: 0;
				}
				@include breakpoint( max , 767){
					margin-top: 0;
					width: 90%;
				}
				.search-dd-list{
					ul{
						padding: 8px 0;
						max-height: 300px;
						overflow: auto;
						li{
							padding: 8px 15px;
							display: flex;
							.avatar{
								flex-shrink: 0;
								width: 40px;
								margin-right: 10px;
								img{
									width: 40px;
									height: 40px;
									@include border-radius(100%);
								}
							}
							.info{
								.search__title{
									font-size: 16px;
									line-height: 19px;
									font-weight: 600;
									color: $label_color;
									a{
										display: inline-block;
										color: $label_color;
									}
								}
								.search__sub-title{
									font-size: 14px;
									color: $gray_color;
								}
							}
						}
					}
				}
			}
		}
	}
	.popular-posts{
		padding: 20px 20px;
		ul{
			li{
				display: flex;
				padding-bottom: 16px;
				border-bottom: 1px solid $color_mercury2;
				margin-bottom: 16px;
				&:last-child{
					border-bottom: 0;
					margin-bottom: 0;
					padding-bottom: 0;
				}
				.post-img{
					width: 78px;
					flex-shrink: 0;
					margin-right: 10px;
					img{
						@include border-radius(3px);
					}
				}
				.post-title{
					font-size: 16px;
					line-height: 20px;
					color: $color_gray_dark;
					font-weight: 500;
					a{
						color: $color_gray_dark;
						display: inline-block;
						@include transition(all 0.3s ease-in-out);
						&:hover{
							color: $primary_color;
						}
					}
				}
			}
		}
	}
}