@import "../variables";

.profile-setting-box{
	background: $color_white;
	padding: 24px;
	@include border-radius(5px);
	.nav{
		// max-width: 550px;
		margin-bottom: 25px;
	}
	.changes-password{
		padding-bottom: 25px;
	}
	.close-account{
		padding-bottom: 10px;
		.close-links{
			display: flex;
			.btn{
				padding: 0;
				font-size: 18px;
				font-weight: 600;
				color: $gray_color;
				display: flex;
				align-items: center;
				margin-right: 24px;
				@include breakpoint( max , 767){
					font-size: 14px;
					margin-right: 15px;
					&:last-child{
						margin-right: 0;
					}
				}
				&:hover{
					color: $color_error;
					svg{
						path{
							fill: $color_error
						}
					}
				}
				.icon{
					margin-right: 6px;
					svg{
						display: block;
					}
				}
			}
		}
	}
	.edit-icon{
		button{
			border: 0;
			background: $secondary_color;
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			@include border-radius(100%);
		}
	}
	.profile-photo-edit{
		position: relative;
		padding-bottom: 30px;
		.form-label{
			font-size: 18px;
			padding-bottom: 15px;
		}
		.img{
			position: relative;
			max-width: 100px;
			.edit-icon{
				position: absolute;
				bottom: 0;
				right: 0;
			}
			img{
				@include border-radius(100%);
			}
		}
    .photo-view{
      .box{
        width: 100px;
        height: 100px;
        img{
          width: 100px;
          height: 100px;
        }
      }
    }
	}
	.cover-photo-edit{
		position: relative;
		padding-bottom: 30px;
    &.show{
      padding-bottom: 0;
    }
		.form-label{
			font-size: 18px;
			padding-bottom: 15px;
		}
		.img{
			position: relative;
			.edit-icon{
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				z-index: 1;
				width: 30px;
				height: 30px;
			}
			img{
				@include border-radius(5px);
        height: 80px;
        width: 100%;
        object-fit: cover;
			}
			&:after{
				content: "";
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				@include border-radius(5px);
				background: $color_black;
				opacity: .3;
			}
		}
	}
}

.notification-setting-wrap{
	position: relative;
	.notification-setting-list{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		@include breakpoint( max , 767){
			align-items: flex-start;
		}
		.column-1{
			width: 50%;
			@include breakpoint( max , 767){
				width: calc(100% - 100px);
			}
		}
		.column-2{
			width: 110px;
			text-align: center;
			@include breakpoint( max , 767){
				width: 50px;
			}
		}
		.column-3{
			width: 110px;
			text-align: center;
			@include breakpoint( max , 767){
				width: 50px;
			}
		}
	}
	.notification-setting-header{
		font-size: 14px;
		text-transform: uppercase;
		border-bottom: 1px solid rgba($gray_color, .2);
		margin-bottom: 25px;
	}
	.notification-setting-body{
		.setting-item{
			padding-bottom: 16px;
		}
		.column-1{
			font-size: 16px;
			font-weight: 600;
			color: $color_gray_dark;
			@include breakpoint( max , 767){
				padding-right: 5px;
				font-size: 14px;
				line-height: 16px;
			}
		}
		.custom-control{
			display: inline-block;
			.custom-control-label{
				margin-top: -4px;
			}
		}
	}
}

.select-topic-list{
	> ul{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		@include breakpoint( max , 991){
			padding-bottom: 10px;
		}
		@include breakpoint( max , 767){
			margin: 0 -5px;
		}
		> li{
			padding: 0 16px;
			margin-bottom: 32px;
			@include breakpoint( max , 991){
				padding: 0 10px;
				margin-bottom: 20px;
			}
			@include breakpoint( max , 767){
				padding: 0 5px;
				margin-bottom: 10px;
			}
		}
	}
	.box{
		position: relative;
		height: 160px;
		width: 160px;
		overflow: hidden;
		@include border-radius(5px);
		cursor: pointer;
		@include breakpoint( max , 767){
			width: 140px;
			height: 140px;
		}
		.select-topic-input{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			z-index: 2;
			cursor: pointer;
			&:checked ~ .select-topic-box{
				.check{
					background: $secondary_color;
					&:before{
						opacity: 1;
						@include transform(scale(1));
					}
				}
			}
		}
		.select-topic-box{
			position: relative;
			width: 100%;
			height: 100%;
			padding: 5px 5px 26px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			@include breakpoint( max , 767){
				padding-bottom: 15px;
			}
			.check{
				width: 40px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: auto;
				position: relative;
				z-index: 1;
				background: $color_mercury2;
				@include border-radius(100%);
				@include transition(all 0.3s ease-in-out);
				@include breakpoint( max , 767){
					width: 20px;
					height: 20px;
				}
				&:before{
					content: "";
					display: block;
					width: 24px;
					height: 19px;
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='18.309' viewBox='0 0 24 18.309'%3E%3Cpath id='tick-icon' d='M22.069,44.48,7.622,58.926,1.931,53.235,0,55.166l7.622,7.622L24,46.411Z' transform='translate(0 -44.48)' fill='%23fff'/%3E%3C/svg%3E");
					background-repeat: no-repeat;
					opacity: 0;
					@include transform(scale(.8));
					@include transition(all 0.3s ease-in-out);
					@include breakpoint( max , 767){
						width: 14px;
						height: 9px;
						background-size: contain;
					}
				}
			}
			.img{
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				&:before{
					content: "";
					position: absolute;
					width: 100%;
					height: 100%;
					left: 0;
					top: 0;
					background: $color_black;
					opacity: .5;
				}
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
					@include border-radius(5px);
				}
			}
			p{
				position: relative;
				margin: 0;
				text-align: center;
				font-size: 20px;
				line-height: 24px;
				font-weight: 600;
				color: $color_white;
				@include breakpoint( max , 767){
					font-size: 16px;
					line-height: 1.3;
				}
			}
		}
	}
}