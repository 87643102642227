@import "./variables";

.main {
  padding: 40px 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  @include breakpoint(max, 767) {
    margin: 0;
  }
  &.three-column {
    .article-col {
      width: calc(50% - 162px);
      @include breakpoint(max, 1270) {
        width: 100%;
        padding: 0;
        display: none;
      }
    }
    .article-col-mobile {
      @include breakpoint(max, 1270) {
        width: calc(100% - 324px);
        display: block;
        padding: 0 12px;
      }
      @include breakpoint(max, 767) {
        width: 100%;
        display: block;
        padding: 0;
      }
      .mobile-filter-select {
        display: block;
      }
    }
    .signupcta-box {
      display: none;
    }
    .sidebar-col {
      .post-article-btn {
        display: block;
        margin-bottom: 8px;
        .btn {
          height: 41px;
        }
      }
    }
    .community-nearby-box {
      .community-nearby-list {
        .create-article-btn {
          display: block;
        }
      }
    }
  }
  .article-col {
    width: calc(100% - 324px);
    padding: 0 12px;
    @include breakpoint(max, 767) {
      width: 100%;
      padding: 0;
      display: none;
    }
    .column-title {
      padding-bottom: 10px;
      h2 {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        color: $color_gray_dark;
      }
    }
  }
  .article-col-mobile {
    @include breakpoint(min, 768) {
      display: none;
    }
    .article-filter {
      .topic-filter {
        .item {
          // display: none;
        }
      }
    }
  }
  .sidebar-col {
    width: 324px;
    padding: 0 12px;
    @include breakpoint(max, 767) {
      display: none;
    }
    .post-article-btn {
      display: none;
      margin-bottom: 8px;
      .btn {
        height: 41px;
      }
    }
  }
  &.two-column {
    .article-col-mobile {
      @include breakpoint(max, 767) {
        width: 100%;
      }
    }
  }
}

.container {
  &.after-login-container {
    @include breakpoint(max, 1270) {
      max-width: 930px;
    }
  }
}
