import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import ClipLoader from "react-spinners/ClipLoader";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { numFormatter } from "../../../../utils/helper";
import { GET_MY_COMMUNITIES } from "../../../../queries/newsCosmosQueries";
import { LEAVE_COMMUNITY } from "../../../../mutations/newsCosmosMutations";
import "../CommunitiesNearby/communitiesnearby.scss";

const MyCommunity = ({ match }) => {
  const [selectedId, setSelectedId] = useState("");
  const [leaveCommunity, mutationData] = useMutation(LEAVE_COMMUNITY);

  useEffect(() => {
    if (
      mutationData.data
      && mutationData.data.leaveCommunity.status === "Fail"
    ) {
      toast.error(mutationData.data.leaveCommunity.message);
      setSelectedId("");
    }
    if (
      mutationData.data
      && mutationData.data.leaveCommunity.status === "Success"
    ) {
      toast.success(mutationData.data.leaveCommunity.message);
      setSelectedId("");
    }
  }, [mutationData.data]);

  const {
    loading, error, data, refetch,
  } = useQuery(GET_MY_COMMUNITIES, {
    variables: {
      community_type: null,
      limit: 5,
      current_page: 1,
    },
  });

  if (loading || error) {
    return (
      <div className="community-nearby-box">
        <h3>Joined Communities</h3>
        <div className="community-nearby-list">
          <p>
            Loading...
            <span className="ml-2">
              <ClipLoader color="#e76c35" loading={true} size={15} />
            </span>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="community-nearby-box">
      <h3>Joined Communities</h3>
      <div className="community-nearby-list">
        {data
          && data.getMyCommunities
          && data.getMyCommunities.my_communities.length > 0 && (
            <ul>
              {data
                && data.getMyCommunities.my_communities.map((community) => (
                  <li key={Math.random()}>
                    <div className="community-info">
                      <div className="photo">
                        <img src={community.photo} alt="" />
                      </div>
                      <div className="name-info">
                        <div className="community-name">
                          <Link to={`/community/${community.slug}/posts`}>
                            {community.name}
                          </Link>
                        </div>
                        <div className="community-members">
                          {numFormatter(community.community_members_count) ?? 0}
                          {" "}
                          members
                        </div>
                      </div>
                    </div>
                    <div className="join-btn">
                      <Button
                        variant="outline-primary"
                        className="btn-bold"
                        title="Leave"
                        disabled={
                          selectedId === community.id && mutationData.loading
                        }
                        onClick={async () => {
                          swal({
                            title: "Are you sure want to leave the community?",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                          }).then(async (willDelete) => {
                            if (willDelete) {
                              setSelectedId(community.id);
                              await leaveCommunity({
                                variables: { community_slug: community.slug },
                              });
                              refetch();
                            }
                          });
                        }}
                      >
                        Leave
                        {selectedId === community.id && mutationData.loading && (
                          <span className="ml-2">
                            <ClipLoader
                              color="#228CB7"
                              loading={true}
                              size={15}
                            />
                          </span>
                        )}
                      </Button>
                    </div>
                  </li>
                ))}
            </ul>
        )}
        {data
          && data.getMyCommunities
          && data.getMyCommunities.my_communities.length === 0 && (
            <p>Nothing to see here — yet</p>
        )}
        {data
          && data.getMyCommunities
          && data.getMyCommunities.my_communities.length > 0
          && data.getMyCommunities.next_page !== 0 && (
            <div className="create-article-btn d-block">
              <Link
                to={`/user/${match.params.slug}/joined-communities`}
                className="btn-bold btn-primary btn btn-block"
              >
                VIEW ALL Communities
              </Link>
            </div>
        )}
      </div>
    </div>
  );
};

export default MyCommunity;