import React, { useState, useEffect } from "react";
import Collapse from "react-bootstrap/Collapse";
import qs from "query-string";
import { withRouter } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import SearchSuggestions from "./SearchSuggestions";
import SearchIcon from "../../public/images/magnifying-glass.svg";

const SearchBox = ({ history }) => {
  const [open, setOpen] = useState(false);
  const params = qs.parse(history.location.search);
  const [searchTerm, setSearchTerm] = useState(
    params.query ? params.query : ""
  );
  useEffect(() => {
    if (history.location.pathname.indexOf("search-results") === -1) {
      setSearchTerm("");
    }
  }, [history.location]);
  const handleSubmit = (e) => {
    e.preventDefault();
    history.push({
      pathname: "/search-results/articles",
      search: `?query=${searchTerm}`,
    });
  };
  return (
    <div className="header-center">
      <div className="header-search">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="collapse-search"
          aria-expanded={open}
          variant="search"
        >
          <SearchIcon />
        </Button>
        <div className="search-area">
          <Collapse in={open}>
            <div id="collapse-search">
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <Form.Group>
                  <div className="icon">
                    <SearchIcon />
                  </div>
                  <DebounceInput
                    minLength={2}
                    debounceTimeout={10}
                    placeholder="Search"
                    className="form-control"
                    onChange={(event) => setSearchTerm(event.target.value)}
                    value={searchTerm}
                  />
                </Form.Group>
              </Form>
            </div>
          </Collapse>
        </div>
        {(!params.query || params.query !== searchTerm) && (
          <SearchSuggestions searchTerm={searchTerm} />
        )}
      </div>
    </div>
  );
};

export default withRouter(SearchBox);
