@import "../../../../public/style/variables";

.about-article-info-box {
  margin-bottom: 8px;
  background: $color_white;
  @include border-radius(5px);

  .cover-photo {
    position: relative;

    img {
      width: 100%;
      max-height: 90px;
      object-fit: cover;
      @include border-radius(5px 5px 0 0);
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $color_white;
      opacity: 0.76;
      @include border-radius(5px 5px 0 0);
    }
  }

  .article-user-info {
    padding: 0 16px 16px;
    margin-top: -34px;
    position: relative;

    .photo-name {
      display: flex;
      align-items: center;
      padding-bottom: 12px;

      .photo {
        flex-shrink: 0;
        width: 50px;
        margin-right: 8px;

        img {
          max-width: 50px;
          @include border-radius(100%);
        }
      }

      .name {
        font-size: 16px;
        line-height: 28px;
        font-weight: 600;
        color: $color_gray_dark;
      }
    }

    .small-txt {
      padding-bottom: 6px;
    }

    .member-type {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-bottom: 16px;

      .member {
        width: 50%;
        font-size: 12px;
        line-height: 1.1;

        span {
          display: block;
          font-size: 16px;
          color: $color_gray_dark;
        }
      }

      .community-type {
        width: 50%;
        display: flex;

        .short-type {
          margin-right: 8px;
          flex-shrink: 0;
        }

        .type {
          font-size: 12px;
          line-height: 1.1;

          span {
            display: block;
            font-size: 16px;
            color: $color_gray_dark;
            text-transform: capitalize;
          }
        }
      }
    }

    .create-date {
      line-height: 16px;
      display: flex;
      align-items: center;
      padding-bottom: 16px;

      span {
        display: block;
        vertical-align: middle;
        margin-right: 8px;

        svg {
          display: block;
        }
      }
    }
  }
}
