import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { ClipLoader } from "react-spinners";
import { GET_USER_FOLLOWERS } from "../../../../queries/newsCosmosQueries";
import "../CommunitiesNearby/communitiesnearby.scss";
import { useAuth } from "../../../../utils/useAuth";

const FollowersBox = ({ match }) => {
  const auth = useAuth();
  const { loading, error, data } = useQuery(GET_USER_FOLLOWERS, {
    variables: {
      user_slug: match.params.slug,
      limit: 5,
      current_page: 1,
    },
  });
  if (loading || error) {
    return (
      <div className="community-nearby-box">
        <h3>Followers</h3>
        <div className="community-nearby-list">
          <p>
            Loading...
            <span className="ml-2">
              <ClipLoader color="#e76c35" loading={true} size={15} />
            </span>
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className="community-nearby-box">
      <h3>Followers</h3>
      <div className="community-nearby-list">
        {data && data.getUserFollowers.followers.length > 0 && (
          <ul>
            {data.getUserFollowers.followers.map((user) => (
              <li key={Math.random()}>
                <div className="community-info">
                  <div className="photo">
                    <img src={user.follower.photo} alt="" />
                  </div>
                  <div className="name-info">
                    <div className="community-name">
                      <Link to={`/user/${user.follower.slug}/posts`}>
                        {user.follower.username}
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
        {data
          && data.getUserFollowers
          && data.getUserFollowers.followers.length === 0 && (
            <p>Nothing to see here — yet</p>
        )}
        {data
          && data.getUserFollowers
          && data.getUserFollowers.next_page !== 0 && (
            <div className="create-article-btn d-block">
              <Link
                to={`/user/${auth.user.slug}/followers`}
                className="btn-bold btn-primary btn btn-block"
              >
                VIEW ALL FOLLOWERS
              </Link>
            </div>
        )}
      </div>
    </div>
  );
};

export default FollowersBox;