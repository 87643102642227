import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import moment from "moment";
import className from "classnames";
import EditIcon from "../../../public/images/edit.svg";
import MenuIcon from "../../../public/images/discussion-icon.svg";
import GlobeIcon from "../../../public/images/globe.svg";
import MailIcon from "../../../public/images/mail.svg";
import LocationIcon from "../../../public/images/location.svg";
import "../ProfileHeader/_profileheader.scss";

const OrganizationHeader = ({ match, details }) => {
  const [open, setOpen] = useState(false);
  const badgeClass = !details.news_organization.community
    && className("article-rate-badge large-badge", {
      "article-rate-badge-good": details.news_organization.average_rating >= 7,
      "article-rate-badge-average":
        details.news_organization.average_rating >= 4
        && details.news_organization.average_rating < 7,
      "article-rate-badge-bad":
        details.news_organization.average_rating > 0
        && details.news_organization.average_rating < 4,
      "article-rate-badge": details.news_organization.average_rating === 0,
    });
  return (
    <div className="myprofile-header-wrap org-header-wrap">
      <div className="container container-sm">
        <div className="myprofile-intro-nav">
          <div className="myprofile-intro">
            <div className="profile-photo">
              <img
                src={
                  details
                  && details.news_organization
                  && details.news_organization.photo
                }
                alt=""
              />
            </div>
            <div className="profile-info">
              <div className="profile-meta">
                <div className="left">
                  <h3>
                    {details
                      && details.news_organization
                      && details.news_organization.title}
                  </h3>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="create-date">
                      <span className="icon">
                        <EditIcon />
                      </span>
                      {" "}
                      Created
                      {" "}
                      {details.news_organization
                        && moment(details.news_organization.created_at).format(
                          "DD MMM YYYY",
                        )}
                    </div>
                  </div>
                </div>
                <div className="right">
                  <span className={badgeClass}>
                    {details.news_organization.average_rating > 0
                      ? details.news_organization.average_rating
                      : "?"}
                  </span>
                </div>
              </div>
              <div className="txt">
                {details
                  && details.news_organization
                  && details.news_organization.description}
              </div>
              0
              {" "}
              <div className="org-personal-info">
                <ul>
                  <li>
                    <div className="icon">
                      <GlobeIcon />
                    </div>
                    <a
                      href={
                        details.news_organization
                        && details.news_organization.website
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {details.news_organization
                        && details.news_organization.website}
                    </a>
                  </li>
                  <li>
                    <div className="icon">
                      <MailIcon />
                    </div>
                    <a
                      href={`mailto:${
                        details
                        && details.news_organization
                        && details.news_organization.email
                      }`}
                    >
                      {details
                        && details.news_organization
                        && details.news_organization.email}
                    </a>
                  </li>
                  <li>
                    <div className="icon">
                      <LocationIcon />
                    </div>
                    {details
                      && details.news_organization
                      && details.news_organization.location}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="myprofile-nav">
            <Button
              onClick={() => setOpen(!open)}
              aria-expanded={open}
              variant="nav"
            >
              <MenuIcon />
              {" "}
              Organisations Menu
            </Button>
            <Collapse in={open}>
              <div className="nav-list">
                <ul>
                  <li>
                    <NavLink
                      className="nav-link"
                      exact
                      to={`/news-organization/${match.params.slug}/posts`}
                    >
                      Posts
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="nav-link"
                      exact
                      to={`/news-organization/${match.params.slug}/discussion`}
                    >
                      Discussion
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="nav-link"
                      exact
                      to={`/news-organization/${match.params.slug}/profile`}
                    >
                      Profile
                    </NavLink>
                  </li>
                </ul>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationHeader;