@import "../../../../public/style/variables";

.community-rules-box{
	background: $color_white;
  padding: 12px 8px;
	margin-bottom: 8px;
	@include border-radius(5px);
	h3{
		font-size: 20px;
		font-weight: 700;
		color: $color_gray_dark;
		padding: 0 7px 10px;
	}
}

.rules-accordion{
  .no-data{
		padding: 0 8px 10px;
	}
	.accordion{
		> .card{
			border-radius: 0;
			border: 0;
			border-bottom: 1px solid rgba($gray_color, .2) !important;
			margin-bottom: 12px;
			padding-bottom: 12px;
			&:last-child{
				border-bottom: 0 !important;
				margin-bottom: 0;
				padding-bottom: 0;
			}
			&.active-card{
				.card-header{
					&:after{
						@include transform(rotate(180deg));
					}
				}		
			}
		}
		.card-header{
			border: 0;
			position: relative;
			cursor: pointer;
			background: transparent;
			padding: 0 16px 0 0;
			display: flex;
			font-size: 16px;
			line-height: 20px;
			font-weight: 600;
			color: $color_gray_dark;
			&:after{
				content: "";
				width: 12px;
				height: 8px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.092' height='5' viewBox='0 0 8.092 5'%3E%3Cpath id='down-arrow-blue' d='M49.861,0l-.954.954L52,4.046,48.907,7.138l.954.954,4.046-4.046Z' transform='translate(8.092 -48.907) rotate(90)' fill='%23228cb7'/%3E%3C/svg%3E%0A");
        background-size: cover;
        background-repeat: no-repeat;
				position: absolute;
				right: 0;
				top: 5px;
				background-size: 100%;
				@include transition(all 0.3s ease-in-out);
			}
			.no{
				flex-shrink: 0;
				min-width: 20px;
			}
		}
		.card-body{
			padding: 7px 16px 0 20px;
			color: $color_dove_gray;
			line-height: 16px;
		}
	}
}