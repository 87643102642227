@import "./variables";

.form-wrap{
	.form-label{
		display: block;
		margin-bottom: 3px;
		font-size: 14px;
		line-height: 20px;
		font-weight: 600;
		color: $label_color;
		.info-tooltip{
			display: inline-block;
			margin-left: 5px;
			svg{
				display: block;
			}
		}
	}
	.form-control{
		height: 40px;
		box-shadow: none;
		font-size: 16px;
		border-color: $color_mercury2;
		color: rgba($label_color, 1);
		@include border-radius(5px);
		@include transition(all 0.3s ease-in-out);
		&:focus{
			border-color: rgba($gray_color, .7);
			box-shadow: none;
		}
	}
	.validation-error-msg{
    color: $color_red;
  }
	.forgot-link{
		font-size: 14px;
		a{
			color: $primary_color;
		}
	}
	textarea{
		&.form-control{
			height: 109px;
		}
	}
	.textarea-field{
		position: relative;
		.form-label{
			background: $color_white;
			margin: 1px 0 0 1px;
		}
		.textarea-length{
			position: absolute;
			right: 10px;
			bottom: 2px;
			font-size: 11px;
			font-weight: 600;
		}
	}
	.form-text{
		font-size: 13px;
		margin-top: 3px;
	}
	.password-group{
		position: relative;
		.password-show{
			position: absolute;
			width: 16px;
			height: 16px;
			right: 10px;
			top: 12px;
			svg{
				display: block;
			}
		}
	}
	.floating-form{
		position: relative;
		margin-bottom: 8px;
		.form-label{
			font-size: 13px;
			line-height: 16px;
			font-weight: 400;
			color: $gray_color;
			padding: 4px 10px 0;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			@include border-radius(5px);
		}
		.form-control{
			height: 50px;
			padding: 24px 10px 8px;
			color: $label_color;
			@include border-radius(5px);
			&:disabled{
				background: $color_mercury2;
				color: rgba($label_color, .5);
			}
		}
		textarea{
			&.form-control{
				height: 109px;
			}
		}
		.selectpicker{
			.select__control{
				height: 50px;
				@include border-radius(5px);
			}
			.select__value-container{
				padding: 0 10px;
			}
		}
		&.tags-box{
			.tags-input{
				.tagify{
					min-height: 86px;
					padding-top: 20px;
					@include border-radius(5px);
				}
			}
		}
	}
	.repeat-box{
		display: flex;
		.repeat-left{
			width: 100%;
		}
		.repeat-right{
			flex-shrink: 0;
			padding-bottom: 1rem;
			padding-left: 8px;
      width: 22px;
      padding-top: 23px;
			.btn{
				&.repeat-close{
					padding: 0;
					border: 0;
					height: 40px;
					display: flex;
					align-items: center;
					svg{
						display: block;
					}
				}
			}
		}
	}
	.add-more-btn{
		.more-btn{
			background: transparent;
			border: 0;
			color: $secondary_color;
			font-size: 14px;
			font-weight: 600;
			display: flex;
			align-items: center;
			svg{
				display: block;
				margin-right: 5px;
				path{
					fill: $secondary_color;
				}
			}
		}
	}
}

// Custom Checkbox and Radio
.custom-control{
	padding-left: 24px;
	.custom-control-input{
		&:not(:disabled):active ~ .custom-control-label{
			&:before{
				box-shadow: none;
				background: $color_white;
				border-color: $secondary_color;
			}
		}
		&:focus ~ .custom-control-label{
			&:before{
				box-shadow: none;
				background: $color_white;
				border-color: $secondary_color;
			}
		}
		&:checked ~ .custom-control-label{
			&:before{
				box-shadow: none;
				background: $color_white;
				border-color: $secondary_color;
			}
		}
	}
	.custom-control-label{
		cursor: pointer;
		&:before{
			width: 18px;
			height: 18px;
			left: -24px;
			border-color: $secondary_color;
		}
		&:after{
			width: 18px;
			height: 18px;
			left: -24px;
		}
	}
	&.custom-radio{
		.custom-control-input{
			&:focus ~ .custom-control-label{
				&:before{
					box-shadow: none;
					border-color: $secondary_color;
				}
				&:after{
					opacity: 1;
					background: $secondary_color;
				}
			}
			&:checked ~ .custom-control-label{
				&:before{
					box-shadow: none;
					border-color: $secondary_color;
				}
				&:after{
					opacity: 1;
					background: $secondary_color;
				}
			}
		}
		.custom-control-label{
			&:after{
				opacity: 0;
				width: 16px;
				height: 16px;
				margin: 1px;
				background: $secondary_color;
				border: 3px solid $color_white;
				@include border-radius(100%);
			}
		}
	}
	&.custom-switch{
		padding-left: 50px;
		.custom-control-input{
			width: 47px;
			height: 24px;
			top: 4px;
			&:focus ~ .custom-control-label{
				&:before{
					box-shadow: none;
					border-color: $gray_color;
					background: $gray_color;
				}
				&:after{
					left: -47px;
					background: $color_white;
				}
			}
			&:checked ~ .custom-control-label{
				&:before{
					box-shadow: none;
					border-color: $secondary_color;
					background: $secondary_color;
				}
				&:after{
					left: -36px;
					background: $color_white;
				}
				&:focus{
					&:before{
						box-shadow: none;
						border-color: $secondary_color;
						background: $secondary_color;
					}
					&:after{
						left: -36px;
						background: $color_white;
					}
				}
			}
		}
		.custom-control-label{
			&:before{
				width: 47px;
				height: 24px;
				left: -50px;
				border-color: $gray_color;
				background: $gray_color;
				@include border-radius(20px);
			}
			&:after{
				left: -47px;
				width: 18px;
				height: 18px;
				top: calc(0.25rem + 3px);
				background: $color_white;
				@include border-radius(20px);
				@include transition(all 0.3s ease-in-out);
			}
		}
	}
}

.community-type-check-list{
	padding-bottom: 8px;
	ul{
		padding-top: 2px;
		li{
			position: relative;
			display: flex;
			align-items: center;
			margin-bottom: 18px;
			&:last-child{
				margin-bottom: 8px;
			}
			.check{
				flex-shrink: 0;
			}
			.community-type{
				display: flex;
				align-items: center;
				.short-type{
					flex-shrink: 0;
					margin-right: 8px;
				}
				.type{
					span{
						font-size: 16px;
						font-weight: 600;
						color: $color_gray_dark;
						display: inline-block;
						margin-right: 4px;
						@include breakpoint( max , 600){
							display: block;
						}
					}
				}
			}
		}
	}
}

.profile-photo{
	padding-bottom: 1px;
	.photo-preview{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding-top: 5px;
		.upload-photo{
			padding: 0 10px 8px 0;
			> div{
				> div{
					overflow: hidden;
					@include border-radius(5px !important);
					border: 1px dashed $color_mercury2 !important;
					label{
						font-size: 14px !important;
						font-weight: 400 !important;
						color: $primary_color !important;
						font-family: $primary_font !important;
						margin: 0;
					}
				}
			}
		}
		.photo-view{
			padding-bottom: 8px;
			.box{
				@include border-radius(100%);
				img{
					@include border-radius(100%);
					border: 1px dashed $color_mercury2;
				}
			}
		}
	}
	.validation-error-msg{
		display: block;
		margin-bottom: 8px;
	}
}

.file-upload-box{
	margin-bottom: 8px;
	.filepond--wrapper{
		.filepond--credits{
			display: none;
		}
		.filepond--root{
			font-family: $primary_font;
			font-weight: 400;
			margin-bottom: 0px;
			.filepond--drop-label{
				min-height: 6.75em;
			}
		}
		.filepond--drip{
			background: $color_white;
			opacity: 1;
			border: 1px dashed $color_mercury2;
			@include border-radius(5px);
		}
		.filepond--drop-label{
			color: $gray_color;
		}
		.filepond--image-preview{
			background: $header_bg_color;
		}
		.upload-txt{
			font-size: 14px;
			padding-bottom: 6px;
			span{
				color: $primary_color;
				text-decoration: none;
			}
		}
		.filepond-image-note{
			font-size: 12px;
			span{
				display: block;
			}
		}
	}
}

.selectpicker {
	&.select--is-disabled{
		.select__control{
			background: $color_mercury2;
			.select__single-value{
				color: rgba($label_color, .5);
			}
		}
	}
	.select__menu{
		z-index: 12;
	}
	.select__menu-list {
		max-height: 220px;
	}
	.select__control {
		height: 40px;
		@include border-radius(5px);
		background: $color_white;
		border: 1px solid $color_mercury2;
		box-shadow: none;
		&:hover{
			border-color: $color_mercury2;
		}
	}
	.select__value-container {
		color: $color_gray_dark;
		padding: 0 0.75rem;
		.select__placeholder {
			font-size: 13px;
			color: $gray_color;
		}
		.select__single-value {
			color: $color_gray_dark;
			font-size: 16px;
		}
	}
	.select__indicator-separator {
		display: none;
	}
	.select__option {
		font-size: 15px;
		padding: 8px 17px;
		color: $gray_color;
		@include transition(all 0.3s ease-in-out);
		&.select__option--is-selected {
			background: rgba($primary_color, 1);
			color: $color_white;
			&.select__option--is-focused {
				background: rgba($primary_color, 1);
			}
			&:active{
				background: rgba($primary_color, 1);
			}
		}
		&.select__option--is-focused {
			background: rgba($primary_color, 0.05);
			&:active{
				background: rgba($primary_color, 0.05);
			}
		}
		&:active{
			background: transparent;
		}
	}
}

.tags-box{
	.tags-input{
		.tagify{
			border-color: $color_mercury2;
			min-height: 40px;
			padding-top: 2px;
			@include border-radius(5px);
		}
	}
}

.autocomplete-dropdown-container{
	.suggestion-item{
		padding: 8px 15px;
	}
}

.post-photo-result{
	margin: 10px 0;
	max-width: 160px;
	position: relative;
	img{
		width: 100%;
		height: auto;
	}
	span{
		display: block;
	}
	.remove-file{
		width: 20px;
		height: 20px;
		padding: 5px;
		position: absolute;
		right: -10px;
		top: -5px;
		@include border-radius(100%);
		@include box-shadow(0px 0px 3px rgba(0,0,0,0.7));
		display: flex;
		align-items: center;
		justify-content: center;
		background: $color_white;
		svg{
			path{
				fill: red
			}
		}
	}
}