@import "../../../../public/style/variables";

.sidebar-ads{
    margin: 0px 0 8px;
    background: $color_white;
    @include border-radius(5px);
    .ads-title{
        font-size: 14px;
        text-transform: uppercase;
        line-height: 17px;
        color: $heading_color;
        padding: 9px 16px 7px;
    }
    .ads-body-area{
        padding: 0px;
        background: $color_mercury2;
        min-height: 250px;
				overflow: hidden;
        @include border-radius( 0 0 5px 5px);
    }
}