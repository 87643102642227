import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import className from "classnames";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import moment from "moment";
import { useAuth } from "../../../utils/useAuth";
import { numFormatter } from "../../../utils/helper";
import EditIcon from "../../../public/images/edit.svg";
import MenuIcon from "../../../public/images/discussion-icon.svg";
import GlobeIcon from "../../../public/images/globe.svg";
import MailIcon from "../../../public/images/mail.svg";
import LocationIcon from "../../../public/images/location.svg";
import "../ProfileHeader/_profileheader.scss";

const ProfileHeader = ({ community, history }) => {
  const [open, setOpen] = useState(false);
  const auth = useAuth();
  const badgeClass = className("short-type community-badge", {
    "community-badge-public": community.type === "public",
    "community-badge-private": community.type === "private",
    "community-badge-restricted": community.type === "restricted",
  });
  if (auth.user.id !== community.owner.id) {
    history.push("/");
    return null;
  }
  return (
    <div className="myprofile-header-wrap">
      <div className="profile-coverphoto">
        <img src={community.cover_photo} alt="" />
      </div>
      <div className="container container-sm">
        <div className="myprofile-intro-nav">
          <div className="myprofile-intro">
            <div className="profile-photo">
              <img src={community.photo} alt="" />
            </div>
            <div className="profile-info">
              <div className="profile-meta">
                <div className="left">
                  <h3>{community.name}</h3>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="followers">
                      <span>
                        {numFormatter(community.community_members_count) ?? 0}
                      </span>
                      {" "}
                      Members
                    </div>
                    <div className="create-date">
                      <span className="icon">
                        <EditIcon />
                      </span>
                      {" "}
                      Created
                      {" "}
                      {moment(community.created_at).format("DD MMM YYYY")}
                      {" "}
                      by
                      {" "}
                      {community.owner.username}
                    </div>
                  </div>
                </div>
                <div className="right">
                  <span className={badgeClass}>
                    {community.type.slice(0, 2)}
                  </span>
                  <Link
                    to={`/user/community/${community.slug}/settings`}
                    className="btn btn-primary btn-bold"
                  >
                    EDIT
                  </Link>
                </div>
              </div>
              <div className="txt">{community.description}</div>
              <div className="org-personal-info">
                <ul>
                  {community.website && (
                    <li>
                      <div className="icon">
                        <GlobeIcon />
                      </div>
                      <a
                        href={community.website}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {community.website}
                      </a>
                    </li>
                  )}
                  {auth.user.email && (
                    <li>
                      <div className="icon">
                        <MailIcon />
                      </div>
                      <a href={`mailto:${auth.user.email}`}>
                        {auth.user.email}
                      </a>
                    </li>
                  )}
                  <li>
                    <div className="icon">
                      <LocationIcon />
                    </div>
                    {community.location}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="myprofile-nav">
            <Button
              onClick={() => setOpen(!open)}
              aria-expanded={open}
              variant="nav"
            >
              <MenuIcon />
              {" "}
              Menu
            </Button>
            <Collapse in={open}>
              <div className="nav-list">
                <ul>
                  <li>
                    <NavLink
                      className="nav-link"
                      exact
                      to={`/user/community/${community.slug}/posts`}
                    >
                      Posts
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="nav-link"
                      exact
                      to={`/user/community/${community.slug}/scheduled-posts`}
                    >
                      Schedule Post
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="nav-link"
                      exact
                      to={`/user/community/${community.slug}/members`}
                    >
                      Members
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="nav-link"
                      exact
                      to={`/user/community/${community.slug}/rules`}
                    >
                      Rules
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="nav-link"
                      exact
                      to={`/user/community/${community.slug}/settings`}
                    >
                      Settings
                    </NavLink>
                  </li>
                </ul>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;