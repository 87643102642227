import React from "react";
import { Link } from "react-router-dom";
import "./_cms-fooer.scss";

const CmsFooter = () => (
  <div className="cms-fooer">
    <div className="container container-sm">
      <div className="cms-quick-links">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <ul>
              <li>
                <Link to="/about-us">About</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/career">Career</Link>
              </li>
              <li>
                <Link to="/best-practices">Best Practices</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li>
                <Link to="/terms-of-use">Terms of Use</Link>
              </li>
              <li>
                <Link to="/blogs">Blog</Link>
              </li>
              <li>
                <Link to="/cookie-policy">Cookie Policy</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="copyright">
        News Cosmos, LLC © 2021. All rights reserved
      </div>
    </div>
  </div>
);
export default CmsFooter;