@import "./variables";

.article-filter {
  background: $color_white;
  padding: 6px 9px;
  @include border-radius(3px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  .topic-filter {
    display: flex;
    align-items: center;
    .item {
      padding: 0 8px;
      font-size: 16px;
      line-height: 19px;
      color: $gray_color;
      border-right: 1px solid $gray_color;
      &:last-child {
        border-right: 0;
      }
      &.active {
        font-weight: 600;
        color: $primary_color;
      }
    }
    .mobile-filter-select {
      padding-left: 10px;
      display: none;
      @include breakpoint(max, 767) {
        display: block;
      }
      .link-select {
        .select__value-container {
          .select__single-value {
            font-size: 16px;
            font-weight: 600;
            color: $primary_color;
            white-space: nowrap;
            display: block;
          }
        }
        .select__indicators {
          .select__indicator {
            color: $primary_color;
          }
        }
        .select__menu {
          min-width: 150px;
          left: 0;
        }
      }
    }
  }
  .relevance-filte-box {
    display: flex;
    align-items: center;
    background: $body_bg_color;
    padding: 4px 16px;
    @include border-radius(16px);
    @include breakpoint(max, 767) {
      padding-right: 10px;
    }
    > svg {
      margin-right: 20px;
      @include breakpoint(max, 767) {
        margin-right: 5px;
      }
    }
  }
  .link-select {
    .select__control {
      border: 0;
      background: transparent;
      padding: 0;
      border-radius: 0;
      min-height: 10px;
      outline: 0;
      box-shadow: none;
    }
    .select__value-container {
      padding: 0;
      flex-shrink: 0;
      .select__single-value {
        position: relative;
        top: 0;
        white-space: normal;
        overflow: visible;
        transform: translateY(0%);
        font-size: 14px;
        color: $gray_color;
        @include breakpoint(max, 767) {
          display: none;
        }
      }
    }
    .select__indicators {
      .select__indicator-separator {
        display: none;
      }
      .select__indicator {
        padding: 0;
      }
    }
    .select__menu {
      min-width: 100px;
      right: 0;
    }
    .select__option {
      white-space: nowrap;
      &.select__option--is-focused {
        background: $color_white;
      }
      &.select__option--is-selected {
        background: $primary_color;
      }
    }
  }
}
