import React from "react";
import { Route, Switch } from "react-router-dom";
import BarLoader from "react-spinners/BarLoader";
import PrivateRoute from "./PrivateRoute";

const Login = React.lazy(() => import("../components/Login"));
const Signup = React.lazy(() => import("../components/Signup"));
const Home = React.lazy(() => import("../components/Home"));
const NoMatch = React.lazy(() => import("../components/NoMatch"));
const OneMoreStep = React.lazy(() => import("../components/OneMoreStep"));
const VerifyEmail = React.lazy(() => import("../components/VerifyEmail"));
const ForgotPassword = React.lazy(() => import("../components/ForgotPassword"));
const ResetPassword = React.lazy(() => import("../components/ResetPassword"));
const ThankYou = React.lazy(() => import("../components/ThankYou"));
const CreatePost = React.lazy(() => import("../components/CreatePost"));
const EditPost = React.lazy(() => import("../components/EditPost"));
const PostDetails = React.lazy(() => import("../components/PostDetails"));
const CreateCommunity = React.lazy(() => import("../components/CreateCommunity"));
const CommunityPosts = React.lazy(() => import("../components/Community/Posts/CommunityPosts"));
const CommunityMembers = React.lazy(() => import("../components/Community/CommunityMembers"));
const UserPosts = React.lazy(() => import("../components/MyProfile/Posts"));
const UserSettings = React.lazy(() => import("../components/MyProfile/UserSettings"));
const UserCommunityPosts = React.lazy(() => import("../components/MyProfile/Community/Posts"));
const UserCommunityScheduledPosts = React.lazy(() => import("../components/MyProfile/Community/SchedulePosts/ScheduledPosts"));
const UserCommunitySettings = React.lazy(() => import("../components/MyProfile/Community/Settings"));
const UserCommunityRules = React.lazy(() => import("../components/MyProfile/Community/Rules/Rules"));
const UserCommunityMembers = React.lazy(() => import("../components/MyProfile/Community/Members/Members"));
const MyCommunity = React.lazy(() => import("../components/MyProfile/MyCommunity/MyCommunity"));
const OwnCommunity = React.lazy(() => import("../components/MyProfile/OwnCommunity/OwnCommunity"));
const UserFollowing = React.lazy(() => import("../components/MyProfile/Following/Following"));
const UserFollowers = React.lazy(() => import("../components/MyProfile/Followers/Followers"));
const UserNotifications = React.lazy(() => import("../components/MyProfile/Notifications/Notifications"));
const UserSavedPosts = React.lazy(() => import("../components/MyProfile/SavedPosts/SavedPosts"));
const UserScheduledPosts = React.lazy(() => import("../components/MyProfile/ScheduledPosts/ScheduledPosts"));
const UserComments = React.lazy(() => import("../components/MyProfile/Comments/Comments"));
const OrganizationProfile = React.lazy(() => import("../components/Organization/Profile"));
const OrganizationDiscussion = React.lazy(() => import("../components/Organization/Discussion"));
const OrganizationPosts = React.lazy(() => import("../components/Organization/Posts"));
const EmailJoinCommunity = React.lazy(() => import("../components/EmailActions/JoinCommunity"));
const EmailCommunityMemberRequestAccept = React.lazy(() => import("../components/EmailActions/CommunityOwnerAcceptRequest"));
const EmailFollowUser = React.lazy(() => import("../components/EmailActions/FollowUser"));
const EmailBlockUser = React.lazy(() => import("../components/EmailActions/BlockUser"));
const CurrentIssues = React.lazy(() => import("../components/CurrentIssues/CurrentIssues"));
const CurrentIssueDetails = React.lazy(() => import("../components/CurrentIssues/CurrentIssuesDetails"));
const TopicArticles = React.lazy(() => import("../components/Topic/Articles"));
const SearchResults = React.lazy(() => import("../components/SearchResult"));
const SearchArticles = React.lazy(() => import("../components/SearchResults/Articles"));
const SearchMembers = React.lazy(() => import("../components/SearchResults/Members"));
const SearchCommunities = React.lazy(() => import("../components/SearchResults/Communities"));
const SearchOrganizations = React.lazy(() => import("../components/SearchResults/Organizations"));
const SearchIssues = React.lazy(() => import("../components/SearchResults/Issues"));
const CommentShare = React.lazy(() => import("../components/CommentShare"));
const CurrentIssueCommentShare = React.lazy(() => import("../components/CurrentIssues/CommentShare"));
const NewsOrganizationCommentShare = React.lazy(() => import("../components/Organization/CommentShare"));
const BlogListing = React.lazy(() => import("../components/Cms/Blog"));
const Career = React.lazy(() => import("../components/Cms/Career"));
const PrivacyPolicy = React.lazy(() => import("../components/Cms/PrivacyPolicy"));
const TermsOfUse = React.lazy(() => import("../components/Cms/TermsOfUse"));
const CookiePolicy = React.lazy(() => import("../components/Cms/CookiePolicy"));
const Gdpr = React.lazy(() => import("../components/Cms/Gdpr"));
const Help = React.lazy(() => import("../components/Cms/Help"));
const BestPractices = React.lazy(() => import("../components/Cms/BestPractices"));
const AboutUs = React.lazy(() => import("../components/Cms/AboutUs"));
const ContactUs = React.lazy(() => import("../components/Cms/ContactUs"));
const BlogDetail = React.lazy(() => import("../components/Cms/BlogDetail"));
const ReactivateAccount = React.lazy(() => import("../components/ReactivateAccount"));

const Routes = (
  <React.Suspense
    fallback={(
      <div className="page-loader">
        <div>
          Loading...
          <BarLoader color="#ED6C27" width="150px" height="5px" />
        </div>
      </div>
    )}
  >
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/signup/thankyou" component={ThankYou} />
      <Route exact path="/signup/one-more-step" component={OneMoreStep} />
      <Route exact path="/verify/email/:token" component={VerifyEmail} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/reset-password/:token" component={ResetPassword} />
      <Route exact path="/community/:slug/posts" component={CommunityPosts} />
      <Route exact path="/post/:slug/details" component={PostDetails} />
      <Route
        exact
        path="/community/:slug/members"
        component={CommunityMembers}
      />
      <PrivateRoute
        path="/create-post"
        roles={["user"]}
        CurrentIssuesDetails
        component={CreatePost}
      />
      <PrivateRoute
        path="/edit-post/:slug"
        roles={["user"]}
        component={EditPost}
      />
      <PrivateRoute
        path="/create-community"
        roles={["user"]}
        component={CreateCommunity}
      />
      <Route path="/user/:slug/posts" roles={["user"]} component={UserPosts} />
      <PrivateRoute
        path="/user/:slug/settings"
        roles={["user"]}
        component={UserSettings}
      />
      <PrivateRoute
        path="/user/community/:slug/posts"
        roles={["user"]}
        component={UserCommunityPosts}
      />
      <PrivateRoute
        path="/user/community/:slug/scheduled-posts"
        roles={["user"]}
        component={UserCommunityScheduledPosts}
      />
      <PrivateRoute
        path="/user/community/:slug/settings"
        roles={["user"]}
        component={UserCommunitySettings}
      />
      <PrivateRoute
        path="/user/community/:slug/rules"
        roles={["user"]}
        component={UserCommunityRules}
      />
      <PrivateRoute
        path="/user/community/:slug/members"
        roles={["user"]}
        component={UserCommunityMembers}
      />
      <PrivateRoute
        path="/user/:slug/joined-communities"
        roles={["user"]}
        component={MyCommunity}
      />
      <PrivateRoute
        path="/user/:slug/owned-communities"
        roles={["user"]}
        component={OwnCommunity}
      />
      <PrivateRoute
        path="/user/:slug/following"
        roles={["user"]}
        component={UserFollowing}
      />
      <Route
        path="/user/:slug/followers"
        roles={["user"]}
        component={UserFollowers}
      />
      <PrivateRoute
        path="/user/:slug/notifications"
        roles={["user"]}
        component={UserNotifications}
      />
      <PrivateRoute
        path="/user/:slug/saved-posts"
        roles={["user"]}
        component={UserSavedPosts}
      />
      <PrivateRoute
        path="/user/:slug/scheduled-posts"
        roles={["user"]}
        component={UserScheduledPosts}
      />
      <PrivateRoute
        path="/user/:slug/comments"
        roles={["user"]}
        component={UserComments}
      />
      <PrivateRoute
        path="/join/community/:from/:slug"
        roles={["user"]}
        component={EmailJoinCommunity}
      />
      <PrivateRoute
        path="/community/:community_slug/member/request-accept/:slug"
        roles={["user"]}
        component={EmailCommunityMemberRequestAccept}
      />
      <PrivateRoute
        path="/user/follow/:slug"
        roles={["user"]}
        component={EmailFollowUser}
      />
      <PrivateRoute
        path="/user/block/:slug"
        roles={["user"]}
        component={EmailBlockUser}
      />
      <Route exact path="/topic/:slug" component={TopicArticles} />
      <Route exact path="/current-issues" component={CurrentIssues} />
      <Route
        path="/current-issues/:slug/details"
        component={CurrentIssueDetails}
      />
      <Route
        path="/news-organization/:slug/profile"
        component={OrganizationProfile}
      />
      <Route
        path="/news-organization/:slug/discussion"
        component={OrganizationDiscussion}
      />
      <Route
        path="/news-organization/:slug/posts"
        component={OrganizationPosts}
      />
      <Route path="/search-result" component={SearchResults} />
      <Route path="/search-results/articles" component={SearchArticles} />
      <Route path="/search-results/members" component={SearchMembers} />
      <Route path="/search-results/communities" component={SearchCommunities} />
      <Route
        path="/search-results/news-organizations"
        component={SearchOrganizations}
      />
      <Route path="/search-results/current-issues" component={SearchIssues} />
      <Route
        path="/post/:slug/comments/:commentSlug"
        component={CommentShare}
      />
      <Route
        path="/current-issues/:slug/comments/:commentSlug"
        component={CurrentIssueCommentShare}
      />
      <Route
        path="/news-organization/:slug/comments/:commentSlug"
        component={NewsOrganizationCommentShare}
      />
      <Route path="/blogs" component={BlogListing} />
      <Route path="/career" component={Career} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/terms-of-use" component={TermsOfUse} />
      <Route path="/cookie-policy" component={CookiePolicy} />
      <Route path="/gdpr-privacy-policy" component={Gdpr} />
      <Route path="/help" component={Help} />
      <Route path="/best-practices" component={BestPractices} />
      <Route path="/about-us" component={AboutUs} />
      <Route path="/contact-us" component={ContactUs} />
      <Route path="/blog-detail/:slug" component={BlogDetail} />
      <Route path="/reactivate/:user_slug" component={ReactivateAccount} />
      <Route path="/category/:category_slug/blogs" component={BlogListing} />
      <Route path="*" component={NoMatch} />
    </Switch>
  </React.Suspense>
);
export default Routes;