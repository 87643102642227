@import "./variables";

.nav{
	&.nav-outline{
		.nav-item{
			flex-grow: 1;
			flex-basis: 0;
    		flex-shrink: 1;
			@include breakpoint( max , 600){
				flex-grow: 0;
				flex-basis: auto;
				width: 100%;
				padding-bottom: 5px;
				.nav-link{
					@include border-radius(5px);
					border-width: 1px;
				}
			}
			&:first-child{
				.nav-link{
					border-top-left-radius: 5px;
					border-bottom-left-radius: 5px;
				}
			}
			&:last-child{
				.nav-link{
					border-right-width: 1px;
					border-top-right-radius: 5px;
					border-bottom-right-radius: 5px;
				}
			}
		}
		.nav-link{
			font-size: 14px;
			line-height: 17px;
			color: $primary_color;
			border-style: solid;
			border-color: $primary_color;
			border-width: 1px 0 1px 1px;
			padding: 0.72rem 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			.icon{
				margin-right: 10px;
				margin-top: -1px;
				svg{
					display: block;
					path{
						fill: $primary_color;
					}
				}
			}
			&.active{
				background: $primary_color;
				color: $color_white;
				.icon{
					svg{
						path{
							fill: $color_white;
						}
					}
				}
			}
		}
	}
}