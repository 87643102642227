import React, { useEffect, useState } from "react";
import { Link, NavLink, Redirect } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { useMutation, useQuery } from "@apollo/client";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import moment from "moment";
import { toast } from "react-toastify";
import { LinkContainer } from "react-router-bootstrap";
import { BarLoader } from "react-spinners";
import {
  GET_FOLLOWING_USER_IDS,
  GET_USER_BLOCK_USER_IDS,
} from "../../../queries/newsCosmosQueries";
import { FOLLOW_USER } from "../../../mutations/newsCosmosMutations";
import EditIcon from "../../../public/images/edit.svg";
import MenuIcon from "../../../public/images/discussion-icon.svg";
import "./_profileheader.scss";
import { numFormatter } from "../../../utils/helper";
import { useAuth } from "../../../utils/useAuth";
// import history from "../../../utils/history";

const ProfileHeader = ({ data }) => {
  const [open, setOpen] = useState(false);
  const loginUser = useAuth();
  const { data: followingUsersData, refetch } = useQuery(
    GET_FOLLOWING_USER_IDS,
    {
      skip: !loginUser.user,
    },
  );

  const { data: blockUserIdsData } = useQuery(GET_USER_BLOCK_USER_IDS, {
    variables: {
      slug: data.user.slug,
    },
    skip: !loginUser.user,
  });

  const [userFollow, mutationData] = useMutation(FOLLOW_USER);
  useEffect(() => {
    if (mutationData.data && mutationData.data.userFollow.status === "Fail") {
      toast.error(mutationData.data.userFollow.message);
    }
    if (
      mutationData.data
      && mutationData.data.userFollow.status === "Success"
    ) {
      toast.success(mutationData.data.userFollow.message);
    }
  }, [mutationData.data]);

  if (
    loginUser
    && (typeof loginUser.user === "object"
      ? loginUser.user.slug
      : JSON.parse(loginUser.user).slug) === data.user.slug
  ) {
    return (
      <div className="myprofile-header-wrap">
        {data && data.user && (
          <>
            <div className="profile-coverphoto">
              <img
                src={
                  data.user.userProfile
                    ? data.user.userProfile.cover_photo
                    : null
                }
                alt="cover"
              />
            </div>
            <div className="container container-sm">
              <div className="myprofile-intro-nav">
                <div className="myprofile-intro">
                  <div className="profile-photo">
                    <img
                      src={data.user.photo ? data.user.photo : null}
                      alt="avatar"
                    />
                  </div>
                  <div className="profile-info">
                    <div className="profile-meta">
                      <div className="left">
                        <h3>{data.user.username}</h3>
                        <div className="d-flex flex-wrap align-items-center">
                          <div className="followers">
                            <span>
                              {numFormatter(data.user.followers_count) ?? 0}
                            </span>
                            {" "}
                            Followers
                          </div>
                          <div className="create-date">
                            <span className="icon">
                              <EditIcon />
                            </span>
                            {" "}
                            Created
                            {" "}
                            {moment(data.user.created_at).format("DD MMM YYYY")}
                          </div>
                        </div>
                      </div>
                      <div className="right">
                        <Link
                          to={`/user/${data.user.slug}/settings`}
                          className="btn btn-primary btn-bold"
                        >
                          EDIT
                        </Link>
                      </div>
                    </div>
                    {data.user.userProfile && (
                      <div className="txt">
                        {data.user.userProfile.description}
                      </div>
                    )}
                  </div>
                </div>
                <div className="myprofile-nav">
                  <Button
                    onClick={() => setOpen(!open)}
                    aria-expanded={open}
                    variant="nav"
                  >
                    <MenuIcon />
                    {" "}
                    Profile Menu
                  </Button>
                  <Collapse in={open}>
                    <div className="nav-list">
                      <ul>
                        <li>
                          <NavLink
                            className="nav-link"
                            exact
                            to={`/user/${data.user.slug}/posts`}
                          >
                            Posts
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className="nav-link"
                            exact
                            to={`/user/${data.user.slug}/comments`}
                          >
                            Comments
                          </NavLink>
                        </li>
                        <li>
                          <NavDropdown title="Activity">
                            <ul>
                              <li>
                                <LinkContainer
                                  exact
                                  to={`/user/${data.user.slug}/saved-posts`}
                                >
                                  <NavDropdown.Item>
                                    Saved Posts
                                  </NavDropdown.Item>
                                </LinkContainer>
                              </li>
                              <li>
                                <LinkContainer
                                  exact
                                  to={`/user/${data.user.slug}/scheduled-posts`}
                                >
                                  <NavDropdown.Item>
                                    Scheduled Posts
                                  </NavDropdown.Item>
                                </LinkContainer>
                              </li>
                            </ul>
                          </NavDropdown>
                        </li>
                        <li>
                          <NavDropdown title="Communities">
                            <ul>
                              <li>
                                <LinkContainer
                                  exact
                                  to={`/user/${data.user.slug}/joined-communities`}
                                >
                                  <NavDropdown.Item>
                                    Joined Communities
                                  </NavDropdown.Item>
                                </LinkContainer>
                              </li>
                              <li>
                                <LinkContainer
                                  exact
                                  to={`/user/${data.user.slug}/owned-communities`}
                                >
                                  <NavDropdown.Item>
                                    Owned Communities
                                  </NavDropdown.Item>
                                </LinkContainer>
                              </li>
                            </ul>
                          </NavDropdown>
                        </li>
                        <li>
                          <NavLink
                            className="nav-link"
                            exact
                            to={`/user/${data.user.slug}/following`}
                          >
                            Following
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className="nav-link"
                            exact
                            to={`/user/${data.user.slug}/followers`}
                          >
                            Followers
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className="nav-link"
                            exact
                            to={`/user/${data.user.slug}/notifications`}
                          >
                            Notifications
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className="nav-link"
                            exact
                            to={`/user/${data.user.slug}/settings`}
                          >
                            Settings
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
  if (mutationData && mutationData.loading) {
    return (
      <div className="page-loader">
        <div>
          Loading...
          <BarLoader color="#ED6C27" width="150px" height="5px" />
        </div>
      </div>
    );
  }
  if (
    data
    && blockUserIdsData
    && blockUserIdsData.getUserBlockedUserIds
    && blockUserIdsData.getUserBlockedUserIds.blocked_user_ids
  ) {
    if (
      blockUserIdsData.getUserBlockedUserIds.blocked_user_ids.includes(
        loginUser.user.id,
      )
    ) {
      return <Redirect to="/" />;
    }
  }
  return (
    <div className="myprofile-header-wrap">
      {data && data.user && (
        <>
          <div className="profile-coverphoto">
            <img
              src={
                data.user.userProfile ? data.user.userProfile.cover_photo : null
              }
              alt="cover"
            />
          </div>
          <div className="container container-sm">
            <div className="myprofile-intro-nav">
              <div className="myprofile-intro">
                <div className="profile-photo">
                  <img
                    src={data.user.photo ? data.user.photo : null}
                    alt="avatar"
                  />
                </div>
                <div className="profile-info">
                  <div className="profile-meta">
                    <div className="left">
                      <h3>{data.user.username}</h3>
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="followers">
                          <span>
                            {numFormatter(data.user.followers_count) ?? 0}
                          </span>
                          {" "}
                          Followers
                        </div>
                        <div className="create-date">
                          <span className="icon">
                            <EditIcon />
                          </span>
                          {" "}
                          Created
                          {" "}
                          {moment(data.user.created_at).format("DD MMM YYYY")}
                        </div>
                      </div>
                    </div>
                    <div className="right">
                      {loginUser
                      && followingUsersData
                      && followingUsersData.getFollowingUserIds
                      && followingUsersData.getFollowingUserIds
                        .following_user_ids
                      && followingUsersData.getFollowingUserIds.following_user_ids.includes(
                        data.user.id,
                      ) ? (
                        <Button variant="primary" className="btn-bold">
                          Following
                        </Button>
                        ) : (
                          <Button
                            variant="outline-primary"
                            className="btn-bold"
                            onClick={async () => {
                              await userFollow({
                                variables: { user_slug: data.user.slug },
                              });
                              refetch();
                            }}
                            title="Follow"
                          >
                            Follow
                          </Button>
                        )}
                    </div>
                  </div>
                  {data.user.userProfile && (
                    <div className="txt">
                      {data.user.userProfile.description}
                    </div>
                  )}
                </div>
              </div>
              <div className="myprofile-nav">
                <Button
                  onClick={() => setOpen(!open)}
                  aria-expanded={open}
                  variant="nav"
                >
                  <MenuIcon />
                  {" "}
                  Profile Menu
                </Button>
                <Collapse in={open}>
                  <div className="nav-list">
                    <ul>
                      <li>
                        <NavLink
                          className="nav-link"
                          exact
                          to={`/user/${data.user.slug}/posts`}
                        >
                          Posts
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          className="nav-link"
                          exact
                          to={`/user/${data.user.slug}/followers`}
                        >
                          Followers
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProfileHeader;