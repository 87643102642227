import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import { Link, Redirect } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import ValidationErrorInput from "./UIComponents/ValidationErrorInput";
import {
  REPORT_COMMENT,
  REPORT_CURRENT_ISSUE_COMMENT,
  REPORT_POST,
} from "../mutations/newsCosmosMutations";
import Close from "../public/images/close.svg";
import FlagIcon from "../public/images/flag.svg";
import {
  GET_REPORTED_COMMENT_IDS,
  GET_REPORTED_CURRENT_ISSUE_COMMENT_IDS,
  GET_REPORTED_POST_IDS,
} from "../queries/newsCosmosQueries";

const Report = ({ type, slug, reportId }) => {
  const [redirectTo, setRedirectTo] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const isUser = localStorage.getItem("user");
  const [reportPost, postMutationData] = useMutation(REPORT_POST);
  const [reportComment, mutationData] = useMutation(REPORT_COMMENT);
  const [reportCurrentIssueComment, currentIssueMutationData] = useMutation(
    REPORT_CURRENT_ISSUE_COMMENT,
  );

  const { data, refetch } = useQuery(GET_REPORTED_POST_IDS, {
    skip: !isUser,
  });

  useEffect(() => {
    if (
      postMutationData.data
      && postMutationData.data.reportPost.message === "Post not found!"
    ) {
      toast.error(postMutationData.data.reportPost.message);
      handleClose();
      setRedirectTo(true);
    } else {
      if (
        postMutationData.data
        && postMutationData.data.reportPost.status === "Success"
      ) {
        toast.success(postMutationData.data.reportPost.message);
        refetch();
        handleClose();
      }
      if (
        postMutationData.data
        && postMutationData.data.reportPost.status === "Fail"
      ) {
        toast.error(postMutationData.data.reportPost.message);
        handleClose();
      }
    }
  }, [postMutationData.data]);

  if (isUser) {
    if (type === "post") {
      if (redirectTo) {
        return <Redirect to="/" />;
      }
      return (
        <>
          <div className="changes-password">
            <button
              type="button"
              className={`d-flex align-items-center border-0 bg-transparent text-reset ${
                isUser
                  ? data
                    && data.getReportedPostIds.reported_post_ids
                    && data.getReportedPostIds.reported_post_ids.includes(
                      reportId,
                    )
                    && "active"
                  : ""
              }`}
              onClick={handleShow}
            >
              <span className="icon">
                <FlagIcon />
              </span>
              {" "}
              <span className="txt">Report</span>
            </button>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="change-password-popup"
          >
            <Button variant="modal-close" onClick={handleClose}>
              <Close />
            </Button>
            <Modal.Header>
              <Modal.Title>REPORT</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={{
                  description: "",
                }}
                onSubmit={async (values) => {
                  await reportPost({
                    variables: {
                      description: values.description,
                      post_slug: slug,
                    },
                  });
                }}
                validate={(values) => {
                  const errors = {};

                  if (!values.description) {
                    errors.description = "Please enter description.";
                  }

                  return errors;
                }}
              >
                {({
                  values, errors, touched, handleSubmit, setFieldValue,
                }) => (
                  <Form autoComplete="off" onSubmit={handleSubmit}>
                    <div className="form-wrap">
                      <div className="form-group">
                        <label htmlFor="old-password" className="form-label">
                          Description:
                        </label>
                        <div className="password-group">
                          <textarea
                            className="form-control"
                            values={values.description}
                            onChange={(e) => setFieldValue("description", e.target.value)}
                            rows="5"
                          />
                          {errors.description && touched.description && (
                          <ValidationErrorInput error={errors.description} />
                          )}
                        </div>
                      </div>

                      <div className="cta-group">
                        <Button
                          variant="outline-primary"
                          className="btn-bold"
                          onClick={handleClose}
                        >
                          CANCEL
                        </Button>
                        <Button
                          variant="primary"
                          className="btn-bold"
                          type="submit"
                          disabled={postMutationData.loading}
                        >
                          SUBMIT
                          {postMutationData.loading && (
                          <span className="ml-2">
                            <ClipLoader
                              color="#ffffff"
                              loading={true}
                              size={15}
                            />
                          </span>
                          )}
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
        </>
      );
    }
    if (type === "comment") {
      /* eslint-disable */
      const { data, refetch } = useQuery(GET_REPORTED_COMMENT_IDS);

      useEffect(() => {
        if (
          mutationData.data &&
          mutationData.data.reportComment.message === "Comment not found!"
        ) {
          toast.error(mutationData.data.reportComment.message);
          handleClose();
          setRedirectTo(true);
        } else {
          if (
            mutationData.data &&
            mutationData.data.reportComment.status === "Success"
          ) {
            toast.success(mutationData.data.reportComment.message);
            refetch();
            handleClose();
          }
          if (
            mutationData.data &&
            mutationData.data.reportComment.status === "Fail"
          ) {
            toast.error(mutationData.data.reportComment.message);
            handleClose();
          }
        }
      }, [mutationData.data]);
      if (redirectTo) {
        return <Redirect to="/" />;
      }
      return (
        <>
          <div className="changes-password">
            <button
              type="button"
              className={`d-flex align-items-center border-0 bg-transparent text-reset ${
                isUser
                  ? data &&
                    data.getReportedCommentIds.reported_comment_ids &&
                    data.getReportedCommentIds.reported_comment_ids.includes(
                      slug
                    ) &&
                    "active"
                  : ""
              }`}
              onClick={handleShow}
            >
              <span className="icon">
                <FlagIcon />
              </span>{" "}
              <span className="txt">Report</span>
            </button>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="change-password-popup"
          >
            <Button variant="modal-close" onClick={handleClose}>
              <Close />
            </Button>
            <Modal.Header>
              <Modal.Title>REPORT</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={{
                  description: "",
                }}
                onSubmit={async (values) => {
                  await reportComment({
                    variables: {
                      description: values.description,
                      comment_id: slug,
                    },
                  });
                }}
                validate={(values) => {
                  const errors = {};

                  if (!values.description) {
                    errors.description = "Please enter description.";
                  }

                  return errors;
                }}
              >
                {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                  <Form autoComplete="off" onSubmit={handleSubmit}>
                    <div className="form-wrap">
                      <div className="form-group">
                        <label htmlFor="old-password" className="form-label">
                          Description:
                        </label>
                        <div className="password-group">
                          <textarea
                            className="form-control"
                            values={values.description}
                            onChange={(e) =>
                              setFieldValue("description", e.target.value)
                            }
                            rows="5"
                          />
                          {errors.description && touched.description && (
                            <ValidationErrorInput error={errors.description} />
                          )}
                        </div>
                      </div>

                      <div className="cta-group">
                        <Button
                          variant="outline-primary"
                          className="btn-bold"
                          onClick={handleClose}
                        >
                          CANCEL
                        </Button>
                        <Button
                          variant="primary"
                          className="btn-bold"
                          type="submit"
                          disabled={mutationData.loading}
                        >
                          SUBMIT
                          {mutationData.loading && (
                            <span className="ml-2">
                              <ClipLoader
                                color="#ffffff"
                                loading={true}
                                size={15}
                              />
                            </span>
                          )}
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
        </>
      );
    }
    if (type === "current_issue") {
      const { data, refetch } = useQuery(
        GET_REPORTED_CURRENT_ISSUE_COMMENT_IDS
      );
      useEffect(() => {
        if (
          currentIssueMutationData.data &&
          currentIssueMutationData.data.reportCurrentIssueComment.message ===
            "Comment not found!"
        ) {
          toast.error(mutationData.data.reportCurrentIssueComment.message);
          handleClose();
          setRedirectTo(true);
        } else {
          if (
            currentIssueMutationData.data &&
            currentIssueMutationData.data.reportCurrentIssueComment.status ===
              "Success"
          ) {
            toast.success(
              currentIssueMutationData.data.reportCurrentIssueComment.message
            );
            refetch();
            handleClose();
          }
          if (
            currentIssueMutationData.data &&
            currentIssueMutationData.data.reportCurrentIssueComment.status ===
              "Fail"
          ) {
            toast.error(
              currentIssueMutationData.data.reportCurrentIssueComment.message
            );
            handleClose();
          }
        }
      }, [currentIssueMutationData.data]);
      if (redirectTo) {
        return <Redirect to="/" />
      }
      return (
        <>
          <div className="changes-password">
            <button
              type="button"
              className={`d-flex align-items-center border-0 bg-transparent text-reset ${
                isUser
                  ? data &&
                    data.getReportedCurrentIssueCommentIds
                      .reported_current_issue_comment_ids &&
                    data.getReportedCurrentIssueCommentIds.reported_current_issue_comment_ids.includes(
                      slug
                    ) &&
                    "active"
                  : ""
              }`}
              onClick={handleShow}
            >
              <span className="icon">
                <FlagIcon />
              </span>{" "}
              <span className="txt">Report</span>
            </button>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="change-password-popup"
          >
            <Button variant="modal-close" onClick={handleClose}>
              <Close />
            </Button>
            <Modal.Header>
              <Modal.Title>REPORT</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={{
                  description: "",
                }}
                onSubmit={async (values) => {
                  await reportCurrentIssueComment({
                    variables: {
                      description: values.description,
                      current_issue_comment_id: slug,
                    },
                  });
                }}
                validate={(values) => {
                  const errors = {};

                  if (!values.description) {
                    errors.description = "Please enter description.";
                  }

                  return errors;
                }}
              >
                {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                  <Form autoComplete="off" onSubmit={handleSubmit}>
                    <div className="form-wrap">
                      <div className="form-group">
                        <label htmlFor="old-password" className="form-label">
                          Description:
                        </label>
                        <div className="password-group">
                          <textarea
                            className="form-control"
                            values={values.description}
                            onChange={(e) =>
                              setFieldValue("description", e.target.value)
                            }
                            rows="5"
                          />
                          {errors.description && touched.description && (
                            <ValidationErrorInput error={errors.description} />
                          )}
                        </div>
                      </div>

                      <div className="cta-group">
                        <Button
                          variant="outline-primary"
                          className="btn-bold"
                          onClick={handleClose}
                        >
                          CANCEL
                        </Button>
                        <Button
                          variant="primary"
                          className="btn-bold"
                          type="submit"
                          disabled={currentIssueMutationData.loading}
                        >
                          SUBMIT
                          {currentIssueMutationData.loading && (
                            <span className="ml-2">
                              <ClipLoader
                                color="#ffffff"
                                loading={true}
                                size={15}
                              />
                            </span>
                          )}
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
        </>
      );
    }
  }

  return (
    <>
      <div className="changes-password">
        <Link
          to="/login"
          className="d-flex align-items-center border-0 bg-transparent text-reset"
        >
          <span className="icon">
            <FlagIcon />
          </span>{" "}
          <span className="txt">Report</span>
        </Link>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="change-password-popup"
      >
        <Button variant="modal-close" onClick={handleClose}>
          <Close />
        </Button>
        <Modal.Header>
          <Modal.Title>REPORT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              description: "",
            }}
            onSubmit={async (values) => {
              if (type === "post") {
                await reportPost({
                  variables: {
                    description: values.description,
                    post_slug: slug,
                  },
                });
              }
              if (type === "comment") {
                await reportComment({
                  variables: {
                    description: values.description,
                    comment_id: slug,
                  },
                });
              }
              if (type === "current_issue") {
                await reportCurrentIssueComment({
                  variables: {
                    description: values.description,
                    current_issue_comment_id: slug,
                  },
                });
              }
            }}
            validate={(values) => {
              const errors = {};

              if (!values.description) {
                errors.description = "Please enter description.";
              }

              return errors;
            }}
          >
            {({ values, errors, touched, handleSubmit, setFieldValue }) => (
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <div className="form-wrap">
                  <div className="form-group">
                    <label htmlFor="old-password" className="form-label">
                      Description:
                    </label>
                    <div className="password-group">
                      <textarea
                        className="form-control"
                        values={values.description}
                        onChange={(e) =>
                          setFieldValue("description", e.target.value)
                        }
                        rows="5"
                      />
                      {errors.description && touched.description && (
                        <ValidationErrorInput error={errors.description} />
                      )}
                    </div>
                  </div>

                  <div className="cta-group">
                    <Button
                      variant="outline-primary"
                      className="btn-bold"
                      onClick={handleClose}
                    >
                      CANCEL
                    </Button>
                    <Button
                      variant="primary"
                      className="btn-bold"
                      type="submit"
                      disabled={currentIssueMutationData.loading}
                    >
                      SUBMIT
                      {currentIssueMutationData.loading && (
                        <span className="ml-2">
                          <ClipLoader
                            color="#ffffff"
                            loading={true}
                            size={15}
                          />
                        </span>
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Report;
