import React from "react";
import { useQuery } from "@apollo/client";
import ClipLoader from "react-spinners/ClipLoader";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { GET_OWNED_COMMUNITIES } from "../../../../queries/newsCosmosQueries";
import "../CommunitiesNearby/communitiesnearby.scss";
import { numFormatter } from "../../../../utils/helper";

const CommunitiesOwned = ({ match }) => {
  const { loading, error, data } = useQuery(GET_OWNED_COMMUNITIES, {
    variables: {
      limit: 4,
      community_type: null,
      current_page: 1,
    },
  });
  if (loading || error) {
    return (
      <div className="community-nearby-box">
        <h3>Owned Communities</h3>
        <div className="community-nearby-list">
          <p>
            Loading...
            <span className="ml-2">
              <ClipLoader color="#e76c35" loading={true} size={15} />
            </span>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="community-nearby-box">
      <h3>Owned Communities</h3>
      <div className="community-nearby-list">
        {data.getOwnedCommunities
          && data.getOwnedCommunities.owned_communities.length > 0 && (
            <ul>
              {data.getOwnedCommunities.owned_communities.map((community) => (
                <li key={Math.random()}>
                  <div className="community-info">
                    <div className="photo">
                      <img src={community.photo} alt={community.name} />
                    </div>
                    <div className="name-info">
                      <div className="community-name">
                        <Link to={`/community/${community.slug}/posts`}>
                          {community.name}
                        </Link>
                      </div>
                      <div className="community-members">
                        {numFormatter(community.community_members_count) ?? 0}
                        {" "}
                        members
                      </div>
                    </div>
                  </div>
                  <div className="join-btn">
                    <Link to={`/user/community/${community.slug}/settings`}>
                      <Button variant="primary" className="btn-bold">
                        Edit
                      </Button>
                    </Link>
                  </div>
                </li>
              ))}
            </ul>
        )}
        {data.getOwnedCommunities
          && data.getOwnedCommunities.owned_communities.length === 0 && (
            <p>Nothing to see here — yet</p>
        )}
        {data
          && data.getOwnedCommunities
          && data.getOwnedCommunities.owned_communities.length > 0
          && data.getOwnedCommunities.next_page !== 0 && (
            <div className="create-article-btn d-block">
              <Link
                to={`/user/${match.params.slug}/owned-communities`}
                className="btn-bold btn-primary btn btn-block"
              >
                VIEW ALL Communities
              </Link>
            </div>
        )}
      </div>
    </div>
  );
};

export default CommunitiesOwned;