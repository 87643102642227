@import "../variables";

.myprofile-page-wrap{
	padding-bottom: 40px;
	.profile-page-title{
		padding: 26px 0 13px;
		h2{
			font-size: 20px;
			line-height: 24px;
			font-weight: 600;
			color: $color_gray_dark;
		}
	}
	.line-title{
		font-size: 14px;
		text-transform: uppercase;
		border-bottom: 1px solid rgba($gray_color, .2);
		margin-bottom: 25px;
	}
	.main{
		padding-top: 0;
		padding-bottom: 0;
	}
	&.orgprofile-page-wrap{
		.main{
			.sidebar-col{
				.sidebar-ads{
					margin-top: 0;
				}
			}
		}
	}
}

.schedule-post-popup{
	.modal-dialog{
		max-width: 360px;
		@include breakpoint( max , 400){
			width: 90%;
			margin: 2em auto;
		}
	}
	.modal-body{
		padding: 10px 0 5px 0;
	}
}

.profile-info-box{
	background: $color_white;
	padding: 20px;
	@include border-radius(5px);
	.profile-info-list{
		ul{
			li{
				display: flex;
				padding-bottom: 20px;
				&:last-child{
					padding-bottom: 0;
				}
				.icon{
					flex-shrink: 0;
					width: 28px;
					padding-top: 2px;
					svg{
						display: block;
					}
				}
				h3{
					font-size: 16px;
					line-height: 20px;
					font-weight: 600;
					color: $color_gray_dark;
					padding-bottom: 3px;
				}
				p{
					margin-bottom: 0;
					font-size: 14px;
				}
			}
		}
	}
}