import { useQuery } from "@apollo/client";
import React, {
  useState, useContext, createContext, useEffect,
} from "react";
import { GET_USER_DETAIL } from "../queries/newsCosmosQueries";
import client from "./apolloClient";

const authContext = createContext();

export const useAuth = () => useContext(authContext);

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const lCount = localStorage.getItem("lCount");
  const { data } = useQuery(GET_USER_DETAIL, {
    variables: {
      slug: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).slug
        : "",
    },
    skip: !localStorage.getItem("user"),
  });
  useEffect(() => {
    if (data && data.user) {
      setUser(data.user);
    }
  }, [data]);
  useEffect(() => {
    if (localStorage.getItem("user")) {
      setUser(localStorage.getItem("user"));
    } else {
      setUser(false);
    }
  }, []);

  const signIn = (accessToken, currentUser) => {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("user", JSON.stringify(currentUser));
    setUser(currentUser);
    return currentUser;
  };
  const signOut = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("showCommunity");
    localStorage.removeItem("user");
    localStorage.removeItem("lCount");
    client.resetStore();
    return setUser(false);
  };

  return {
    user,
    lCount,
    signIn,
    signOut,
  };
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}