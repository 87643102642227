@import "./variables";

.blog-list{
	padding-bottom: 6px;
	.blog-item{
		margin-bottom: 24px;	
	}
}

.blog-item{
	background: $color_white;
	@include border-radius(5px);
	.blog-img{
		img{
			@include border-radius(5px 5px 0 0);
			width: 100%;
		}
	}
	.blog-item-info{
		padding: 22px 20px;
		h3{
			font-size: 20px;
			font-weight: 500;
			color: $color_gray_dark;
			padding-bottom: 18px;
			a{
				color: $color_gray_dark;
				display: inline-block;
			}
		}
		.blog-meta{
			padding-bottom: 20px;
			ul{
				display: flex;
				flex-wrap: wrap;
				li{
					padding-right: 12px;
					margin-right: 12px;
					border-right: 1px solid rgba($gray_color, .5);
					font-size: 14px;
					line-height: 1;
					color: $gray_color;
					display: flex;
					align-items: center;
					&:last-child{
						border-right: 0;
						margin-right: 0;
						padding-right: 0;
					}
					span{
						display: block;
						margin-right: 9px;
					}
					svg{
						display: block;
					}
				}
			}
		}
		.desc{
			font-size: 16px;
			line-height: 22px;
			color: #575757;
			margin-bottom: 20px;
		}
		.cta{
			a{
				display: flex;
				color: $primary_color;
				font-size: 14px;
				font-weight: 500;
				text-transform: uppercase;
				line-height: 17px;
				svg{
					display: block;
					margin-left: 9px;
				}
			}
		}
		.share{
			position: relative;
			width: 36px;
			height: 36px;
			.mfb-component, .mfb-component--tl, .mfb-component--tr, .mfb-component--bl, .mfb-component--br{
				position: absolute;
				padding: 0;
				margin: 0;
			}
			.mfb-component__wrap{
				padding: 0;
				margin: 0;
			}
			.mfb-component__button--main, .mfb-component__button--child{
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.mfb-component__button--child i{
				opacity: 1;
			}
			.mfb-component__list{
				// position: relative;
				.mfb-component__list-item{
					li{
						transform: translate(0px, 0px) !important;
					}
				}
			}
			.icon-share{
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.734' height='16.764' viewBox='0 0 14.734 16.764'%3E%3Cpath id='sharing' d='M42.787,10.871a2.937,2.937,0,0,0-2.039.822L37.6,9.74a3.435,3.435,0,0,0,0-2.715l3.152-1.953a2.941,2.941,0,1,0-.588-.791L37.082,6.187a3.438,3.438,0,1,0,0,4.39l3.078,1.907a2.947,2.947,0,1,0,2.627-1.613Z' transform='translate(-31)' fill='%23fff'/%3E%3C/svg%3E%0A");
				background-repeat: no-repeat;
				width: 15px;
				height: 17px;
				transform: rotate(0);
			}
			.icon-linkedin{
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='17.205' viewBox='0 0 18 17.205'%3E%3Cg id='linkedin' transform='translate(0 0.668)' style='isolation: isolate'%3E%3Cpath id='Path_17279' data-name='Path 17279' d='M2.183-.668a2.011,2.011,0,1,0-.051,4.011h.025A2.012,2.012,0,1,0,2.183-.668Zm0,0' transform='translate(0 0)' fill='%23fff'/%3E%3Cpath id='Path_17280' data-name='Path 17280' d='M8.109,198.313h3.859v11.609H8.109Zm0,0' transform='translate(-7.881 -193.384)' fill='%23fff'/%3E%3Cpath id='Path_17281' data-name='Path 17281' d='M228.551,188.625a4.834,4.834,0,0,0-3.477,1.956V188.9h-3.859v11.609h3.859v-6.483a2.645,2.645,0,0,1,.127-.942,2.112,2.112,0,0,1,1.98-1.411c1.4,0,1.955,1.065,1.955,2.625v6.21h3.858V193.85C232.993,190.284,231.089,188.625,228.551,188.625Zm0,0' transform='translate(-214.993 -183.969)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
				background-repeat: no-repeat;
				width: 18px;
				height: 18px;
			}
			.icon-twitter{
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='16.25' viewBox='0 0 18 16.25'%3E%3Cpath id='Path_16853' data-name='Path 16853' d='M1476.137,3479.438a8.686,8.686,0,0,0,1.863-2.149,7.3,7.3,0,0,1-2.11.664,4.023,4.023,0,0,0,1.617-2.265,7.073,7.073,0,0,1-2.355.976,4.187,4.187,0,0,0-1.213-.937,3.218,3.218,0,0,0-1.494-.352,3.292,3.292,0,0,0-1.828.547,3.836,3.836,0,0,0-1.336,1.5,4.414,4.414,0,0,0-.492,2.051,6.305,6.305,0,0,0,.07.937,9.434,9.434,0,0,1-4.236-1.25,10.482,10.482,0,0,1-3.358-3.047,4.363,4.363,0,0,0-.07,4.024,4.432,4.432,0,0,0,1.2,1.484,3.221,3.221,0,0,1-1.652-.547v.078a4.2,4.2,0,0,0,.844,2.578,3.684,3.684,0,0,0,2.109,1.446,4.245,4.245,0,0,1-.984.117,6.084,6.084,0,0,1-.668-.039,4.1,4.1,0,0,0,1.283,2.012,3.221,3.221,0,0,0,2.163.8,6.867,6.867,0,0,1-4.605,1.758q-.493,0-.879-.039a9.751,9.751,0,0,0,11.39,0,10.853,10.853,0,0,0,3.551-4.414,12.63,12.63,0,0,0,1.23-5.43A2.571,2.571,0,0,0,1476.137,3479.438Z' transform='translate(-1460 -3475.375)' fill='%23fff'/%3E%3C/svg%3E%0A");
				background-repeat: no-repeat;
				width: 18px;
				height: 17px;
			}
			.icon-whatsapp{
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cpath id='Path_17282' data-name='Path 17282' d='M9,0H9A8.994,8.994,0,0,0,1.713,14.275L.592,17.619l3.459-1.106A9,9,0,1,0,9,0Zm5.237,12.709a2.54,2.54,0,0,1-1.766,1.27c-.47.1-1.084.18-3.152-.677A11.274,11.274,0,0,1,4.84,9.344,5.14,5.14,0,0,1,3.771,6.63a2.873,2.873,0,0,1,.92-2.19,1.308,1.308,0,0,1,.92-.323c.111,0,.212.006.3.01.264.011.4.027.572.444.217.523.746,1.815.809,1.947a.536.536,0,0,1,.038.487,1.555,1.555,0,0,1-.291.413c-.133.153-.259.27-.391.434-.122.143-.259.3-.106.56A7.988,7.988,0,0,0,8,10.227a6.617,6.617,0,0,0,2.111,1.3.569.569,0,0,0,.635-.1,10.89,10.89,0,0,0,.7-.932.5.5,0,0,1,.646-.2c.243.084,1.529.72,1.793.852s.439.2.5.307A2.241,2.241,0,0,1,14.239,12.709Z' fill='%23fff'/%3E%3C/svg%3E%0A");
				background-repeat: no-repeat;
				width: 18px;
				height: 18px;
			}
			.icon-facebook{
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='19.258' viewBox='0 0 10 19.258'%3E%3Cpath id='f_1_' d='M43.781,19.258V10.474h2.947l.442-3.424H43.781V4.864c0-.991.274-1.666,1.7-1.666H47.29V.134A24.566,24.566,0,0,0,44.649,0c-2.614,0-4.4,1.6-4.4,4.525V7.05H37.29v3.424h2.956v8.784Z' transform='translate(-37.29)' fill='%23fff'/%3E%3C/svg%3E%0A");
				background-repeat: no-repeat;
				width: 10px;
				height: 19px;
			}
			.icon-pocket{
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='14.875' viewBox='0 0 17 14.875'%3E%3Cpath id='Path_17283' data-name='Path 17283' d='M15.938,32H1.063A1.064,1.064,0,0,0,0,33.063v5.844a7.977,7.977,0,0,0,7.969,7.969H9.031A7.977,7.977,0,0,0,17,38.906V33.063A1.064,1.064,0,0,0,15.938,32ZM13.5,38.064l-4.25,4.25a1.062,1.062,0,0,1-1.5,0L3.5,38.064a1.062,1.062,0,1,1,1.5-1.5l3.5,3.5,3.5-3.5a1.062,1.062,0,1,1,1.5,1.5Z' transform='translate(0 -32)' fill='%23fff'/%3E%3C/svg%3E%0A");
				background-repeat: no-repeat;
				width: 17px;
				height: 14px;
			}
			.icon-instagram{
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='17.996' viewBox='0 0 18 17.996'%3E%3Cpath id='instagram' d='M8.927,36.209a4.614,4.614,0,1,0,4.614,4.614A4.607,4.607,0,0,0,8.927,36.209Zm0,7.614a3,3,0,1,1,3-3A3.005,3.005,0,0,1,8.927,43.823Zm5.879-7.8a1.076,1.076,0,1,1-1.076-1.076A1.074,1.074,0,0,1,14.806,36.02Zm3.056,1.092a5.326,5.326,0,0,0-1.454-3.771,5.361,5.361,0,0,0-3.771-1.454c-1.486-.084-5.939-.084-7.425,0a5.353,5.353,0,0,0-3.771,1.45A5.343,5.343,0,0,0-.012,37.109c-.084,1.486-.084,5.939,0,7.425A5.326,5.326,0,0,0,1.442,48.3a5.368,5.368,0,0,0,3.771,1.454c1.486.084,5.939.084,7.425,0A5.326,5.326,0,0,0,16.408,48.3a5.361,5.361,0,0,0,1.454-3.771C17.946,43.048,17.946,38.6,17.862,37.113Zm-1.919,9.015a3.037,3.037,0,0,1-1.711,1.711c-1.185.47-4,.361-5.3.361s-4.124.1-5.3-.361a3.037,3.037,0,0,1-1.711-1.711c-.47-1.185-.361-4-.361-5.3s-.1-4.124.361-5.3a3.037,3.037,0,0,1,1.711-1.711c1.185-.47,4-.361,5.3-.361s4.124-.1,5.3.361a3.037,3.037,0,0,1,1.711,1.711c.47,1.185.361,4,.361,5.3S16.412,44.947,15.942,46.128Z' transform='translate(0.075 -31.825)' fill='%23fff'/%3E%3C/svg%3E%0A");
				background-repeat: no-repeat;
				width: 18px;
				height: 18px;
			}
			.icon-email{
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12.75' viewBox='0 0 17 12.75'%3E%3Cpath id='envelope' d='M16.678,68.21a.2.2,0,0,1,.322.156v6.79a1.594,1.594,0,0,1-1.594,1.594H1.594A1.594,1.594,0,0,1,0,75.156V68.37a.2.2,0,0,1,.322-.156c.744.578,1.73,1.312,5.117,3.772.7.511,1.883,1.587,3.061,1.58,1.185.01,2.391-1.089,3.065-1.58C14.951,69.525,15.934,68.788,16.678,68.21ZM8.5,72.5c.77.013,1.879-.97,2.437-1.375,4.406-3.2,4.741-3.476,5.757-4.273A.8.8,0,0,0,17,66.225v-.631A1.594,1.594,0,0,0,15.406,64H1.594A1.594,1.594,0,0,0,0,65.594v.631a.8.8,0,0,0,.305.628c1.016.794,1.351,1.076,5.757,4.273C6.621,71.53,7.73,72.513,8.5,72.5Z' transform='translate(0 -64)' fill='%23fff'/%3E%3C/svg%3E%0A");
				background-repeat: no-repeat;
				width: 17px;
				height: 13px;
			}
			button{
				border: 0;
				background: transparent;
				font-size: 12px;
				color: $gray_color;
			}
		}
	}
}