import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import "@babel/polyfill";
import client from "./utils/apolloClient";
import history from "./utils/history";
import App from "./App";

function renderApp() {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <Router history={history} basename="/">
        <App />
      </Router>
    </ApolloProvider>,
    document.getElementById("root"),
  );
}
renderApp();
if (process.NODE_ENV === "development" && module.hot) {
  module.hot.accept();
}