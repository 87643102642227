import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import ClipLoader from "react-spinners/ClipLoader";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import {
  Formik, Form, Field, FieldArray, ErrorMessage,
} from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useAuth } from "../../../../utils/useAuth";
import { INVITE_PEOPLE_TO_JOIN_COMMUNITY } from "../../../../mutations/newsCosmosMutations";
import Close from "../../../../public/images/close.svg";
import AddIcon from "../../../../public/images/add.svg";
import "../../../../public/style/_form.scss";
import "./_invitepeople.scss";

/* eslint-disable */
Yup.addMethod(Yup.array, "unique", function (message, mapper = (a) => a) {
  return this.test(
    "unique",
    message,
    (list) => list.length === new Set(list.map(mapper)).size,
  );
});

const InvitePeople = ({ match }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [invitePeopleToJoin, mutationData] = useMutation(
    INVITE_PEOPLE_TO_JOIN_COMMUNITY,
  );
  const auth = useAuth();
  const [user, setUser] = useState(auth.user);

  useEffect(() => {
    if (typeof auth.user === "string") {
      setUser(auth.user);
    }
  }, [auth.user]);
  useEffect(() => {
    if (
      mutationData.data
      && mutationData.data.invitePeopleToJoin.status === "Success"
    ) {
      toast.success("Invitations sent successfully!");
      handleClose();
    }
    if (
      mutationData.data
      && mutationData.data.invitePeopleToJoin.status === "Fail"
    ) {
      toast.error(mutationData.data.invitePeopleToJoin.message);
      handleClose();
    }
  }, [mutationData.data]);
  const formInitialValues = {
    users: [
      {
        email: "",
        name: "",
      },
    ],
  };
  function validateUniqueEmail(value) {
    let error;
    if (typeof user === "string") {
      if (value === JSON.parse(user).email) {
        error = "You can't use own email address.";
      }
    }
    if (typeof user === "object") {
      if (value === user.email) {
        error = "You can't use own email address.";
      }
    }
    return error;
  }

  return (
    <div className="invite-people-box">
      <h3>Invite People</h3>
      <div className="invite-people-content">
        <p>Send invitation to members via email to join the community.</p>
      </div>
      <div className="create-article-btn d-block">
        <Button className="btn-bold btn-block" onClick={handleShow}>
          Send invitations
        </Button>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="invite-people-popup"
      >
        <Button variant="modal-close" onClick={handleClose}>
          <Close />
        </Button>
        <Modal.Header>
          <Modal.Title>Invite people</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-wrap">
            <Formik
              initialValues={formInitialValues}
              onSubmit={async (values, { resetForm }) => {
                await invitePeopleToJoin({
                  variables: {
                    slug: match.params.slug,
                    input: {
                      invitees: values.users,
                    },
                  },
                });
                resetForm();
              }}
              validationSchema={Yup.object({
                users: Yup.array()
                  .of(
                    Yup.object().shape({
                      email: Yup.string()
                        .required("Enter your email address.")
                        .email("Enter valid email address."),
                      name: Yup.string().required("Enter your name."),
                    }),
                  )
                  .unique(
                    "You can't use same invite email address.",
                    (a) => a.email,
                  ),
              })}
              render={({
                values, handleSubmit, setFieldValue, errors,
              }) => (
                <Form>
                  {errors
                    && errors.users
                    && typeof errors.users === "string" && (
                      <span className="validation-error-msg">
                        {errors.users}
                      </span>
                  )}
                  <FieldArray
                    name="users"
                    render={(arrayHelpers) => (
                      <>
                        {values.users
                          && values.users.length > 0
                          && values.users.map((data, index) => (
                            <div key={index} className="repeat-box">
                              <div className="repeat-left">
                                <Row className="gutter-16">
                                  <Col md="6">
                                    <div className="form-group">
                                      <label
                                        htmlFor="email"
                                        className="form-label"
                                      >
                                        Email Address
                                      </label>
                                      {console.log(
                                        "users",
                                        values.users[index],
                                      )}
                                      <Field
                                        name={`users[${index}].email`}
                                        className="form-control"
                                        validate={validateUniqueEmail}
                                        value={values.users[index].email}
                                        onChange={(e) => setFieldValue(
                                          `users[${index}].email`,
                                          e.target.value,
                                        )}
                                      />
                                      <span className="validation-error-msg">
                                        <ErrorMessage
                                          name={`users[${index}].email`}
                                        />
                                      </span>
                                    </div>
                                  </Col>
                                  <Col md="6">
                                    <div className="form-group">
                                      <label
                                        htmlFor="name"
                                        className="form-label"
                                      >
                                        User Name
                                      </label>
                                      <Field
                                        name={`users[${index}].name`}
                                        className="form-control"
                                        value={values.users[index].name}
                                        onChange={(e) => setFieldValue(
                                          `users[${index}].name`,
                                          e.target.value,
                                        )}
                                      />
                                      <span className="validation-error-msg">
                                        <ErrorMessage
                                          name={`users[${index}].name`}
                                        />
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div className="repeat-right">
                                {index !== 0 && (
                                  <Button
                                    variant="link"
                                    className="repeat-close"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <Close />
                                  </Button>
                                )}
                              </div>
                            </div>
                          ))}
                        {values.users.length < 5 && (
                        <div className="add-more-btn form-group">
                          <button
                            className="more-btn"
                            type="button"
                            onClick={() => arrayHelpers.push({ email: "", name: "" })}
                          >
                            <AddIcon />
                            {" "}
                            Add More
                          </button>
                        </div>
                        )}
                      </>
                    )}
                  />
                  <div className="cta-group text-right">
                    <Button
                      variant="outline-primary"
                      className="btn-bold"
                      onClick={handleClose}
                    >
                      CANCEL
                    </Button>
                    <Button
                      variant="primary"
                      className="btn-bold"
                      type="submit"
                      disabled={mutationData.loading}
                    >
                      SEND
                      {mutationData.loading && (
                      <span className="ml-2">
                        <ClipLoader
                          color="#ffffff"
                          loading={true}
                          size={15}
                        />
                      </span>
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default InvitePeople;