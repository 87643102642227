import React from "react";
import { Link } from "react-router-dom";
import "../../../../public/style/_form.scss";
import "../../../../public/style/_privacy-policy.scss";
import "./_blog-categories.scss";
import { useQuery } from "@apollo/client";
import { ClipLoader } from "react-spinners";
import { GET_BLOG_CATEGORIES } from "../../../../queries/newsCosmosQueries";

const BlogCategories = () => {
  const { loading, error, data } = useQuery(GET_BLOG_CATEGORIES);

  if (error) {
    return (
      <div className="privacy-page-wrap">
        <div className="container container-sm">
          <div className="privacy-page-column single-content">
            <div className="right about-page-content">
              {error.networkError ? (
                <p>
                  Error : No internet connection, please refresh the page or
                  check internet connection !!
                </p>
              ) : (
                <p>
                  Error!
                  {error.message}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="categories-box">
      <h3>Categories</h3>
      {loading && (
        <div className="d-flex justify-content-center pt-2">
          Loading...
          <span className="ml-2">
            <ClipLoader color="#e76c35" loading={true} size={15} />
          </span>
        </div>
      )}
      <ul>
        {!loading && data && data.getCategories && data.getCategories.categories
          ? data.getCategories.categories.map((category) => (
              <li key={category.id}>
                <Link to={`/category/${category.slug}/blogs`}>
                  {category.title}
                </Link>
              </li>
            ))
          : null}
        {!loading &&
          data &&
          data.getCategories &&
          data.getCategories.categories.length === 0 && (
            <h6>Nothing to see here — yet.</h6>
          )}
      </ul>
    </div>
  );
};

export default BlogCategories;
