import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import ValidationErrorInput from "../UIComponents/ValidationErrorInput";
import { GET_REPORTED_NEWS_ORGANIZATION_COMMENT_IDS } from "../../queries/newsCosmosQueries";
import { REPORT_NEWS_ORGANIZATION_COMMENT } from "../../mutations/newsCosmosMutations";
import Close from "../../public/images/close.svg";
import FlagIcon from "../../public/images/flag.svg";

const Report = ({ commentId }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const isUser = localStorage.getItem("user");

  const [
    reportNewsOrganizationComment,
    newsOrganizationMutationData,
  ] = useMutation(REPORT_NEWS_ORGANIZATION_COMMENT);

  const { loading, data, refetch } = useQuery(
    GET_REPORTED_NEWS_ORGANIZATION_COMMENT_IDS,
    {
      skip: !isUser,
    },
  );
  useEffect(() => {
    if (
      newsOrganizationMutationData.data
      && newsOrganizationMutationData.data.reportNewsOrganizationComment
        .status === "Success"
    ) {
      toast.success(
        newsOrganizationMutationData.data.reportNewsOrganizationComment
          .message,
      );
      refetch();
      handleClose();
    }
    if (
      newsOrganizationMutationData.data
      && newsOrganizationMutationData.data.reportNewsOrganizationComment
        .status === "Fail"
    ) {
      toast.error(
        newsOrganizationMutationData.data.reportNewsOrganizationComment
          .message,
      );
      handleClose();
    }
  }, [newsOrganizationMutationData.data]);

  if (isUser) {
    if (loading) {
      return null;
    }
    return (
      <>
        <div className="changes-password">
          <button
            type="button"
            className={`d-flex align-items-center border-0 bg-transparent text-reset ${
              isUser
                ? data
                  && data.getReportedNewsOrganizationCommentIds
                    .reported_news_organization_comment_ids
                  && data
                    .getReportedNewsOrganizationCommentIds
                    .reported_news_organization_comment_ids.includes(
                      commentId,
                    )
                  && "active"
                : ""
            }`}
            onClick={handleShow}
          >
            <span className="icon">
              <FlagIcon />
            </span>
            {" "}
            <span className="txt">Report</span>
          </button>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          centered
          className="change-password-popup"
        >
          <Button variant="modal-close" onClick={handleClose}>
            <Close />
          </Button>
          <Modal.Header>
            <Modal.Title>REPORT</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                description: "",
              }}
              onSubmit={async (values) => {
                await reportNewsOrganizationComment({
                  variables: {
                    description: values.description,
                    news_organization_comment_id: commentId,
                  },
                });
              }}
              validate={(values) => {
                const errors = {};

                if (!values.description) {
                  errors.description = "Please enter description.";
                }

                return errors;
              }}
            >
              {({
                values, errors, touched, handleSubmit, setFieldValue,
              }) => (
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  <div className="form-wrap">
                    <div className="form-group">
                      <label htmlFor="old-password" className="form-label">
                        Description:
                      </label>
                      <div className="password-group">
                        <textarea
                          className="form-control"
                          values={values.description}
                          onChange={(e) => setFieldValue("description", e.target.value)}
                          rows="5"
                        />
                        {errors.description && touched.description && (
                        <ValidationErrorInput error={errors.description} />
                        )}
                      </div>
                    </div>

                    <div className="cta-group">
                      <Button
                        variant="outline-primary"
                        className="btn-bold"
                        onClick={handleClose}
                      >
                        CANCEL
                      </Button>
                      <Button
                        variant="primary"
                        className="btn-bold"
                        type="submit"
                        disabled={newsOrganizationMutationData.loading}
                      >
                        SUBMIT
                        {newsOrganizationMutationData.loading && (
                        <span className="ml-2">
                          <ClipLoader
                            color="#ffffff"
                            loading={true}
                            size={15}
                          />
                        </span>
                        )}
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  return (
    <>
      <div className="changes-password">
        <Link
          to="/login"
          className="d-flex align-items-center border-0 bg-transparent text-reset"
        >
          <span className="icon">
            <FlagIcon />
          </span>
          {" "}
          <span className="txt">Report</span>
        </Link>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="change-password-popup"
      >
        <Button variant="modal-close" onClick={handleClose}>
          <Close />
        </Button>
        <Modal.Header>
          <Modal.Title>REPORT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              description: "",
            }}
            onSubmit={async (values) => {
              await reportNewsOrganizationComment({
                variables: {
                  description: values.description,
                  current_issue_comment_id: commentId,
                },
              });
            }}
            validate={(values) => {
              const errors = {};

              if (!values.description) {
                errors.description = "Please enter description.";
              }

              return errors;
            }}
          >
            {({
              values, errors, touched, handleSubmit, setFieldValue,
            }) => (
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <div className="form-wrap">
                  <div className="form-group">
                    <label htmlFor="old-password" className="form-label">
                      Description:
                    </label>
                    <div className="password-group">
                      <textarea
                        className="form-control"
                        values={values.description}
                        onChange={(e) => setFieldValue("description", e.target.value)}
                        rows="5"
                      />
                      {errors.description && touched.description && (
                      <ValidationErrorInput error={errors.description} />
                      )}
                    </div>
                  </div>

                  <div className="cta-group">
                    <Button
                      variant="outline-primary"
                      className="btn-bold"
                      onClick={handleClose}
                    >
                      CANCEL
                    </Button>
                    <Button
                      variant="primary"
                      className="btn-bold"
                      type="submit"
                      disabled={newsOrganizationMutationData.loading}
                    >
                      SUBMIT
                      {newsOrganizationMutationData.loading && (
                      <span className="ml-2">
                        <ClipLoader
                          color="#ffffff"
                          loading={true}
                          size={15}
                        />
                      </span>
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Report;