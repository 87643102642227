import React from "react";
import { Link } from "react-router-dom";
import UserIcon from "../../../../public/images/add-user-icon.svg";
import "./SignUp.scss";

const SignUp = () => (
  <div className="signupcta-box">
    <div className="icon">
      <UserIcon />
    </div>
    <p>
      Sign up to get best experience of news from your local community and your
      personal news feed
    </p>
    <Link to="/signup" className="btn btn-white rounded">
      Sign up
    </Link>
  </div>
);

export default SignUp;