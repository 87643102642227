@import "../../../public/style/variables";

.rating-box{
	background: $color_athens_gray;
	border: 1px solid $color_mercury2;
	@include border-radius(5px);
	padding: 10px 8px;
	display: flex;
	flex-wrap: wrap;
	.start-preview{
		width: calc(100% - 100px);
		ul{
			display: flex;
			flex-wrap: wrap;
			li{
				display: flex;
				align-items: center;
				font-size: 12px;
				line-height: 15px;
				margin-right: 24px;
				&:last-child{
					margin-right: 0;
				}
				.star-info{
					display: flex;
					align-items: center;
					padding-left: 5px;
					.no{
						font-size: 14px;
						font-weight: 600;
						color: $secondary_color;
						margin-right: 3px;
					}
					.icon{
						svg{
							display: block;
						}
					}
				}
			}
		}
	}
	.add-rating{
		width: 100px;
		text-align: right;
		.rating-btn{
			border: 0;
			background: transparent;
			font-size: 12px;
			line-height: 13px;
			color: $gray_color;
			display: flex;
			align-items: center;
			margin-left: auto;
			svg{
				display: block;
				margin-right: 8px;
			}
		}
	}
}

.rating-popup{
	.modal-dialog{
		max-width: 400px;
		@include breakpoint( max , 400){
			width: 90%;
			margin: 2em auto;
		}
	}
	.modal-body{
		padding: 12px 16px 24px;
	}
}

.rating-type-list{
	padding-bottom: 24px;
	ul{
		li{
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid $color_concrete;
			padding-bottom: 16px;
			margin-bottom: 12px;
			&:last-child{
				margin-bottom: 0;
			}
			.rating-label{
				font-size: 16px;
				line-height: 19px;
				font-weight: 600;
				color: $color_gray_dark;
			}
		}
	}
}