import { toast } from "react-toastify";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_TOPICS } from "../../../queries/newsCosmosQueries";
import { useAuth } from "../../../utils/useAuth";
import FooterSmall from "../FooterSmall/FooterSmall";
import MenuIcon from "../../../public/images/menuicon.svg";
import MenuClose from "../../../public/images/menuclose.svg";
import Logo from "../../../public/images/logo.svg";
import Arrow from "../../../public/images/down-arrow-blue.svg";
import Home from "../../../public/images/menu-icon/home.svg";
import Topic from "../../../public/images/menu-icon/topic.svg";
import Community from "../../../public/images/menu-icon/community.svg";
import Save from "../../../public/images/menu-icon/save.svg";
import Followings from "../../../public/images/menu-icon/followings.svg";
import Settings from "../../../public/images/menu-icon/settings.svg";
import About from "../../../public/images/menu-icon/about.svg";
import Help from "../../../public/images/menu-icon/help.svg";
import SignOut from "../../../public/images/menu-icon/sign-out.svg";
import SignIn from "../../../public/images/menu-icon/sign-in.svg";
import SignUp from "../../../public/images/menu-icon/sign-up.svg";
import BackIcon from "../../../public/images/menu-icon/left-arrow.svg";
import Issue from "../../../public/images/Issue.svg";
import "./_mobile-menu.scss";

const MobileMenu = () => {
  const auth = useAuth();
  const [show, setShow] = useState(false);
  const [submenu, subShow] = useState(false);
  const [aboutMenu, AboutMenuShow] = useState(false);
  const { data } = useQuery(GET_TOPICS);
  return (
    <div className="mobile-menu">
      <div className="menu-btn">
        <button type="button" onClick={() => setShow(!show)}>
          <MenuIcon />
        </button>
      </div>

      <div className={show ? "mobile-menu-wrap show-menu" : "mobile-menu-wrap"}>
        <div className="mobile-menu-header">
          <div className="mobile-logo" onClick={() => setShow(false)}>
            <Link to="/">
              <span className="logo-icon">
                <Logo />
              </span>
              <span className="logo-text">News Cosmos</span>
            </Link>
          </div>
          <div className="menu-btn">
            <button type="button" onClick={() => setShow(!show)}>
              <MenuClose />
            </button>
          </div>
        </div>
        <div className="m-top">
          {auth.user && (
            <div className="user-name-info">
              <div className="photo">
                <img
                  src={
                    typeof auth.user === "object"
                      ? auth.user.photo
                      : JSON.parse(auth.user).photo
                  }
                  alt="avatar"
                />
              </div>
              <div className="name">
                {typeof auth.user === "object"
                  ? auth.user.username
                  : JSON.parse(auth.user).username}
              </div>
            </div>
          )}
          <div className="mobile-menu-list">
            <ul>
              <li onClick={() => setShow(!show)}>
                <Link to="/" className="menulink">
                  <span className="icon">
                    <Home />
                  </span>
                  <span className="txt">Home</span>
                </Link>
              </li>
              <li>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => subShow(!submenu)}
                  className="menulink"
                >
                  <span className="icon">
                    <Topic />
                  </span>
                  <span className="txt">Topics</span>
                  <span className="next-arrow">
                    <Arrow />
                  </span>
                </div>
              </li>
              {auth.user && (
                <>
                  <li onClick={() => setShow(!show)}>
                    <Link to="/current-issues" className="menulink">
                      <span className="icon">
                        <Issue />
                      </span>
                      <span className="txt">Current Issues</span>
                    </Link>
                  </li>
                  <li onClick={() => setShow(!show)}>
                    <Link
                      to={`/user/${
                        typeof auth.user === "object"
                          ? auth.user.slug
                          : JSON.parse(auth.user).username
                      }/joined-communities`}
                      className="menulink"
                    >
                      <span className="icon">
                        <Community />
                      </span>
                      <span className="txt">Joined Communities</span>
                    </Link>
                  </li>
                  <li onClick={() => setShow(!show)}>
                    <Link
                      to={`/user/${
                        typeof auth.user === "object"
                          ? auth.user.slug
                          : JSON.parse(auth.user).slug
                      }/saved-posts`}
                      className="menulink"
                    >
                      <span className="icon">
                        <Save />
                      </span>
                      <span className="txt">Saved Posts</span>
                    </Link>
                  </li>
                  <li onClick={() => setShow(!show)}>
                    <Link
                      to={`/user/${
                        typeof auth.user === "object"
                          ? auth.user.slug
                          : JSON.parse(auth.user).slug
                      }/following`}
                      className="menulink"
                    >
                      <span className="icon">
                        <Followings />
                      </span>
                      <span className="txt">Followings</span>
                    </Link>
                  </li>
                  <li onClick={() => setShow(!show)}>
                    <Link
                      to={`/user/${
                        typeof auth.user === "object"
                          ? auth.user.slug
                          : JSON.parse(auth.user).slug
                      }/settings`}
                      className="menulink"
                    >
                      <span className="icon">
                        <Settings />
                      </span>
                      <span className="txt">Settings</span>
                    </Link>
                  </li>
                </>
              )}
              <li>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setShow(false);
                    AboutMenuShow(!aboutMenu);
                  }}
                  className="menulink"
                >
                  <span className="icon">
                    <About />
                  </span>
                  <span className="txt">About News Cosmos</span>
                  <span className="next-arrow">
                    <Arrow />
                  </span>
                </div>
              </li>
              <li
                onClick={() => {
                  setShow(false);
                  AboutMenuShow(false);
                }}
              >
                <Link to="/help" className="menulink">
                  <span className="icon">
                    <Help />
                  </span>
                  <span className="txt">Help</span>
                </Link>
              </li>
              {auth.user ? (
                <>
                  <li
                    onClick={() => {
                      auth.signOut();
                      toast.success("You are successfully logged out!");
                      setShow(!show);
                    }}
                  >
                    <Link to="/" className="menulink">
                      <span className="icon">
                        <SignOut />
                      </span>
                      <span className="txt">Logout</span>
                    </Link>
                  </li>
                  {/* <Logout auth={auth} /> */}
                </>
              ) : (
                <>
                  <li onClick={() => setShow(!show)}>
                    <Link to="/login" className="menulink">
                      <span className="icon">
                        <SignIn />
                      </span>
                      <span className="txt">Login</span>
                    </Link>
                  </li>
                  <li onClick={() => setShow(!show)}>
                    <Link to="/signup" className="menulink">
                      <span className="icon">
                        <SignUp />
                      </span>
                      <span className="txt">Sign up</span>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
        <div className="m-footer">
          {auth.user && (
            <div>
              <Link
                to="/create-post"
                onClick={() => setShow(!show)}
                className="btn btn-bold btn-block d-flex align-items-center justify-content-center btn-secondary"
              >
                Post a new article
              </Link>
              <Link
                to="/create-community"
                onClick={() => setShow(!show)}
                className="btn btn-secondary btn-bold btn-block"
              >
                create a community
              </Link>
            </div>
          )}
          <FooterSmall />
        </div>
      </div>

      <div
        className={
          submenu ? "mobile-menu-wrap show-sub-menu" : "mobile-menu-wrap"
        }
      >
        <div className="communities-menu-header">
          <div className="back-box">
            <div
              className="back"
              onClick={() => subShow(!submenu)}
              role="button"
              tabIndex={0}
            >
              <BackIcon /> Topics
            </div>
          </div>
        </div>
        <div className="search-communities-list">
          <ul>
            {data &&
              data.topics &&
              data.topics.map((topic) => (
                <li
                  key={Math.random()}
                  onClick={() => {
                    subShow(!submenu);
                    setShow(!show);
                  }}
                >
                  <Link to={`/topic/${topic.slug}`}>
                    <div className="txt">{topic.title}</div>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
        <div className="m-footer">
          <FooterSmall />
        </div>
      </div>

      <div
        className={
          aboutMenu ? "mobile-menu-wrap show-sub-menu" : "mobile-menu-wrap"
        }
      >
        <div className="communities-menu-header">
          <div className="back-box">
            <div
              className="back"
              onClick={() => AboutMenuShow(!aboutMenu)}
              role="button"
              tabIndex="0"
            >
              <BackIcon /> About News Cosmos
            </div>
          </div>
        </div>
        <div className="menu-quick-links">
          <ul>
            <li
              onClick={() => {
                setShow(false);
                subShow(false);
                AboutMenuShow(false);
              }}
            >
              <Link to="/about-us">About</Link>
            </li>
            <li
              onClick={() => {
                setShow(false);
                subShow(false);
                AboutMenuShow(false);
              }}
            >
              <Link to="/contact-us">Contact Us</Link>
            </li>
            <li
              onClick={() => {
                setShow(false);
                subShow(false);
                AboutMenuShow(false);
              }}
            >
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li
              onClick={() => {
                setShow(false);
                subShow(false);
                AboutMenuShow(false);
              }}
            >
              <Link to="/terms-of-use">Terms of Use</Link>
            </li>
            <li
              onClick={() => {
                setShow(false);
                subShow(false);
                AboutMenuShow(false);
              }}
            >
              <Link to="/career">Career</Link>
            </li>
            <li
              onClick={() => {
                setShow(false);
                subShow(false);
                AboutMenuShow(false);
              }}
            >
              <Link to="/blogs">Blog</Link>
            </li>
            <li
              onClick={() => {
                setShow(false);
                subShow(false);
                AboutMenuShow(false);
              }}
            >
              <Link to="/best-practices">Best Practices</Link>
            </li>
            <li
              onClick={() => {
                setShow(false);
                subShow(false);
                AboutMenuShow(false);
              }}
            >
              <Link to="/cookie-policy">Cookie Policy</Link>
            </li>
            <li
              onClick={() => {
                setShow(false);
                subShow(false);
                AboutMenuShow(false);
              }}
            >
              <Link to="/help">Help</Link>
            </li>
          </ul>
        </div>
        <div className="m-footer">
          <FooterSmall />
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
