import React, { useState } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useQuery } from "@apollo/client";
import { ClipLoader } from "react-spinners";
import SearchIcon from "../../../../public/images/magnifying-glass.svg";
import "../../../../public/style/_form.scss";
import "./_searchblog.scss";
import {
  GET_BLOGS,
  GET_RECENT_BLOGS,
} from "../../../../queries/newsCosmosQueries";

const SearchBlog = () => {
  const [searchValue, setSearchValue] = useState("");
  const { loading, error, data } = useQuery(GET_RECENT_BLOGS);
  const {
    loading: searchLoading,
    error: searchError,
    data: searchData,
  } = useQuery(GET_BLOGS, {
    variables: {
      limit: 4,
      current_page: 1,
      query: searchValue,
      category_slug: "",
    },
  });

  if (error || searchError) {
    console.log("error", error);
  }

  return (
    <div className="search-blog-box">
      <div className="search-area">
        <h3>Search Blog</h3>
        <div className="search form-wrap">
          <Form.Group>
            <Form.Control
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <button className="search-action" type="button">
              <SearchIcon />
            </button>
          </Form.Group>
          {searchValue.length > 1 && (
            <div className="search-dd">
              <div className="search-dd-list">
                {searchLoading ? (
                  <div className="d-flex justify-content-center pt-2 pb-2">
                    Loading...
                    <span className="ml-2">
                      <ClipLoader color="#e76c35" loading={true} size={15} />
                    </span>
                  </div>
                ) : (
                  <ul>
                    {searchData &&
                    searchData.getBlogs &&
                    searchData.getBlogs.blogs.length > 0 ? (
                      searchData.getBlogs.blogs.map((blog) => (
                        <li key={blog.id}>
                          <div className="avatar">
                            <img src={blog.photo} alt="" />
                          </div>
                          <div className="info">
                            <div className="search__title">
                              <Link to={`/blog-detail/${blog.slug}`}>
                                {blog.title}
                              </Link>
                            </div>
                            <div className="search__sub-title">
                              {blog.author}
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <p className="text-center"> Sorry, the search you requested could not be found. </p>
                    )}
                  </ul>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="popular-posts">
        <h3>Recent Posts</h3>
        {loading && (
          <div className="d-flex justify-content-center pt-2">
            Loading...
            <span className="ml-2">
              <ClipLoader color="#e76c35" loading={true} size={15} />
            </span>
          </div>
        )}
        <ul>
          {data && data.getRecentBlogs && data.getRecentBlogs.blogs
            ? data.getRecentBlogs.blogs.map((blog) => (
                <li key={blog.title}>
                  <div className="post-img">
                    <img src={blog.photo} alt="" />
                  </div>
                  <div className="post-title">
                    <Link to={`/blog-detail/${blog.slug}`}>{blog.title}</Link>
                  </div>
                </li>
              ))
            : null}
          {!loading &&
            data &&
            data.getRecentBlogs &&
            data.getRecentBlogs.blogs.length === 0 && <h6>Nothing to see here — yet.</h6>}
        </ul>
      </div>
    </div>
  );
};

export default SearchBlog;
