@import "../../../../public/style/variables";

.signupcta-box {
    background: $secondary_color;
    color: $color_white;
    padding: 32px 16px;
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    @include border-radius(5px);
    margin-bottom: 8px;

    .icon {
        padding-bottom: 8px;
    }

    p {
        font-weight: 600;
    }
}