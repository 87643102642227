import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import SidebarAds from "../SidebarAds/SidebarAds";
import "./sidebar-footer.scss";

const SidebarFooter = ({ isTitleAdd }) => {
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div
      className={
        isTitleAdd ? "sidebar-footer detail-title-show" : "sidebar-footer"
      }
    >
      <SidebarAds />
      <div className="footer-box">
        <div className="footer-links">
          <ul>
            <li>
              <Link to="/about-us">About</Link>
            </li>
            <li>
              <Link to="/career">Career</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
            <li>
              <Link to="/blogs">Blog</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/best-practices">Best Practices</Link>
            </li>
            <li>
              <Link to="/terms-of-use">Terms of Use</Link>
            </li>
            <li>
              <Link to="/cookie-policy">Cookie Policy</Link>
            </li>
          </ul>
        </div>
        <div className="copyright">NewsCosmos © 2021. All rights reserved</div>
      </div>
      <div className="cta">
        <Button
          variant="secondary"
          className="btn-bold btn-block"
          onClick={() => scrollUp()}
        >
          Back to top
        </Button>
      </div>
    </div>
  );
};

export default SidebarFooter;