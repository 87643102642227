@import "../variables";

.community-list {
  > ul {
    padding-bottom: 16px;

    > li {
      padding: 16px 8px 10px;
      margin-bottom: 8px;
      display: flex;
      flex-wrap: wrap;
      background: $color_white;
      @include border-radius(5px);

      @include breakpoint(max, 767) {
        padding: 8px;
      }

      .left {
        width: 65px;

        @include breakpoint(max, 767) {
          width: 30px;
        }

        .img {
          img {
            max-width: 65px;
            min-height: 65px;
            object-fit: cover;
            @include border-radius(100%);

            @include breakpoint(max, 767) {
              max-width: 30px;
              min-height: 30px;
            }
          }
        }
      }

      .right {
        width: calc(100% - 65px);
        padding-left: 7px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include breakpoint(max, 767) {
          width: calc(100% - 30px);
        }
      }

      .select-community-img-info {
        width: calc(100% - 217px);
        padding-right: 26px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include breakpoint(max, 991) {
          width: 100%;
          padding-bottom: 8px;
          order: 1;
        }

        .content {
          width: 100%;

          @include breakpoint(max, 991) {
            width: 100%;
          }

          h3 {
            font-size: 18px;
            line-height: 20px;
            font-weight: 600;
            color: $color_gray_dark;
            padding-bottom: 4px;
            a {
              font-size: 18px;
              line-height: 20px;
              font-weight: 600;
              color: $color_gray_dark;
              padding-bottom: 4px;
            }
            @include breakpoint(max, 767) {
              padding-bottom: 0px;
              font-size: 16px;
            }
          }

          p {
            margin-bottom: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            @include breakpoint(max, 767) {
              display: none;
            }
          }
        }
      }

      .member {
        font-size: 14px;
        line-height: 1.1;
        display: flex;
        flex-wrap: wrap;

        span {
          margin-right: 5px;
        }

        .since {
          margin-left: 8px;
          padding-left: 8px;
          border-left: 1px solid rgba($gray_color, 0.5);

          @include breakpoint(max, 991) {
            width: 100%;
            margin-left: 0;
            padding-left: 0;
            padding-top: 3px;
            border-left: 0;
          }
        }
      }

      .community-type {
        width: 121px;
        display: flex;
        align-items: center;

        @include breakpoint(max, 991) {
          width: 60%;
          order: 2;
        }

        .short-type {
          margin-right: 8px;
        }

        .type {
          font-size: 12px;
          line-height: 1.1;
          text-transform: capitalize;
          span {
            display: block;
            font-size: 16px;
            color: $color_gray_dark;
          }
        }
      }

      .join {
        width: 96px;

        @include breakpoint(max, 991) {
          width: 100%;
          padding-top: 8px;
          order: 4;
        }
      }

      .txt {
        width: 100%;
        font-size: 14px;
        line-height: 18px;
        padding-top: 12px;

        @include breakpoint(max, 991) {
          order: 3;
        }
      }
      &.block-user {
        background: rgba($color_mercury2, 0.5);

        .left {
          .img {
            img {
              filter: grayscale(100%);
            }
          }
        }

        .txt {
          display: none;
        }
      }
    }
  }

  &.following-list {
    > ul {
      > li {
        .select-community-img-info {
          width: calc(100% - 150px);
          padding-right: 10px;

          @include breakpoint(max, 991) {
            width: 100%;
          }

          .content {
            width: 100%;
          }
        }

        .join {
          width: 150px;
          text-align: right;

          @include breakpoint(max, 991) {
            width: 100%;

            .btn {
              display: block;
              width: 100%;
            }
          }
        }

        .txt {
          @include breakpoint(max, 991) {
            padding-top: 0;
          }
        }
      }
    }

    &.follower-list {
      > ul {
        > li {
          .select-community-img-info {
            width: calc(100% - 180px);

            @include breakpoint(max, 991) {
              width: 100%;
            }
          }

          .join {
            width: 180px;
            text-align: right;

            .btn {
              &.block-btn {
                padding: 5px 0;
                padding-left: 17px;
                height: 30px;

                svg {
                  display: block;
                }
              }

              &.btn-icon + .btn-icon {
                margin-left: 8px;
              }
            }

            @include breakpoint(max, 991) {
              width: 100%;
              display: flex;

              .btn {
                display: block;
                width: calc(100% - 41px);
                flex-grow: 0;

                &.block-btn {
                  width: 41px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.hide-type-name {
    > ul {
      > li {
        .select-community-img-info {
          width: calc(100% - 136px);
          @include breakpoint(max, 767) {
            width: calc(100% - 40px);
          }
        }
        .community-type {
          width: 40px;
        }
      }
    }
  }
  &.org-list {
    > ul {
      > li {
        .select-community-img-info {
          width: 100%;
        }
        .txt {
          padding-top: 0;
        }
      }
    }
  }
}
