@import "./variables";

.privacy-page-wrap{
	padding: 40px 0;
	min-height: calc(100vh - 203px);
	.privacy-page-column{
		display: flex;
		flex-wrap: wrap;
		.left{
			width: 250px;
			position: relative;
			@include breakpoint( max , 767){
				width: 100%;
				padding-bottom: 20px;
			}
			.sidebar-quick-links{
				position: sticky;
				top: 60px;
				background: $color_firefly;
				padding: 15px;
				@include border-radius(5px);
				ul{
					li{
						padding-bottom: 10px;
						&:last-child{
							margin-bottom: 0;
						}
						a{
							font-size: 14px;
							line-height: 20px;
							color: $color_white;
						}
					}
				}
			}
		}
		.right{
			padding-left: 25px;
			width: calc(100% - 250px);
			@include breakpoint( max , 767){
				width: 100%;
				padding-left: 0;
			}
		}
		&.single-content{
			max-width: 576px;
			margin: 0 auto;
			.right{
				width: 100%;
				padding-left: 0;
			}
		}
	}
	.content{
		font-size: 14px;
		line-height: 19px;
		word-wrap: break-word;
		color: #474747;
		.page-title{
			color: $secondary_color;
			font-size: 30px;
			line-height: 40px;
			font-weight: 700;
			text-transform: capitalize;
			padding-bottom: 5px;
			padding-top: 0;
		}
		h2{
			font-size: 24px;
			line-height: 29px;
			font-weight: 600;
			padding-bottom: 16px;
			padding-top: 12px;
			color: $heading_color;
		}
		p{
			a{
				color: $primary_color;
				text-decoration: underline;
				word-break: break-all;
				display: inline-block;
			}
			strong{
				font-weight: 600;
			}
		}
		ol{
			counter-reset: my-awesome-counter;
			padding-bottom: 12px;
			li{
				padding-left: 18px;
				margin-bottom: 10px;
				position: relative;
				counter-increment: my-awesome-counter;
				&:before{
					content: counter(my-awesome-counter) ".";
					position: absolute;
					left: 0;
					top: 0;
				}
				a{
					color: $primary_color;
					text-decoration: underline;
					word-break: break-all;
				}
			}
		}
		ul{
			padding-bottom: 12px;
			padding-left: 24px;
			li{
				position: relative;
				padding-left: 18px;
				margin-bottom: 10px;
				&:before{
					content: "";
					position: absolute;
					left: 0;
					top: 6px;
					width: 6px;
					height: 6px;
					background: #474747;
					@include border-radius(10px);
				}
				a{
					color: $primary_color;
					text-decoration: underline;
					word-break: break-all;
				}
			}
		}
	}
	.table{
		width: 625px;
		th{
			font-weight: 600;
			color: $heading_color;
			// white-space: nowrap;
		}
		th, td{
			background: $color_mercury2;
			border-color: rgba($gray_color, .3);
			padding: 16px;
		}
	}
}

.about-title-logo{
	text-align: center;
	margin-bottom: 30px;
	padding-bottom: 25px;
	position: relative;
	&:after{
		content: "";
		width: 200px;
		height: 1px;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0 auto;
		background: #CBCBCB;
	}
	.logo-icon{
		padding-bottom: 16px;
	}
}

.contact-form-box{
	background: $color_white;
	padding: 27px 69px 30px;
	@include border-radius(4px);
	@include breakpoint( max , 767){
		padding: 30px 15px;
	}
	h5{
		font-size: 18px;
		font-weight: 700;
		color: $header_bg_color;
		padding-bottom: 14px;
		text-align: center;
	}
	p{
		max-width: 374px;
		margin: 0 auto 20px;
		text-align: center;
		color: #5E5E5E;
	}
}

.page-not-found-block{
	text-align: center;
	background: $color_white;
	padding: 27px 20px 30px;
	@include border-radius(4px);
	.page-title{
		padding-top: 0;
		padding-bottom: 50px;
	}
}