import React from "react";
import { Route, Redirect } from "react-router-dom";
import { toast } from "react-toastify";

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const currentUser = JSON.parse(localStorage.getItem("user"));
      if (!currentUser) {
        // not logged in so redirect to login page with the return url
        toast.error("Error! Please do login to continue.");
        return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
      }

      // check if route is restricted by role
      if (roles && roles.indexOf(currentUser.role) === -1) {
        toast.info("Info! You are not authorized to access this location.");
        return <Redirect to={{ pathname: "/" }} />;
      }

      // authorised so return component
      return <Component {...props} />;
    }}
  />

);

export default PrivateRoute;