import React from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

export default function authCheck(WrappedComponent) {
  // eslint-disable-next-line react/prefer-stateless-function
  return class extends React.Component {
    /* eslint-disable */
    constructor(props) {
      super(props);
    }

    render() {
      if (localStorage.getItem("user")) {
        toast.info("You are already logged in.");
        return <Redirect to="/" />;
      }
      return <WrappedComponent {...this.props} />;
    }
  };
}