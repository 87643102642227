@import "../../../../public/style/variables";

.categories-box{
	background: $color_white;
	margin-bottom: 16px;
	padding: 18px 20px;
	@include border-radius(5px);
	h3{
		font-size: 20px;
		font-weight: 700;
		color: $heading_color;
		padding-bottom: 16px;
	}
	ul{
		li{
			padding-bottom: 6px;
			border-bottom: 1px solid $color_mercury2;
			margin-bottom: 16px;
			&:last-child{
				border-bottom: 0;
				margin-bottom: 0;
				padding-bottom: 0;
			}
			a{
				font-size: 14px;
				line-height: 20px;
				color: #575757;
				font-weight: 500;
				display: inline-block;
				@include transition(all 0.3s ease-in-out);
				padding-left: 18px;
				position: relative;
				&:before{
					content: "";
					position: absolute;
					left: 0;
					top: 5px;
					width: 6px;
					height: 9px;
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.213' height='8.751' viewBox='0 0 5.213 8.751'%3E%3Cpath id='Path_17385' data-name='Path 17385' d='M5.135-5.018a.328.328,0,0,0,0-.465L1.121-9.529a.328.328,0,0,0-.465,0l-.541.541a.328.328,0,0,0,0,.465L3.355-5.25.115-1.977a.328.328,0,0,0,0,.465l.541.541a.328.328,0,0,0,.465,0Z' transform='translate(-0.018 9.626)' fill='%23575757'/%3E%3C/svg%3E%0A");
					background-repeat: no-repeat;
				}
				&:hover{
					color: $primary_color;
				}
			}
		}
	}
}