@import "../../../../public/style/variables";

.trending-topics-wrap {
    padding-top: 8px;
    margin-bottom: 8px;

    .trending-title {
        font-size: 20px;
        color: $primary_color;
        font-weight: 700;
        text-transform: capitalize;
        margin-bottom: 8px;
    }

    .trending-topics-list {
        .trending-topic-box {
            &+.trending-topic-box {
                margin-top: 8px;
            }
        }
    }
}

.trending-topic-box {
    a {
        display: block;

        .img {
            img {
                @include border-radius(5px 5px 0 0);
            }
        }

        .topic-title {
            background: $color_astral;
            font-size: 16px;
            line-height: 18px;
            color: $color_white;
            padding: 14px 16px;
            @include border-radius(0 0 5px 5px);
        }
    }
}