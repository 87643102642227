@import "../../../../public/style/variables";

.article-card{
	background: $color_white;
	@include border-radius(5px);
	margin-bottom: 8px;
	.article-card-header{
		padding: 8px 8px 13px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.article-user-info{
			display: flex;
			flex-wrap: wrap;
			font-size: 12px;
			color: $gray_color;
			a{
				color: $gray_color;
				&:hover{
					color: $primary_color;
				}
			}
			> div + div{
				margin-left: 7px;
				padding-left: 7px;
				position: relative;
				&:before{
					content: "";
					width: 1px;
					height: 10px;
					position: absolute;
					left: 0;
					top: 50%;
					margin-top: -5px;
					background: $gray_color;
					opacity: .5;
				}
			}
			.communities-name{
				img{
					flex-shrink: 0;
					max-width: 22px;
					@include border-radius(100%);
					margin-right: 8px;
				}
				span{
					font-size: 12px;
					color: $heading_color;
					font-weight: 600;
				}
				a{
					color: $heading_color;
				}
			}
		}
		.article-type-rating{
			flex-shrink: 0;
			margin-left: 8px;
			.badge{
				width: 30px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 2px;
				font-size: 16px;
				line-height: 1;
				font-weight: 600;
				text-transform: uppercase;
				color: $color_white;
				background-color: $mercury;
				@include border-radius(3px);
				&.private{
					background: $color_gigas;
				}
				&.reserve{
					background: $header_bg_color;
				}
				&.rating-good{
					background: $color_green;
				}
				&.rating-average{
					background: $color_yellow;
				}
				&.rating-bad{
					background: $color_red;
				}
			}
		}
	}
	.article-card-body{
		padding: 0 8px 8px;
		display: flex;
		flex-wrap: wrap;
		.article-img{
			width: 160px;
			@include breakpoint( max , 767){
				width: 95px;
			}
			.img{
				position: relative;
				padding-bottom: 56.25%;
				img{
					width: 100%;
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;
					object-fit: cover;
					@include border-radius(3px);
				}
				.external-link{
					position: absolute;
					right: 1px;
					bottom: 1px;
					a{
						display: block;
						width: 16px;
						height: 16px;
						padding: 3px;
						background: $primary_color;
						@include border-radius(2.5px);
						svg{
							display: block;
							path{
								fill: $color_white;
							}
						}
					}
				}
			}
		}
		.article-info{
			width: calc(100% - 160px);
			padding-left: 8px;
			@include breakpoint( max , 767){
				width: calc(100% - 95px);
			}
			h3{
				font-size: 18px;
				line-height: 20px;
				font-weight: 600;
				color: $heading_color;
				a{
					color: $heading_color;
					@include transition(all 0.3s ease-in-out);
					display: inline-block;
					&:hover{
						color: $secondary_color;
					}
				}
			}
			p{
				margin: 0;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				color: $gray_color;
				font-size: 14px;
				@include breakpoint( max , 767){
					display: none;
				}
			}
		}
	}
	.article-card-footer{
		border-top: 1px solid rgba($gray_color, .2);
		padding: 6px 8px;
		display: flex;
		justify-content: space-between;
		.article-comment{
			ul{
				display: flex;
				flex-wrap: wrap;
				li{
					font-size: 12px;
					color: $gray_color;
					a{
						color: $gray_color;
					}
					.icon{
						margin-right: 5px;
					}
					.txt{
						@include breakpoint( max , 767){
							display: none;
						}
					}
				}
			}
		}
		.article-link{
			flex-shrink: 0;
			max-width: 120px;
			text-align: right;
			a{
				display: flex;
				font-size: 12px;
				color: $primary_color;
				align-items: center;
				span{
					display: block;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					@include breakpoint( max , 767){
						display: none;
					}
				}
			}
			svg{
				flex-shrink: 0;
				margin-left: 3px;
				display: block;
			}
		}
		.post-scheduled{
			display: flex;
			flex-wrap: wrap;
			.btn{
				padding: 2px 10px 1px;
				&.btn-link{
					font-size: 12px;
					display: flex;
					align-items: center;
					color: $gray_color;
					text-decoration: none;
					.icon{
						padding-right: 5px;
						svg{
							display: block;
						}
					}
				}
				&.btn-bold{
					font-size: 12px;
				}
			}
		}
	}
	&.article-detail-card{
		.article-card-body{
			.article-img{
				@include breakpoint( max , 600){
					width: 160px;
				}
			}
			.article-info{
				@include breakpoint( max , 600){
					width: 100%;
					padding-left: 0;
					padding-top: 8px;
				}
				p{
					@include breakpoint( max , 767){
						display: block;
					}
				}
			}
		}
		&.user-article-detail-card{
			.article-card-body{
				@include breakpoint( max , 600){
					justify-content: space-between;
				}
			}
			.article-img{
				@include breakpoint( max , 600){
					order: 1;
				}
			}
			.article-info{
				width: calc(100% - 216px);
				padding-right: 8px;
				@include breakpoint( max , 600){
					width: 100%;
					padding-right: 0;
					order: 3;
				}
			}
			.article-detail-rating{
				width: 56px;
				@include breakpoint( max , 600){
					order: 2;
				}
				.article-rate-badge{
					width: 56px;
					height: 56px;
					font-size: 24px;
				}
			}
		}
	}
	&.comment-card{
		.article-card-footer{
			border-top: 0;
			padding-top: 0;
			padding-bottom: 14px;
		}
		.comment-divider{
			height: 0;
			overflow: hidden;
			border-top: 1px solid rgba($gray_color, .2);
			margin: 0 0 6px;
		}
		.comment-card-info{
			width: 100%;
			padding-top: 8px;
			.comment-on{
				display: flex;
				flex-wrap: wrap;
				font-size: 12px;
				line-height: 15px;
				padding-bottom: 6px;
				> div + div{
					margin-left: 5px;
					&:before{
						content: "|";
						display: inline-block;
						margin-right: 5px;
					}
				}
				.comment-title-name{
					a{
						color: $primary_color;
					}
					strong {
						color: $color_gray_dark;
						font-weight: 600;

						a {
							color: $color_gray_dark;
						}
					}
				}
				a{
					color: $gray_color;
					font-weight: 600;
				}
				strong{
					color: $color_gray_dark;
					font-weight: 600;
					a{
						color: $color_gray_dark;
					}
				}
			}
			.txt{
				font-size: 14px;
				line-height: 18px;
				color: $color_gray_dark;
			}
		}
	}
	&.no-header{
		.article-card-body{
			padding-top: 16px;
		}
	}
}