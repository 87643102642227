import React from "react";
import "./sidebar-ads.scss";

const SidebarAds = () => (
  <div className="sidebar-ads">
    <div className="ads-title">advertisement</div>
    <div className="ads-body-area" />
  </div>
);

export default SidebarAds;