import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { LinkContainer } from "react-router-bootstrap";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

const Logout = ({ auth, history }) => {
  const handleLogout = () => {
    auth.signOut();
    toast.success("You are successfully logged out!");
    history.push("/login");
  };
  return (
    <div className="header--user--dropdown cta">
      <Dropdown>
        <Dropdown.Toggle variant="normal-link" id="dropdown-basic">
          <div className="auth-photo">
            <img src={typeof auth.user === "object" ? auth.user.photo : JSON.parse(auth.user).photo} alt="" />
          </div>
          {typeof auth.user === "object" ? auth.user.username : JSON.parse(auth.user).username}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <LinkContainer to={`/user/${typeof auth.user === "object" ? auth.user.slug : JSON.parse(auth.user).slug}/posts`}>
            <Dropdown.Item>My Profile</Dropdown.Item>
          </LinkContainer>
          <Dropdown.Item onClick={handleLogout} eventKey="2">
            Log out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default withRouter(Logout);