import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import Accordion from "react-bootstrap/Accordion";
import ClipLoader from "react-spinners/ClipLoader";
import Card from "react-bootstrap/Card";
import { GET_COMMUNITY_RULES } from "../../../../queries/newsCosmosQueries";
import "./community-rules.scss";

const CommunityRules = ({ match }) => {
  const { loading, error, data } = useQuery(GET_COMMUNITY_RULES, {
    variables: {
      slug: match.params.slug,
    },
  });
  const [activeId, setActiveId] = useState(1);
  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  if (loading || error) {
    return (
      <div className="community-rules-box">
        <h3>Community Rules</h3>
        <div className="rules-accordion">
          <p>
            Loading...
            <span className="ml-2">
              <ClipLoader color="#e76c35" loading={true} size={15} />
            </span>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="community-rules-box">
      <h3>Community Rules</h3>
      <div className="rules-accordion">
        {data.getCommunityRules
          && data.getCommunityRules.community_rules.length > 0 && (
            <Accordion defaultActiveKey={activeId}>
              {data.getCommunityRules.community_rules.map((rule, index) => (
                <div
                  key={rule.id}
                  className={
                    activeId === index + 1 ? "card active-card" : "card"
                  }
                >
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={index + 1}
                    onClick={() => toggleActive(index + 1)}
                  >
                    <span className="no">
                      {index + 1}
                      .
                    </span>
                    {" "}
                    {rule.title}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={index + 1}>
                    <Card.Body>{rule.description}</Card.Body>
                  </Accordion.Collapse>
                </div>
              ))}
            </Accordion>
        )}
        {data
          && data.getCommunityRules
          && data.getCommunityRules.community_rules.length === 0 && (
            <div className="no-data h5">Nothing to see here — yet</div>
        )}
      </div>
    </div>
  );
};

export default CommunityRules;