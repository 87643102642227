@import "./variables";

.article-detail-page{
	position: relative;
	padding-top: 40px;
	.main{
		padding: 24px 0;
	}
	.article-title-fixed{
		position: fixed;
		left: 0;
		top: 50px;
		width: 100%;
		height: 40px;
		background: $primary_color;
		color: $color_white;
		font-size: 18px;
		font-weight: 600;
		display: flex;
		align-items: center;
		z-index: 1;
		.container{
			display: flex;
			.article-rating{
				margin-right: 10px;
				padding-right: 10px;
				border-right: 1px solid rgba($color_white, .18);
			}
			.title-name{
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
}

.article-detail-wrap{
	background: $color_white;
	padding-bottom: 20px;
	@include border-radius(5px);
	.article-card{
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		.article-card-body{
			.article-info{
				p{
					white-space: normal;
				}
			}
		}
	}
	&.org-comment-wrap{
		padding-top: 12px;
	}
	.article-filter{
		border-bottom: 1px solid rgba($gray_color,.2);
		margin-bottom: 0;
		padding: 16px 8px;
		.link-select{
			.select__menu{
				@include breakpoint( max , 767){
					left: 0 !important;
					right: inherit !important;
				}
			}
		}
	}
}

.article-comment-wrap{
	border-bottom: 1px solid rgba($gray_color,.2);
	padding: 0 8px 16px;
	.before-login-box{
		@include border-radius(5px);
		padding: 14px 18px;
		border: 1px solid $color_mercury2;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include breakpoint( max , 767){
			flex-wrap: wrap;
			justify-content: center;
		}
		p{
			margin: 0;
			font-size: 16px;
			@include breakpoint( max , 767){
				width: 100%;
				text-align: center;
				padding-bottom: 10px;
			}
		}
		.cta{
			flex-shrink: 0;
			display: flex;
			position: relative;
			padding-left: 24px;
			@include breakpoint( max , 767){
				padding-left: 0;
			}
			&:before{
				content: "";
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -10px;
				width: 1px;
				height: 20px;
				background: $gray_color;
				opacity: .2;
				@include breakpoint( max , 767){
					display: none;
				}
			}
			.btn + .btn{
				margin-left: 7px;
			}
		}
	}
}

.user-article-rate{
	border-bottom: 1px solid rgba($gray_color,.2);
	padding: 0 8px 16px;
	margin-bottom: 16px;
	> label{
		font-size: 12px;
		color: $color_gray_dark;
		display: block;
		margin-bottom: 6px;
	}
}

.uploadfile-info-wrap{
	padding: 0 8px 8px;
	.file-name-info{
		display: flex;
		align-items: center;
		.photo{
			width: 60px;
			flex-shrink: 0;
			margin-right: 10px;
			img{
				@include border-radius(5px);
			}
		}
		.name{
			font-size: 14px;
			a{
				color: $heading_color;
				font-weight: 500;
			}
		}
		&+ .file-name-info{
			margin-top: 10px;
		}
	}
}