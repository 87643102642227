import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import ClipLoader from "react-spinners/ClipLoader";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GET_FOLLOWING_COMMUNITY_IDS,
  GET_NEAR_BY_COMMUNITIES,
} from "../../../../queries/newsCosmosQueries";
import { SELECT_USER_COMMUNITIES } from "../../../../mutations/newsCosmosMutations";
import "./communitiesnearby.scss";
import { useAuth } from "../../../../utils/useAuth";
import { numFormatter } from "../../../../utils/helper";

const Listing = ({ latitude, logitude }) => {
  const [selectedId, setSelectedId] = useState("");
  const auth = useAuth();
  const communityIds = useQuery(GET_FOLLOWING_COMMUNITY_IDS, {
    skip: !auth.user,
  });
  const [selectCommunity, mutationData] = useMutation(SELECT_USER_COMMUNITIES);
  const { loading, error, data } = useQuery(GET_NEAR_BY_COMMUNITIES, {
    variables: {
      latitude,
      longitude: logitude,
    },
  });
  useEffect(() => {
    if (
      mutationData.data
      && mutationData.data.selectCommunity.status === "Fail"
    ) {
      toast.error(mutationData.data.selectCommunity.message);
      setSelectedId("");
    }
    if (
      mutationData.data
      && mutationData.data.selectCommunity.status === "Success"
    ) {
      toast.success(mutationData.data.selectCommunity.message);
      setSelectedId("");
    }
  }, [mutationData.data]);

  if (loading) {
    return (
      <div className="community-nearby-box">
        <h3>Communities Nearby</h3>
        <div className="community-nearby-list">
          <p>
            Loading...
            <span className="ml-2">
              <ClipLoader color="#e76c35" loading={true} size={15} />
            </span>
          </p>
        </div>
      </div>
    );
  }

  if (error) {
    if (error.networkError) {
      return (
        <div className="community-nearby-box">
          <h3>Communities Nearby</h3>
          <div className="community-nearby-list">
            <p>
              Error : No internet connection, please refresh the page or
              check internet connection !!
            </p>
          </div>
        </div>
      );
    }
    return (
      <div className="community-nearby-box">
        <h3>Communities Nearby</h3>
        <div className="community-nearby-list">
          <p>
            Error!
            {" "}
            {error.message}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="community-nearby-box">
      <h3>Communities Nearby</h3>
      <div className="community-nearby-list">
        {data.getNearByCommunities && data.getNearByCommunities.length > 0 && (
          <ul>
            {data.getNearByCommunities.map((community) => (
              <li key={Math.random()}>
                <div className="community-info">
                  <div className="photo">
                    <img src={community.photo} alt={community.name} />
                  </div>
                  <div className="name-info">
                    <div className="community-name">
                      <Link to={`/community/${community.slug}/posts`}>
                        {community.name}
                      </Link>
                    </div>
                    <div className="community-members">
                      {numFormatter(community.community_members_count) ?? 0}
                      {" "}
                      {community.community_members_count > 1
                        ? "members"
                        : "member"}
                    </div>
                  </div>
                </div>
                <div className="join-btn">
                  {communityIds
                  && communityIds.data
                  && communityIds.data.getFollowingCommunityIds
                  && communityIds.data.getFollowingCommunityIds.following_community_ids.includes(
                    community.id,
                  ) ? (
                    <Button
                      variant="primary"
                      className="btn-bold"
                      title="Joined"
                    >
                      Joined
                    </Button>
                    ) : (
                      <Button
                        variant="primary"
                        className="btn-bold"
                        title="Join"
                        disabled={
                        selectedId === community.id && mutationData.loading
                      }
                        onClick={async () => {
                          setSelectedId(community.id);
                          await selectCommunity({
                            variables: {
                              community_slug: community.slug,
                              from: "portal",
                            },
                            refetchQueries: [
                              {
                                query: GET_NEAR_BY_COMMUNITIES,
                                variables: {
                                  latitude,
                                  longitude: logitude,
                                },
                              },
                            ],
                          });
                        }}
                      >
                        Join
                        {selectedId === community.id && mutationData.loading && (
                        <span className="ml-2">
                          <ClipLoader
                            color="#ffffff"
                            loading={true}
                            size={15}
                          />
                        </span>
                        )}
                      </Button>
                    )}
                </div>
              </li>
            ))}
          </ul>
        )}
        {data.getNearByCommunities
          && data.getNearByCommunities.length === 0 && <p>Nothing to see here — yet</p>}
        {/* <div className="create-article-btn">
          <Link
            to="/create-community"
            className="btn btn-bold btn-block btn-secondary"
          >
            Create a community
          </Link>
        </div> */}
      </div>
    </div>
  );
};

const WithOutLoginListing = ({ latitude, logitude }) => {
  const { loading, error, data } = useQuery(GET_NEAR_BY_COMMUNITIES, {
    variables: {
      latitude,
      longitude: logitude,
    },
  });
  if (loading || error) {
    return (
      <div className="community-nearby-box">
        <h3>Communities Nearby</h3>
        <div className="community-nearby-list">
          <p>
            Loading...
            <span className="ml-2">
              <ClipLoader color="#e76c35" loading={true} size={15} />
            </span>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="community-nearby-box">
      <h3>Communities Nearby</h3>
      <div className="community-nearby-list">
        {data
          && data.getNearByCommunities
          && data.getNearByCommunities.length > 0 && (
            <ul>
              {data.getNearByCommunities.map((community) => (
                <li key={Math.random()}>
                  <div className="community-info">
                    <div className="photo">
                      <img src={community.photo} alt={community.name} />
                    </div>
                    <div className="name-info">
                      <div className="community-name">{community.name}</div>
                      <div className="community-members">
                        {numFormatter(community.community_members_count) ?? 0}
                        {" "}
                        members
                      </div>
                    </div>
                  </div>
                  <div className="join-btn">
                    <Link to="/login">
                      <Button
                        variant="primary"
                        className="btn-bold"
                        title="Join"
                      >
                        Join
                      </Button>
                    </Link>
                  </div>
                </li>
              ))}
            </ul>
        )}
        {data.getNearByCommunities
          && data.getNearByCommunities.length === 0 && <p>Nothing to see here — yet</p>}
      </div>
    </div>
  );
};

const CommunitiesNearby = ({ isUserLoggedIn, longitude, latitude }) => {
  if (isUserLoggedIn) {
    return <Listing latitude={latitude} logitude={longitude} />;
  }
  return <WithOutLoginListing latitude={latitude} logitude={longitude} />;
};

export default CommunitiesNearby;