import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { ClipLoader } from "react-spinners";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import NewNotificationIcon from "../../../public/images/notification2.svg";
import NotificationIcon from "../../../public/images/header-notification.svg";
import "../../../public/style/_notifications.scss";
import { GET_USER_LATEST_NOTIFICATION } from "../../../queries/newsCosmosQueries";
import { useAuth } from "../../../utils/useAuth";
import { READ_NOTIFICATIONS } from "../../../mutations/newsCosmosMutations";

const Notifications = () => {
  const auth = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [hasNotification, setHasNotification] = useState(
    typeof auth.user === "object"
      ? auth.user.has_notifications
      : JSON.parse(auth.user).has_notifications
  );

  const { data, loading, refetch } = useQuery(GET_USER_LATEST_NOTIFICATION, {
    skip: !isOpen,
  });
  const [readNotifications, mutationsData] = useMutation(READ_NOTIFICATIONS);

  return (
    <div className="notification-icon">
      <svg className="d-none">
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          id="invitation-from-community-owners"
        >
          <title>invitation from community owners</title>
          <path
            d="M8 0L.792 4.206c-.49.288-.79.812-.792 1.38v8.02a1.6 1.6 0 001.6 1.6h12.8a1.6 1.6 0 001.6-1.6v-8.02a1.608 1.608 0 00-.794-1.38L8 0zm0 1.853l6.314 3.683L8 9.464 1.686 5.536 8 1.853zm-.8 1.353v1.6H5.6v1.6h1.6v1.6h1.6v-1.6h1.6v-1.6H8.8v-1.6H7.2z"
            fill="#909090"
          ></path>
        </symbol>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          id="Private-community-owner-approves-my-request"
        >
          <title>Private community owner approves my request</title>
          <path
            d="M6.858 0a3.048 3.048 0 10-.002 6.096A3.048 3.048 0 006.858 0zm4.571 6.857a4.562 4.562 0 00-3.463 1.6 9.965 9.965 0 00-1.109-.074C4.464 8.383 0 9.561 0 11.812v1.902h7.478a4.568 4.568 0 103.951-6.857zm0 1.524c.73.001 1.435.267 1.985.747l-1.985 1.986-.985-.985-1.077 1.077 2.062 2.063 2.862-2.862a3.042 3.042 0 11-2.862-2.025v-.001z"
            fill="#909090"
          ></path>
        </symbol>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 15"
          id="Someone-comments-on-article-of-my-community"
        >
          <title>Someone comments on article of my community</title>
          <path
            d="M13.671 14.29H14.8l-.8-.813a2.768 2.768 0 01-.775-1.628A5.819 5.819 0 0016 6.961C16 3.509 12.872 0 7.973 0 2.783 0 0 3.228 0 6.658c0 3.453 2.812 6.679 7.973 6.679.909 0 1.814-.117 2.693-.347a4.172 4.172 0 003.008 1.3h-.003z"
            fill="#909090"
          ></path>
        </symbol>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 15"
          id="Someone-comments-on-my-news-article"
        >
          <title>Someone comments on my news article</title>
          <path
            d="M13.671 14.29H14.8l-.8-.813a2.768 2.768 0 01-.775-1.628A5.819 5.819 0 0016 6.961C16 3.509 12.872 0 7.973 0 2.783 0 0 3.228 0 6.658c0 3.453 2.812 6.679 7.973 6.679.909 0 1.814-.117 2.693-.347a4.172 4.172 0 003.008 1.3h-.003z"
            fill="#909090"
          ></path>
        </symbol>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          id="Someone-follows-me"
        >
          <title>Someone follows me</title>
          <path
            d="M13.47 0c1.394 0 2.529 1.227 2.529 2.735v6.908c0 1.509-1.135 2.736-2.53 2.736h-3.284l-1.768 3.369a.472.472 0 01-.835 0l-1.768-3.37H2.529C1.135 12.379 0 11.153 0 9.644V2.735C0 1.227 1.135 0 2.53 0zM6.112 2.153c-.989 0-1.794.805-1.794 1.794 0 .494.201.942.525 1.267A3.046 3.046 0 002.975 8.02a.85.85 0 00.85.849h4.577a.85.85 0 00.85-.85 3.046 3.046 0 00-1.87-2.805 1.79 1.79 0 00.525-1.267c0-.989-.805-1.794-1.794-1.794zm5.939 1.662a.339.339 0 00-.181.044l-.824.499a.373.373 0 00-.18.33c0 .194.124.388.305.388.044 0 .1-.013.156-.05l.181-.113V8.09c0 .2.243.3.487.3.243 0 .486-.1.486-.3V4.12c0-.187-.23-.305-.43-.305zM6.21 5.922a2.1 2.1 0 012.096 2.003H3.921a2.1 2.1 0 012.096-2.003zm-.097-2.826a.852.852 0 010 1.702.852.852 0 010-1.702z"
            fill="#909090"
          ></path>
        </symbol>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          id="Someone-joins-my-community"
        >
          <title>Someone joins my community</title>
          <path
            d="M6.857 0a3.048 3.048 0 10-.002 6.096A3.048 3.048 0 006.857 0zm4.571 6.857a4.571 4.571 0 104.571 4.571 4.585 4.585 0 00-4.571-4.571zM6.134 8.414C3.726 8.614 0 9.747 0 11.807v1.907h5.781a6.061 6.061 0 01.353-5.3zm4.533.726h1.524v1.524h1.523v1.524H12.19v1.526h-1.524V12.19H9.143v-1.524h1.524V9.14z"
            fill="#909090"
          ></path>
        </symbol>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          id="Someone-leaves-my-community"
        >
          <title>Someone leaves my community</title>
          <path
            d="M8.667 0c1.102 0 2 .897 2 2v2a.666.666 0 11-1.334 0V2a.667.667 0 00-.666-.667H5.56l.198.066c.545.19.908.698.908 1.268v10h2c.368 0 .666-.3.666-.667V9.333a.666.666 0 111.334 0V12c0 1.103-.898 2-2 2h-2v.667c0 .735-.598 1.333-1.334 1.333a1.38 1.38 0 01-.413-.062L.908 14.601A1.344 1.344 0 010 13.333v-12C0 .598.598 0 1.333 0c.033 0 .064.012.096.015C1.453.01 1.475 0 1.5 0zm3.744 3.384a.667.667 0 01.727.145l2.667 2.666c.26.26.26.682 0 .943l-2.667 2.667A.667.667 0 0112 9.333v-2H9.333a.667.667 0 010-1.333H12V4c0-.27.163-.513.411-.616z"
            fill="#909090"
          ></path>
        </symbol>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          id="Someone-posts-and-article-to-my-community"
        >
          <title>Someone posts and article to my community</title>
          <path
            d="M11.854 0c.34 0 .439.1.439.447v9.377c.596-.084 1.15-.052 1.69.15a3.12 3.12 0 011.987 3.345 3.102 3.102 0 01-2.768 2.652c-.215.02-.435-.003-.655.002-.243.005-.485.027-.728.027h-10.5c-.785 0-1.31-.537-1.316-1.352-.006-.941 0-1.882 0-2.824V3.353c0-.657-.006-1.312 0-1.97C.01.502.519-.001 1.397 0zm1.01 10.404a2.482 2.482 0 00.027 4.962 2.484 2.484 0 002.482-2.496c-.002-1.37-1.121-2.47-2.51-2.466zm.437 1.448c.03 0 .058.014.076.037l.525.527a.097.097 0 010 .154l-1.44 1.438a.448.448 0 01-.27.12c-.169.023-.337.05-.505.076a.15.15 0 01-.02.002.075.075 0 01-.083-.085l.091-.591a.35.35 0 01.104-.195l1.446-1.446a.097.097 0 01.076-.037zm.824-.425l.246.245a.302.302 0 01.003.426 6.75 6.75 0 01-.202.202.096.096 0 01-.143 0l-.534-.534a.094.094 0 010-.144c.066-.067.132-.135.2-.2a.308.308 0 01.43.005zM6.04 9.49H2.502a1.14 1.14 0 00-.324.037c-.155.048-.262.176-.222.33.03.114.139.222.239.3a.297.297 0 00.173.036h1.855a119.325 119.325 0 001.861 0c.246-.002.397-.144.399-.344.002-.2-.13-.327-.368-.354a1.269 1.269 0 00-.153-.006zM9.9 7.729H2.43a.876.876 0 00-.198.016c-.177.04-.284.153-.283.34 0 .186.11.3.293.323.159.017.32.024.48.021h5.982c.445 0 .89-.005 1.335-.022.185-.008.29-.127.3-.315.012-.2-.103-.31-.287-.35A.716.716 0 009.9 7.73zm-.49-4.826H2.87c0 .77.006 1.513-.004 2.257-.003.185.068.215.23.215 2.022-.002 4.045-.003 6.068-.003.078 0 .159-.007.246-.012V2.904z"
            fill="#909090"
          ></path>
        </symbol>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          id="Someone-removes-article-from-my-community"
        >
          <title>Someone removes article from my community</title>
          <path
            d="M11.854 0c.34 0 .439.1.439.447v9.377c.596-.084 1.15-.052 1.69.15a3.12 3.12 0 011.987 3.345 3.102 3.102 0 01-2.768 2.652c-.215.02-.435-.003-.655.002-.243.005-.485.027-.728.027h-10.5c-.785 0-1.31-.537-1.316-1.352-.006-.941 0-1.882 0-2.824V3.353c0-.657-.006-1.312 0-1.97C.01.502.519-.001 1.397 0zm1.01 10.404a2.482 2.482 0 00.027 4.962 2.484 2.484 0 002.482-2.496c-.002-1.37-1.121-2.47-2.51-2.466zm.824 1.234c.173-.17.363-.174.498-.023.123.138.109.302-.047.466-.131.138-.271.277-.404.402s-.272.242-.425.377c.294.292.561.54.805.81.078.087.145.234.132.343-.03.257-.339.33-.542.135-.273-.263-.532-.54-.815-.828-.275.277-.524.543-.791.79a.5.5 0 01-.322.134c-.085-.003-.218-.108-.234-.186a.461.461 0 01.083-.351c.251-.282.531-.54.823-.83-.285-.277-.562-.538-.825-.807-.167-.17-.15-.394.027-.509.163-.105.307-.056.432.073.26.266.517.533.796.822.287-.292.545-.558.809-.818zM6.038 9.49H2.503a1.14 1.14 0 00-.324.037c-.155.048-.262.176-.222.33.03.114.139.222.239.3a.297.297 0 00.173.036h1.855a119.325 119.325 0 001.861 0c.246-.002.397-.144.399-.344.002-.2-.13-.327-.368-.354a1.269 1.269 0 00-.153-.006zM9.9 7.73H2.43a.876.876 0 00-.199.016c-.177.04-.284.153-.283.34 0 .186.11.3.293.323.159.017.32.024.48.021h5.982c.445 0 .89-.005 1.335-.022.185-.008.29-.127.3-.315.012-.2-.103-.31-.287-.35A.716.716 0 009.9 7.73zm-.49-4.826H2.87c0 .77.005 1.513-.005 2.257-.003.185.068.215.23.215 2.022-.002 4.045-.003 6.068-.003.078 0 .159-.007.246-.012V2.904z"
            fill="#909090"
          ></path>
        </symbol>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 14"
          id="Someone-replies-to-my-comments"
        >
          <title>Someone replies to my comments</title>
          <path
            d="M5.307 9.859L.116 5.286a.469.469 0 010-.617L5.306.095c.234-.233.62 0 .62.312v2.245C16.08 2.652 16 13.504 16 13.504s-.7-6.2-10.073-6.2V9.55c0 .309-.387.542-.62.309z"
            fill="#909090"
          ></path>
        </symbol>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 15 9"
          id="Someone-votes-on-my-comments"
        >
          <title>Someone votes on my comments</title>
          <path
            fill="#909090"
            d="M12.465 8.868l1.757-1.757L7.111 0 0 7.111l1.758 1.757 5.354-5.354z"
          ></path>
        </symbol>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          id="Someone-votes-on-my-news-article"
        >
          <title>Someone votes on my news article</title>
          <path
            fill="#909090"
            d="M8 0L5.524 5.013 0 5.813l4 3.9-.944 5.506L8 12.619l4.944 2.6L12 9.713l4-3.9-5.527-.8z"
          ></path>
        </symbol>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 18 18"
          id="Someone-removes-article-from-your-community"
        >
          <g fill="#909090">
            <path d="M11.217 0H2.165A2.107 2.107 0 000 2.048v11.543a2.107 2.107 0 002.165 2.048h5.187a6.212 6.212 0 01-.661-2.793 6.134 6.134 0 01.5-2.428.439.439 0 01-.1.007H3.149a.746.746 0 110-1.489h3.935a.784.784 0 01.693.4 6.76 6.76 0 011.952-1.889h-6.58a.746.746 0 110-1.489h7.084a.769.769 0 01.787.745.373.373 0 01-.008.1 6.921 6.921 0 012.369-.462V2.048A2.107 2.107 0 0011.217 0zM6.3 4.468H3.149a.769.769 0 01-.787-.745.769.769 0 01.787-.745H6.3a.769.769 0 01.787.745.769.769 0 01-.787.745z"></path>
            <path d="M12.687 7.374A5.313 5.313 0 1018 12.687a5.319 5.319 0 00-5.313-5.313zm1.934 6.134a.787.787 0 11-1.113 1.113l-.821-.821-.821.821a.787.787 0 11-1.113-1.113l.821-.821-.821-.821a.787.787 0 011.113-1.113l.821.821.821-.821a.787.787 0 111.113 1.113l-.821.821z"></path>
          </g>
        </symbol>
      </svg>
      <Dropdown
        alignRight={true}
        onToggle={async (show) => {
          if (show) {
            setIsOpen(true);
            setHasNotification(false);
            isOpen && (await refetch());
            setTimeout(async () => {
              await readNotifications();
            }, 2000);
          }
        }}
      >
        <Dropdown.Toggle variant="normal-link" id="dropdown-basic">
          {hasNotification ? <NewNotificationIcon /> : <NotificationIcon />}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="notifications-dd-header">
            <h4>Notifications</h4>
            <div className="view-all-notification">
              <LinkContainer
                to={`/user/${
                  typeof auth.user === "object"
                    ? auth.user.slug
                    : JSON.parse(auth.user).slug
                }/notifications`}
              >
                <Dropdown.Item>View All</Dropdown.Item>
              </LinkContainer>
            </div>
          </div>
          <div className="notifications-list">
            <ul>
              {isOpen &&
                (loading ? (
                  <p className="text-center">
                    Loading...
                    <span className="ml-2">
                      <ClipLoader color="#e76c35" loading={true} size={15} />
                    </span>
                  </p>
                ) : (
                  data.getUserLatestNotifications.user_latest_notifications &&
                  data.getUserLatestNotifications.user_latest_notifications.map(
                    (notification) => {
                      const createdAt = moment
                        .utc(notification.created_at)
                        .local();
                      const symbol =
                        notification.type === "invitation-from-community-owners"
                          ? "invitation-from-community-owners"
                          : notification.type === "comments-on-my-news-article"
                          ? "Someone-comments-on-my-news-article"
                          : notification.type === "votes-on-my-news-article"
                          ? "Someone-votes-on-my-news-article"
                          : notification.type === "votes-on-my-comments"
                          ? "Someone-votes-on-my-comments"
                          : notification.type === "replies-to-my-comments"
                          ? "Someone-replies-to-my-comments"
                          : notification.type === "follows-me"
                          ? "Someone-follows-me"
                          : notification.type === "owner-approve-my-request"
                          ? "Private-community-owner-approves-my-request"
                          : notification.type === "joins-my-community"
                          ? "Someone-joins-my-community"
                          : notification.type === "leaves-my-community"
                          ? "Someone-leaves-my-community"
                          : notification.type ===
                            "posts-article-to-my-community"
                          ? "Someone-posts-and-article-to-my-community"
                          : notification.type ===
                            "comments-on-article-of-my-community"
                          ? "Someone-comments-on-article-of-my-community"
                          : notification.type ===
                            "removes-article-from-your-community"
                          ? "Someone-removes-article-from-your-community"
                          : "Someone-removes-article-from-my-community";
                      return (
                        <li key={Math.random()}>
                          <div className="icon">
                            <svg>
                              <use xlinkHref={`#${symbol}`} />
                            </svg>
                          </div>
                          <div className="notifications-info">
                            <div className="notification-info-txt">
                              <div className="name">
                                <LinkContainer
                                  to={`/user/${notification.user_slug}/posts`}
                                >
                                  <Dropdown.Item>
                                    {notification.text_1 ?? ""}
                                  </Dropdown.Item>
                                </LinkContainer>
                              </div>
                              <p>{notification.text_2 ?? ""}</p>
                              <div className="article-name">
                                {notification.text_3 ?? ""}
                              </div>
                            </div>
                            <div className="time">
                              {moment(createdAt).fromNow()}
                            </div>
                          </div>
                        </li>
                      );
                    }
                  )
                ))}
              {/* <li>
                  <div className="icon">
                    <svg>
                      <use xlinkHref="#invitation-from-community-owners" />
                    </svg>
                  </div>
                  <div className="notifications-info">
                    <div className="notification-info-txt">
                      <div className="name">
                        <Link to="/">Peter Kavin</Link>
                      </div>
                      <p>invited you to join his community</p>
                      <div className="article-name">TheHebrewHeimer</div>
                    </div>
                    <div className="time">12 hours ago</div>
                  </div>
                </li> */}
            </ul>

            {isOpen &&
              data &&
              data.getUserLatestNotifications.user_latest_notifications &&
              data.getUserLatestNotifications.user_latest_notifications
                .length === 0 && (
                <ul>
                  <li>
                    <div className="no-data h6">Nothing to see here — yet</div>
                  </li>
                </ul>
              )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Notifications;
