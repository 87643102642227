import React from "react";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useQuery } from "@apollo/client";
import { GET_COMMUNITY_MEMBERS } from "../../../../queries/newsCosmosQueries";
// import Photo from "../../../../public/images/communities-small-photo.png";
import "../CommunitiesNearby/communitiesnearby.scss";
import { numFormatter } from "../../../../utils/helper";

const CommunitiesMembers = ({ match }) => {
  const { loading, error, data } = useQuery(GET_COMMUNITY_MEMBERS, {
    variables: {
      slug: match.params.slug,
      sorting: "asc",
      limit: 5,
      current_page: 1,
    },
  });
  if (loading || error) {
    return (
      <div className="community-nearby-box">
        <h3>Community Members</h3>
        <div className="community-nearby-list">
          <p>
            Loading...
            <span className="ml-2">
              <ClipLoader color="#e76c35" loading={true} size={15} />
            </span>
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className="community-nearby-box">
      <h3>Community Members</h3>
      <div className="community-nearby-list">
        {data.getCommunityMembers
          && data.getCommunityMembers.community_members.length > 0 && (
            <>
              <ul>
                {data.getCommunityMembers.community_members.map(
                  (member) => member.status !== "pending" && (
                  <li key={member.id}>
                    <div className="community-info">
                      <div className="photo">
                        <img src={member.user.photo} alt="user-avatar" />
                      </div>
                      <div className="name-info">
                        <div className="community-name">
                          <Link to={`/user/${member.user.slug}/posts`}>
                            {member.user.username}
                          </Link>
                        </div>
                        <div className="community-members">
                          {numFormatter(member.user.followers_count) ?? 0}
                          {" "}
                          {member.user.followers_count > 1
                            ? "followers"
                            : "follower"}
                        </div>
                      </div>
                    </div>
                  </li>
                  ),
                )}
              </ul>
              <div className="create-article-btn d-block">
                <Link
                  to={`/community/${match.params.slug}/members`}
                  className="btn btn-primary btn-bold btn-block"
                >
                  View all members
                </Link>
              </div>
            </>
        )}
        {data.getCommunityMembers
          && data.getCommunityMembers.community_members.length === 0 && (
            <p>Nothing to see here — yet</p>
        )}
      </div>
    </div>
  );
};

export default CommunitiesMembers;