@import "./variables";

.login-wrap{
	padding: 40px 15px;
	.login-box{
		max-width: 474px;
		width: 100%;
		margin: 0 auto;
		background: $color_white;
		@include border-radius(5px);
		&-header{
			padding: 22px 15px 20px;
			border-bottom: 1px solid rgba($gray_color, .2);
			h1{
				text-align: center;
				font-size: 36px;
				font-weight: 700;
				color: $secondary_color;
				@include breakpoint( max , 767){
					font-size: 28px;
				}
			}
		}
		&-body{
			padding: 26px 15px;
			.form-wrap{
				max-width: 300px;
				margin: 0 auto;
			}
		}
		.login-with-google{
			max-width: 300px;
			margin: 0 auto;
			padding-bottom: 19px;
			.google-btn{
				.btn{
					&.btn-bold{
						padding: 2px 3px;
						display: flex;
						align-items: center;
						.icon{
							width: 34px;
							height: 34px;
							background: $color_white;
							display: flex;
							align-items: center;
							justify-content: center;
							flex-shrink: 0;
							margin-right: 20px;
							@include border-radius(3px);
						}
					}
				}
			}
		}
		.login-note{
			font-size: 12px;
			line-height: 14px;
			padding-bottom: 20px;
			a{
				color: $primary_color;
			}
		}
		.or{
			position: relative;
			max-width: 175px;
			margin: 18px auto 0;
			&:before{
				content: "";
				position: absolute;
				left: 0;
				top: 50%;
				height: 1px;
				width: 100%;
				background: rgba($gray_color, .2);
			}
			span{
				display: block;
				background: $color_white;
				font-size: 12px;
				text-transform: uppercase;
				width: 55px;
				text-align: center;
				margin: 0 auto;
				position: relative;
			}
		}
		.login-signup-txt{
			font-size: 14px;
			line-height: 18px;
			padding-top: 9px;
			a{
				font-weight: 700;
				text-transform: uppercase;
				color: $primary_color;
			}
		}
		&.signup-confirm-email-box{
			max-width: 900px;
			padding-left: 26px;
			padding-right: 26px;
			.login-box-header{
				padding: 30px 0 26px;
				border-bottom: 1px solid $color_mercury2;
				.img{
					text-align: center;
					padding-top: 4px;
					padding-bottom: 30px;
				}
				p{
					max-width: 510px;
					margin: 0 auto;
					font-size: 18px;
					line-height: 25px;
					text-align: center;
					color: $color_gray_dark;
					@include breakpoint( max , 767){
						font-size: 16px;
						line-height: 24px;
					}
					span{
						color: $secondary_color;
					}
					a{
						color: $secondary_color;
						&:hover{
							text-decoration: underline;
						}
					}
				}
			}
			.login-box-footer{
				padding: 20px 0 20px;
				font-size: 14px;
				color: $color_gray_dark;
				text-align: center;
				br{
					display: none;
					@include breakpoint( max , 767){
						display: block;
					}
				}
				p{
					margin: 0;
					a{
						font-weight: 600;
						color: $primary_color;
						&:hover{
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}