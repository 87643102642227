import React from "react";
import { useQuery } from "@apollo/client";
import Dropdown from "react-bootstrap/Dropdown";
import { LinkContainer } from "react-router-bootstrap";
import { GET_TOPICS } from "../../../queries/newsCosmosQueries";

const TopicSelect = () => {
  const { loading, data } = useQuery(GET_TOPICS);

  if (loading) {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="normal-link" id="dropdown-basic">
          Topics
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item>Fetching Topics...</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
  return (
    <Dropdown>
      <Dropdown.Toggle variant="normal-link" id="dropdown-basic">
        Topics
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {data && data.topics && data.topics.map((topic) => (
          <LinkContainer key={Math.random()} to={`/topic/${topic.slug}`}>
            <Dropdown.Item>{topic.title}</Dropdown.Item>
          </LinkContainer>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TopicSelect;