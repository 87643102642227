import React from "react";
import "./sidebar-ads.scss";

const SidebarAds = () => (
  <div className="sidebar-ads">
    <div className="ads-title">advertisement</div>
    <div className="ads-body-area">
      <img src="https://via.placeholder.com/300x600/e2e2e2" alt="" />
    </div>
  </div>
);

export default SidebarAds;