import { gql } from "@apollo/client";

export const GET_TOPICS = gql`
  {
    topics {
      id
      title
      slug
      photo
    }
  }
`;

export const GET_USER_TOPIC_IDS = gql`
  {
    getUserTopicIds {
      code
      status
      message
      topic_ids
    }
  }
`;

export const GET_OWN_FOLLOWING_COMMUNITIES = gql`
  {
    getOwnAndFollowingCommunities {
      communities {
        id
        name
      }
    }
  }
`;

export const GET_CONTENT_FROM_LINK = gql`
  query getContentFromLink($link: String!) {
    getContentFromLink(input: { link: $link }) {
      code
      status
      message
      title
      content
      image
      organization
    }
  }
`;

export const GET_NEAR_BY_COMMUNITIES = gql`
  query getCommunity($latitude: Float!, $longitude: Float!) {
    getNearByCommunities(latitude: $latitude, longitude: $longitude) {
      id
      name
      slug
      photo
      description
      type
      community_members_count
    }
  }
`;

export const GET_COMMUNITY = gql`
  query getCommunity($slug: String!) {
    community(slug: $slug) {
      id
      type
      name
      email
      website
      status
      slug
      created_at
      photo
      cover_photo
      description
      location
      lat
      long
      community_members_count
      owner {
        id
        username
        slug
      }
    }
  }
`;
export const GET_MY_POSTS = gql`
  query getUserProfilePosts(
    $user_slug: String!
    $limit: Int!
    $current_page: Int!
    $sorting: String
    $filter: String
  ) {
    getUserProfilePosts(
      user_slug: $user_slug
      limit: $limit
      current_page: $current_page
      sorting: $sorting
      filter: $filter
    ) {
      code
      status
      message
      user_profile_posts {
        id
        community_id
        image_url
        slug
        url
        created_at
        title
        description
        topic_id
        average_rating
        post_rates_count
        community {
          id
          type
          slug
          name
          photo
        }
        topic {
          id
          title
          slug
          photo
        }
        user {
          id
          username
          slug
          photo
        }
        newsOrganization {
          title
          slug
        }
        all_comments_count
      }
      next_page
    }
  }
`;

export const GET_MY_FEEDS = gql`
  query getMyFeed($limit: Int!, $current_page: Int!, $sorting: String) {
    getMyFeed(limit: $limit, current_page: $current_page, sorting: $sorting) {
      code
      status
      message
      my_feed {
        id
        image_url
        slug
        url
        created_at
        title
        description
        topic_id
        average_rating
        community {
          id
          type
          slug
          name
          photo
        }
        topic {
          id
          title
          slug
          photo
        }
        user {
          id
          username
          slug
          photo
        }
        all_comments_count
      }
      next_page
    }
  }
`;

export const GET_POSTS = gql`
  query getPosts(
    $limit: Int!
    $current_page: Int!
    $sorting: String
    $filter: String
  ) {
    getPosts(
      limit: $limit
      current_page: $current_page
      sorting: $sorting
      filter: $filter
    ) {
      code
      status
      message
      posts {
        id
        image_url
        slug
        url
        created_at
        title
        description
        topic_id
        post_rates_count
        average_rating
        community {
          id
          type
          slug
          name
          photo
        }
        topic {
          id
          title
          slug
          photo
        }
        user {
          id
          username
          slug
          photo
        }
        newsOrganization {
          title
          slug
        }
        all_comments_count
      }
      next_page
    }
  }
`;
export const GET_SAVE_POST_IDS = gql`
  {
    getSavedPostIds {
      code
      status
      message
      saved_post_ids
    }
  }
`;
export const GET_POST_DETAILS = gql`
  query getPostDetails($post_slug: String!) {
    getPostDetails(post_slug: $post_slug) {
      code
      status
      message

      post {
        topic_id
        community_id
        publish
        id
        url
        image_url
        document_url
        user_id
        slug
        title
        description
        schedule
        post_rates_count
        created_at
        all_comments_count
        average_rating
        ownPostRating {
          id
          post_id
          quality
          facts
          objectivity
          accuracy
          edited
        }
        community {
          id
          photo
          cover_photo
          name
          slug
          status
          type
          description
          location
          community_members_count
          owner {
            id
          }
        }
        topic {
          title
          slug
          photo
        }
        user {
          id
          username
          followers_count
          created_at
          slug
          deactive
          photo
          userProfile {
            cover_photo
            description
            location
          }
        }
        tags
        newsOrganization {
          slug
          title
        }
      }
      community {
        id
        photo
        cover_photo
        name
        slug
        status
        type
        description
        location
        community_members_count
        owner {
          id
        }
      }
    }
  }
`;

export const GET_POST_COMMENT_DETAILS = gql`
  query getSharedCommentDetails($post_slug: String!, $comment_slug: String!) {
    getSharedCommentDetails(
      post_slug: $post_slug
      comment_slug: $comment_slug
    ) {
      code
      status
      message
      post {
        topic_id
        community_id
        publish
        id
        url
        image_url
        user_id
        slug
        title
        description
        schedule
        post_rates_count
        created_at
        all_comments_count
        average_rating
        ownPostRating {
          id
          post_id
          quality
          facts
          objectivity
          accuracy
          edited
        }
        community {
          id
          photo
          cover_photo
          name
          slug
          type
          description
          location
          community_members_count
          owner {
            id
          }
        }
        topic {
          title
          slug
          photo
        }
        user {
          id
          username
          followers_count
          created_at
          slug
          photo
          userProfile {
            cover_photo
            description
            location
          }
        }
        tags
        newsOrganization {
          slug
          title
        }
      }
      comment {
        id
        slug
        user_id
        parent_id
        text
        deleted_at
        created_at
        replies_count
        upvotes_count
        downvotes_count
        user {
          id
          username
          slug
          photo
        }
      }
    }
  }
`;

export const GET_NEWS_ORGANIZATION_COMMENT_DETAILS = gql`
  query getSharedNewsOrganizationCommentDetails(
    $news_organization_slug: String!
    $news_organization_comment_slug: String!
  ) {
    getSharedNewsOrganizationCommentDetails(
      news_organization_slug: $news_organization_slug
      news_organization_comment_slug: $news_organization_comment_slug
    ) {
      code
      status
      message
      news_organization {
        id
        user_id
        title
        slug
        photo
        average_rating
        description
        website
        email
        location
        ownership
        media_companies
        influential_people
        revenue_employee
        awards
        main_office_address
        requested
        created_at
        updated_at

        user {
          id
          username
          slug
          email
          role
          verified
          approved
          deactive
          photo
          created_at
          updated_at
        }
      }
      news_organization_comment {
        id
        slug
        user_id
        parent_id
        text
        deleted_at
        created_at
        replies_count
        upvotes_count
        downvotes_count
        user {
          id
          username
          slug
          photo
        }
      }
    }
  }
`;

export const GET_CURRENT_ISSUE_COMMENT_DETAILS = gql`
  query getSharedCurrentIssueCommentDetails(
    $current_issue_slug: String!
    $current_issue_comment_slug: String!
  ) {
    getSharedCurrentIssueCommentDetails(
      current_issue_slug: $current_issue_slug
      current_issue_comment_slug: $current_issue_comment_slug
    ) {
      code
      status
      message
      current_issue {
        id
        name
        slug
        photo
        thumb
        description
        status
        created_at
        all_current_issue_comments_count
      }
      current_issue_comment {
        id
        slug
        user_id
        parent_id
        text
        deleted_at
        created_at
        replies_count
        upvotes_count
        downvotes_count
        user {
          id
          username
          slug
          photo
        }
      }
    }
  }
`;
export const GET_POST_RATING_BY_USER = gql`
  query getPostRatingByUser($post_slug: String!) {
    getPostRatingByUser(post_slug: $post_slug) {
      code
      status
      message
      user_post_rate {
        quality
        facts
        objectivity
        accuracy
        edited
      }
    }
  }
`;

export const GET_POST_COMMENTS = gql`
  query getPostComments(
    $post_slug: String!
    $limit: Int!
    $current_page: Int!
    $sorting: String!
  ) {
    getPostComments(
      post_slug: $post_slug
      limit: $limit
      current_page: $current_page
      sorting: $sorting
    ) {
      code
      status
      message
      next_page
      comments {
        id
        slug
        user_id
        parent_id
        text
        deleted_at
        created_at
        replies_count
        upvotes_count
        downvotes_count
        user {
          id
          username
          slug
          photo
        }
        replies {
          id
          slug
          user_id
          parent_id
          text
          deleted_at
          created_at
          replies_count
          upvotes_count
          downvotes_count
          user {
            id
            username
            slug
            photo
          }
          replies {
            id
            slug
            user_id
            parent_id
            text
            deleted_at
            created_at
            replies_count
            upvotes_count
            downvotes_count
            user {
              id
              username
              slug
              photo
            }
            replies {
              id
              slug
              user_id
              parent_id
              text
              deleted_at
              created_at
              replies_count
              upvotes_count
              downvotes_count
              user {
                id
                username
                slug
                photo
              }
              replies {
                id
                slug
                user_id
                parent_id
                text
                deleted_at
                created_at
                replies_count
                upvotes_count
                downvotes_count
                user {
                  id
                  username
                  slug
                  photo
                }
                replies {
                  id
                  slug
                  user_id
                  parent_id
                  text
                  deleted_at
                  created_at
                  replies_count
                  upvotes_count
                  downvotes_count
                  user {
                    id
                    username
                    slug
                    photo
                  }
                  replies {
                    id
                    slug
                    user_id
                    parent_id
                    text
                    deleted_at
                    created_at
                    replies_count
                    upvotes_count
                    downvotes_count
                    user {
                      id
                      username
                      slug
                      photo
                    }
                  }
                }
              }
            }
          }
        }
        user {
          id
          username
          slug
          photo
        }
      }
    }
  }
`;

export const GET_MY_COMMUNITIES = gql`
  query getMyCommunities(
    $community_type: String
    $limit: Int!
    $current_page: Int!
  ) {
    getMyCommunities(
      community_type: $community_type
      limit: $limit
      current_page: $current_page
    ) {
      code
      status
      message
      my_communities {
        id
        name
        description
        photo
        type
        slug
        community_members_count
      }
      next_page
    }
  }
`;

export const GET_FOLLOWING_COMMUNITY_IDS = gql`
  {
    getFollowingCommunityIds {
      code
      status
      message
      following_community_ids
    }
  }
`;

export const GET_OWNED_COMMUNITIES = gql`
  query getOwnedCommunities(
    $community_type: String
    $limit: Int!
    $current_page: Int!
  ) {
    getOwnedCommunities(
      community_type: $community_type
      limit: $limit
      current_page: $current_page
    ) {
      code
      status
      message
      owned_communities {
        id
        name
        type
        photo
        description
        slug
        community_members_count
      }
      next_page
    }
  }
`;
export const GET_OWN_COMMUNITY_POSTS = gql`
  query getCommunityPosts(
    $community_slug: String!
    $limit: Int!
    $current_page: Int!
    $filter: String
  ) {
    getCommunityPosts(
      community_slug: $community_slug
      limit: $limit
      current_page: $current_page
      filter: $filter
    ) {
      code
      status
      message
      community_posts {
        id
        image_url
        slug
        url
        created_at
        title
        description
        topic_id
        average_rating
        community {
          id
          type
          slug
          name
          photo
        }
        topic {
          id
          title
          slug
          photo
        }
        user {
          id
          username
          slug
        }
        all_comments_count
      }
      next_page
    }
  }
`;
export const GET_COMMUNITY_SCHEDULED_POSTS = gql`
  query getCommunityScheduledPosts(
    $community_slug: String!
    $limit: Int!
    $current_page: Int!
    $sorting: String
  ) {
    getCommunityScheduledPosts(
      community_slug: $community_slug
      limit: $limit
      current_page: $current_page
      sorting: $sorting
    ) {
      code
      status
      message
      community_scheduled_posts {
        id
        slug
        title
        description
        schedule
        url
        image_url
        average_rating
        created_at
        all_comments_count
        topic {
          title
          slug
          photo
        }
        user {
          id
          username
          slug
        }
        community {
          id
          type
          name
          slug
          photo
        }
      }
      next_page
    }
  }
`;
export const GET_COMMUNITY_MEMBERS = gql`
  query getCommunityMembers(
    $slug: String!
    $sorting: String!
    $limit: Int!
    $current_page: Int!
  ) {
    getCommunityMembers(
      community_slug: $slug
      sorting: $sorting
      limit: $limit
      current_page: $current_page
    ) {
      code
      status
      message
      community_members {
        id
        status
        created_at
        user {
          id
          slug
          username
          photo
          created_at
          followers_count
          userProfile {
            description
          }
        }
        community {
          id
          description
        }
      }
      next_page
    }
  }
`;

export const GET_COMMUNITY_MEMBER_IDS = gql`
  query getCommunityMemberIds($community_slug: String!) {
    getCommunityMemberIds(community_slug: $community_slug) {
      code
      status
      message
      community_member_ids
    }
  }
`;

export const GET_COMMUNITY_RULES = gql`
  query getCommunityRules($slug: String!) {
    getCommunityRules(community_slug: $slug) {
      code
      status
      message
      community_rules {
        id
        title
        description
        position
      }
    }
  }
`;

export const GET_USER_DETAIL = gql`
  query getUserDetail($slug: String!) {
    user(slug: $slug) {
      id
      username
      slug
      email
      role
      verified
      approved
      deactive
      photo
      followers_count
      followings_count
      created_at
      has_notifications
      userProfile {
        description
        location
        cover_photo
      }
    }
  }
`;

export const GET_USER_FOLLOWERS = gql`
  query getUserFollowers(
    $user_slug: String!
    $limit: Int!
    $current_page: Int!
  ) {
    getUserFollowers(
      user_slug: $user_slug
      limit: $limit
      current_page: $current_page
    ) {
      code
      status
      message
      followers {
        follower_id
        following_id
        follower {
          id
          username
          slug
          email
          role
          verified
          approved
          deactive
          photo
          created_at
          updated_at
          followers_count
          userProfile {
            description
          }
        }
      }
      next_page
    }
  }
`;
export const GET_USER_LATEST_NOTIFICATION = gql`
  {
    getUserLatestNotifications {
      code
      status
      message
      user_latest_notifications {
        id
        user_slug
        text_1
        text_2
        text_3
        type
        created_at
      }
    }
  }
`;

export const GET_USER_NOTIFICATION_PERMISSIONS = gql`
  {
    getUserNotificationPermissions {
      code
      status
      message
      notification_permissions
    }
  }
`;
export const GET_USER_SCHEDULED_POSTS = gql`
  query getScheduledPosts(
    $limit: Int!
    $current_page: Int!
    $filter: String
    $sorting: String
  ) {
    getScheduledPosts(
      limit: $limit
      current_page: $current_page
      filter: $filter
      sorting: $sorting
    ) {
      code
      status
      message
      scheduled_posts {
        id
        slug
        title
        description
        schedule
        image_url
        average_rating
        created_at
        all_comments_count
        url
        topic {
          title
          slug
          photo
        }
        user {
          id
          username
          slug
        }
        community {
          id
          type
          name
          slug
          photo
        }
      }
      next_page
    }
  }
`;

export const GET_USER_SAVED_POSTS = gql`
  query getSavedPosts(
    $limit: Int!
    $current_page: Int!
    $filter: String
    $sorting: String
  ) {
    getSavedPosts(
      limit: $limit
      current_page: $current_page
      filter: $filter
      sorting: $sorting
    ) {
      code
      status
      message
      saved_posts {
        id
        slug
        title
        description
        schedule
        image_url
        average_rating
        post_rates_count
        all_comments_count
        url
        topic {
          title
          slug
          photo
        }
        user {
          id
          username
          slug
        }
        community {
          id
          type
          name
          slug
          photo
        }
      }
      next_page
    }
  }
`;

export const GET_USER_FOLLOWING = gql`
  query getUserFollowing($limit: Int!, $current_page: Int!) {
    getUserFollowing(limit: $limit, current_page: $current_page) {
      code
      status
      message
      followings {
        follower_id
        following_id
        following {
          id
          username
          slug
          email
          role
          verified
          approved
          deactive
          photo
          created_at
          updated_at
          followers_count
          userProfile {
            description
          }
        }
      }
      next_page
    }
  }
`;

export const GET_FOLLOWING_USER_IDS = gql`
  {
    getFollowingUserIds {
      code
      status
      message
      following_user_ids
    }
  }
`;
export const GET_FOLLOWER_USER_IDS = gql`
  {
    getFollowerUserIds {
      code
      status
      message
      follower_user_ids
    }
  }
`;
export const GET_BLOCK_USERS = gql`
  {
    getBlockedUsers {
      code
      status
      message
      blocked_users {
        user_id
        blocked_user_id
        user {
          username
          slug
          photo
          userProfile {
            cover_photo
            description
          }
          followers_count
        }
        blockedUser {
          username
          slug
          photo
          userProfile {
            cover_photo
            description
          }
          followers_count
        }
      }
    }
  }
`;
export const GET_BLOCK_USER_IDS = gql`
  {
    getBlockedUserIds {
      code
      status
      message
      blocked_user_ids
    }
  }
`;
export const GET_USER_BLOCK_USER_IDS = gql`
  query getUserBlockedUserIds($slug: String!) {
    getUserBlockedUserIds(slug: $slug) {
      code
      status
      message
      blocked_user_ids
    }
  }
`;
export const GET_ORGANIZATION_PROFILE = gql`
  query getNewsOrganizationDetails($news_organization_slug: String!) {
    getNewsOrganizationDetails(
      news_organization_slug: $news_organization_slug
    ) {
      message
      news_organization {
        id
        user_id
        title
        slug
        photo
        average_rating
        description
        website
        email
        location
        ownership
        media_companies
        influential_people
        revenue_employee
        awards
        main_office_address
        requested
        created_at
        updated_at

        user {
          id
          username
          slug
          email
          role
          verified
          approved
          deactive
          photo
          created_at
          updated_at
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_POSTS = gql`
  query getNewsOrganizationPosts(
    $news_organization_slug: String!
    $limit: Int!
    $current_page: Int!
    $sorting: String
    $filter: String
  ) {
    getNewsOrganizationPosts(
      news_organization_slug: $news_organization_slug
      limit: $limit
      current_page: $current_page
      sorting: $sorting
      filter: $filter
    ) {
      code
      status
      message
      news_organization_posts {
        id
        image_url
        slug
        url
        created_at
        title
        description
        topic_id
        average_rating
        community {
          id
          type
          slug
          name
          photo
        }
        topic {
          id
          title
          slug
          photo
        }
        user {
          id
          username
          slug
          photo
        }
        all_comments_count
      }
      next_page
    }
  }
`;

export const GET_USER_NOTIFICATIONS = gql`
  query getUserNotifications($limit: Int!, $current_page: Int!) {
    getUserNotifications(limit: $limit, current_page: $current_page) {
      code
      status
      message
      user_notifications {
        id
        user_slug
        text_1
        text_2
        text_3
        type
        created_at
      }
      next_page
    }
  }
`;

export const GET_CURRENT_ISSUES = gql`
  query getCurrentIssues($filter: String!, $limit: Int!, $current_page: Int!) {
    getCurrentIssues(
      filter: $filter
      limit: $limit
      current_page: $current_page
    ) {
      code
      status
      message
      current_issues {
        id
        name
        slug
        photo
        thumb
        description
        status
        created_at
        updated_at
        all_current_issue_comments_count
      }
      next_page
    }
  }
`;

export const GET_CURRENT_ISSUE_DETAILS = gql`
  query getCurrentIssueDetails($current_issue_slug: String!) {
    getCurrentIssueDetails(current_issue_slug: $current_issue_slug) {
      code
      status
      message
      current_issue {
        id
        name
        slug
        photo
        thumb
        description
        status
        created_at
        all_current_issue_comments_count
      }
    }
  }
`;
export const GET_CURRENT_ISSUE_COMMENTS = gql`
  query getCurrentIssueComments(
    $current_issue_slug: String!
    $limit: Int!
    $current_page: Int!
    $sorting: String!
  ) {
    getCurrentIssueComments(
      current_issue_slug: $current_issue_slug
      limit: $limit
      current_page: $current_page
      sorting: $sorting
    ) {
      code
      status
      message
      next_page
      current_issue_comments {
        id
        slug
        user_id
        parent_id
        text
        deleted_at
        created_at
        replies_count
        upvotes_count
        downvotes_count
        user {
          id
          username
          slug
          photo
        }
        replies {
          id
          slug
          user_id
          parent_id
          text
          created_at
          replies_count
          upvotes_count
          downvotes_count
          user {
            id
            username
            slug
            photo
          }
          replies {
            id
            slug
            user_id
            parent_id
            text
            created_at
            replies_count
            upvotes_count
            downvotes_count
            user {
              id
              username
              slug
              photo
            }
            replies {
              id
              slug
              user_id
              parent_id
              text
              created_at
              replies_count
              upvotes_count
              downvotes_count
              user {
                id
                username
                slug
                photo
              }
              replies {
                id
                slug
                user_id
                parent_id
                text
                created_at
                replies_count
                upvotes_count
                downvotes_count
                user {
                  id
                  username
                  slug
                  photo
                }
                replies {
                  id
                  slug
                  user_id
                  parent_id
                  text
                  created_at
                  replies_count
                  upvotes_count
                  downvotes_count
                  user {
                    id
                    username
                    slug
                    photo
                  }
                  replies {
                    id
                    slug
                    user_id
                    parent_id
                    text
                    created_at
                    replies_count
                    upvotes_count
                    downvotes_count
                    user {
                      id
                      username
                      slug
                      photo
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_NEWS_ORGANIZATION_COMMENTS = gql`
  query getNewsOrganizationComments(
    $news_organization_slug: String!
    $limit: Int!
    $current_page: Int!
    $sorting: String!
  ) {
    getNewsOrganizationComments(
      news_organization_slug: $news_organization_slug
      limit: $limit
      current_page: $current_page
      sorting: $sorting
    ) {
      code
      status
      message
      next_page
      news_organization_comments {
        id
        slug
        user_id
        parent_id
        text
        deleted_at
        created_at
        replies_count
        upvotes_count
        downvotes_count
        user {
          id
          username
          slug
          photo
        }
        replies {
          id
          slug
          user_id
          parent_id
          text
          created_at
          replies_count
          upvotes_count
          downvotes_count
          user {
            id
            slug
            username
            slug
            photo
          }
          replies {
            id
            slug
            user_id
            parent_id
            text
            created_at
            replies_count
            upvotes_count
            downvotes_count
            user {
              id
              username
              slug
              photo
            }
            replies {
              id
              slug
              user_id
              parent_id
              text
              created_at
              replies_count
              upvotes_count
              downvotes_count
              user {
                id
                username
                slug
                photo
              }
              replies {
                id
                slug
                user_id
                parent_id
                text
                created_at
                replies_count
                upvotes_count
                downvotes_count
                user {
                  id
                  username
                  slug
                  photo
                }
                replies {
                  id
                  slug
                  user_id
                  parent_id
                  text
                  created_at
                  replies_count
                  upvotes_count
                  downvotes_count
                  user {
                    id
                    username
                    slug
                    photo
                  }
                  replies {
                    id
                    slug
                    user_id
                    parent_id
                    text
                    created_at
                    replies_count
                    upvotes_count
                    downvotes_count
                    user {
                      id
                      username
                      slug
                      photo
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_TOPIC_DETAILS = gql`
  query topic($slug: String!) {
    topic(slug: $slug) {
      id
      title
      slug
      position
      photo
      created_at
      updated_at
    }
  }
`;
export const GET_TOPIC_POSTS = gql`
  query getTopicPosts(
    $topic_slug: String
    $filter: String!
    $limit: Int!
    $current_page: Int!
  ) {
    getTopicPosts(
      topic_slug: $topic_slug
      filter: $filter
      limit: $limit
      current_page: $current_page
    ) {
      code
      status
      message
      topic_posts {
        id
        user_id
        community_id
        topic_id
        title
        slug
        description
        url
        image_url
        publish
        created_at
        average_rating
        post_rates_count
        all_comments_count
        user {
          id
          username
          slug
          photo
        }
      }
      next_page
    }
  }
`;
export const GET_PRESIGNED_URL = gql`
  query getPreSignedUrl($key: String, $content_type: String) {
    getPreSignedUrl(key: $key, content_type: $content_type) {
      code
      status
      message
      url
    }
  }
`;
export const GET_MY_COMMENTS = gql`
  query getMyComments($filter: String!, $limit: Int!, $current_page: Int!) {
    getMyComments(filter: $filter, limit: $limit, current_page: $current_page) {
      code
      status
      message
      comments {
        id
        user_id
        parent_id
        text
        created_at
        deleted_at
        replies {
          id
          text
          parent_id
        }
        replies_count
        user {
          id
          username
          photo
          slug
        }
        commentable {
          community_id
          id
          title
          slug
          description
          image_url
          url
          topic_id
          topic {
            title
            slug
          }
          community {
            name
            slug
          }
          user {
            id
            username
            slug
          }
        }
      }
      next_page
    }
  }
`;

export const GET_VOTED_COMMENT_IDS = gql`
  {
    getVotedCommentIds {
      code
      status
      message
      upvoted_comment_ids
      downvoted_comment_ids
    }
  }
`;

export const GET_VOTED_CURRENT_ISSUE_COMMENT_IDS = gql`
  {
    getVotedCurrentIssueCommentIds {
      code
      status
      message
      upvoted_current_issue_comment_ids
      downvoted_current_issue_comment_ids
    }
  }
`;
export const GET_VOTED_NEWS_ORGANIZATION_COMMENT_IDS = gql`
  {
    getVotedNewsOrganizationCommentIds {
      code
      status
      message
      upvoted_news_organization_comment_ids
      downvoted_news_organization_comment_ids
    }
  }
`;
export const GET_REPORTED_POST_IDS = gql`
  {
    getReportedPostIds {
      code
      status
      message
      reported_post_ids
    }
  }
`;

export const GET_REPORTED_COMMENT_IDS = gql`
  {
    getReportedCommentIds {
      code
      status
      message
      reported_comment_ids
    }
  }
`;

export const GET_REPORTED_CURRENT_ISSUE_COMMENT_IDS = gql`
  {
    getReportedCurrentIssueCommentIds {
      code
      status
      message
      reported_current_issue_comment_ids
    }
  }
`;

export const GET_REPORTED_NEWS_ORGANIZATION_COMMENT_IDS = gql`
  {
    getReportedNewsOrganizationCommentIds {
      code
      status
      message
      reported_news_organization_comment_ids
    }
  }
`;

export const GET_SITE_SETTING_DETAILS = gql`
  {
    siteSetting {
      code
      status
      message
      site_setting {
        id
        title
        email
        copy_right
        instagram
        twitter
        youtube
        vimeo
        post_limit_per_month
      }
    }
  }
`;

export const GET_SEARCH_RESULTS = gql`
  query search(
    $query: String!
    $want: String!
    $limit: Int!
    $current_page: Int!
  ) {
    search(
      query: $query
      want: $want
      limit: $limit
      current_page: $current_page
    ) {
      code
      status
      message
      next_page
      data {
        id
        title
        name
        username
        slug
        email
        photo
        description
        status
        created_at
        user {
          id
          username
          slug
          email
          role
          photo
          userProfile {
            description
          }
        }
        community {
          id
          name
          type
          slug
          photo
        }
        location
        is_user
        followersCount
        is_post
        is_community
        is_news_organization
        is_current_issue
        url
        image_url
        publish
        post_rates_count
        average_rating
        all_comments_count
        type
        website
        community_members_count
        owner {
          id
          username
          slug
          email
          photo
        }
        ownership
        media_companies
        influential_people
        revenue_employee
        awards
        main_office_address
        requested
        thumb
        all_current_issue_comments_count
      }
    }
  }
`;

export const GET_SEARCH_SUGGESTIONS = gql`
  query searchSuggestion($query: String!) {
    searchSuggestion(query: $query) {
      code
      status
      message
      search_suggestions {
        id
        title
        name
        username
        slug
        photo
        image_url
        created_at
        is_user
        is_post
        is_community
        is_news_organization
        is_current_issue
      }
    }
  }
`;

export const GET_CMS_PAGE_DETAILS = gql`
  query getCmsPageDetail($code: String!) {
    getCmsPageDetail(code: $code) {
      code
      message
      status
      cms_page {
        id
        title
        description
      }
    }
  }
`;

export const GET_BLOGS = gql`
  query getBlogs(
    $limit: Int!
    $current_page: Int!
    $query: String!
    $category_slug: String!
  ) {
    getBlogs(
      limit: $limit
      current_page: $current_page
      query: $query
      category_slug: $category_slug
    ) {
      code
      status
      message
      category_slug
      blogs {
        id
        title
        slug
        photo
        category_id
        author
        description
        short_brief
        status
        created_at
        category {
          id
          title
          slug
          created_at
        }
      }
      next_page
    }
  }
`;

export const GET_BLOG_CATEGORIES = gql`
  {
    getCategories {
      code
      status
      message
      categories {
        id
        title
        slug
        created_at
      }
    }
  }
`;

export const GET_BLOG_DETAILS = gql`
  query getBlogDetails($blog_slug: String!) {
    getBlogDetails(blog_slug: $blog_slug) {
      code
      status
      message
      next_blog_title
      next_blog_slug
      previous_blog_title
      previous_blog_slug
      blog {
        id
        category_id
        title
        slug
        photo
        author
        description
        status
        category {
          id
          title
          slug
          created_at
        }
        created_at
      }
    }
  }
`;

export const GET_CAREERS = gql`
  {
    getCareers {
      code
      status
      message
      careers {
        id
        title
        slug
        vacancy
        description
        created_at
      }
    }
  }
`;

export const GET_RECENT_BLOGS = gql`
  {
    getRecentBlogs {
      status
      code
      message
      blogs {
        id
        category_id
        title
        photo
        slug
        author
        description
        short_brief
        status
        category {
          id
          title
          slug
        }
      }
    }
  }
`;