import React, { useEffect, useState } from "react";
import { hot } from "react-hot-loader";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Routes from "./routes/Routes";
import Header from "./components/UIComponents/Header/Header";
import HeaderSmall from "./components/UIComponents/HeaderSmall/HeaderSmall";
import { ProvideAuth } from "./utils/useAuth";
import "react-toastify/dist/ReactToastify.css";
import "./public/style/style.scss";

function App({ history }) {
  const [showSmallHeader, setShowSmallHeader] = useState(false);
  useEffect(() => {
    if (
      history.location.pathname.split("/").includes("login")
      || history.location.pathname.split("/").includes("signup")
      || history.location.pathname.split("/").includes("forgot-password")
      || history.location.pathname.split("/").includes("reset-password")
    ) {
      setShowSmallHeader(true);
    } else {
      setShowSmallHeader(false);
    }
  }, [history.location]);
  return (
    <ProvideAuth>
      <div className="App">
        {showSmallHeader && <HeaderSmall />}
        {!showSmallHeader && <Header />}
        {Routes}

        <ToastContainer autoClose={2000} />
      </div>
    </ProvideAuth>
  );
}

const ExportedApp = process.env.NODE_ENV === "development"
  ? hot(module)(withRouter(App))
  : withRouter(App);
export default ExportedApp;