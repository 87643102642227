@import "./variables";

.create-post-page-wrap{
	padding: 40px 15px;
}
.create-post-box{
	max-width: 600px;
	width: 100%;
	margin: 0 auto;
	background: $color_white;
	@include border-radius(5px);
	&-header{
		padding: 10px 8px 12px;
		border-bottom: 1px solid $color_mercury2;
		h3{
			font-size: 18px;
			line-height: 20px;
			font-weight: 600;
			color: $primary_color;
		}
	}
	&-body{
		padding: 10px 8px;
		.tab-content{
			padding: 10px 0 0;
		}
		.form-footer{
			text-align: right;
			display: flex;
			justify-content: flex-end;
			.btn + .btn{
				margin-left: 9px;
			}
			.btn-toolbar{
				margin-left: 9px;
				.btn{
					&.txt{
						padding-right: 8px;
					}
					.line{
						margin-right: 8px;
						border-left: 1px solid $color_white;
						height: 14px;
					}
				}
			}
		}
	}
}

.schedule-post-popup{
	.modal-dialog{
		max-width: 360px;
		@include breakpoint( max , 400){
			width: 90%;
			margin: 2em auto;
		}
	}
	.modal-body{
		padding: 10px 0 5px 0;
	}
}