import React from "react";
import { useQuery } from "@apollo/client";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import { GET_CURRENT_ISSUES } from "../../../../queries/newsCosmosQueries";
import "./trending-topic.scss";

const TrendingIssues = () => {
  const { loading, error, data } = useQuery(GET_CURRENT_ISSUES, {
    variables: {
      filter: "trending",
      limit: 6,
      current_page: 1,
    },
  });

  if (loading) {
    return (
      <div className="trending-topics-wrap">
        <h3 className="trending-title">Current Issues</h3>
        <div className="trending-topics-list">
          <p>
            Loading...
            <span className="ml-2">
              <ClipLoader color="#e76c35" loading={true} size={15} />
            </span>
          </p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="trending-topics-wrap">
        <h3 className="trending-title">Current Issues</h3>
        <div className="trending-topics-list">
          {error.networkError ? (
            <p>
              Error : No internet connection, please refresh the page or
              check internet connection !!
            </p>
          ) : (
            <p>
              Error!
              {" "}
              {error.message}
            </p>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="trending-topics-wrap">
      <h3 className="trending-title">Current Issues</h3>
      <div className="trending-topics-list">
        {data
          && data.getCurrentIssues
          && data.getCurrentIssues.current_issues.length > 0
          && data.getCurrentIssues.current_issues.map((issue) => (
            <div key={Math.random()} className="trending-topic-box">
              <Link to={`/current-issues/${issue.slug}/details`}>
                <div className="img">
                  <img src={issue.photo} alt="Current Issue" height="130" width="300" />
                </div>
                <div className="topic-title">{issue.name}</div>
              </Link>
            </div>
          ))}
        {data
          && data.getCurrentIssues
          && data.getCurrentIssues.current_issues.length === 0 && (
            <p>Nothing to see here — yet</p>
        )}
        {data
          && data.getCurrentIssues
          && data.getCurrentIssues.current_issues.length > 0
          && data.getCurrentIssues.next_page !== 0 && (
            <div className="create-article-btn d-block pt-2">
              <Link
                to="/current-issues"
                className="btn-bold btn-primary btn btn-block"
              >
                VIEW MORE
              </Link>
            </div>
        )}
      </div>
    </div>
  );
};

export default TrendingIssues;