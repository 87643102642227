@import "../../../../public/style/variables";

.sidebar-footer{
    padding: 8px 0;
	position: sticky;
	top: 50px;
    &.detail-title-show{
        top: 90px;
    }
    .footer-box{
        background: $header_bg_color;
        padding: 13px 16px;
        @include border-radius(5px);
        margin-bottom: 16px;
    }
    .footer-links{
        ul{
            display: flex;
            flex-wrap: wrap;
            margin: 0 -7px;
            li{
                width: 50%;
                padding: 0 7px;
                a{
                    display: inline-block;
                    font-size: 14px;
                    line-height: 26px;
                    color: $color_white;
                    @include transition(all 0.3s ease-in-out);
                    &:hover{
                        color: $secondary_color;
                    }
                }
            }
        }
    }
    .copyright{
        font-size: 12px;
        color: $color_white;
        padding-top: 5px;
    }
}